import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";

import {
    mapStateToProps,
    mapDispatchToProps
} from '../functions/mapStateToProps'

import { AccountLayout, TOKEN_PROGRAM_ID, createTransferInstruction } from '@solana/spl-token'
import { Connection, clusterApiUrl, Transaction, PublicKey } from '@solana/web3.js'

import { socket } from './App'

var that
var redirecting
var oldGames = []

class ListOngoingGames extends Component {

    constructor(props) {
        super(props)
        this.state = { blockchain: "" }
        that = this
        redirecting = false
    }

    syncChange() {
        that.props.listAsyncGames()
    }

    asyncChange() {
        that.props.listGames()
    }

    async showTokens() {
        const provider = window.solana
        if (provider.isPhantom) {
            var connection = new Connection(
                clusterApiUrl(process.env.REACT_APP_NETWORK),
                'confirmed'
            )

            const tokenAccounts = await connection.getTokenAccountsByOwner(
                new PublicKey(window.localStorage.getItem('account')),
                {
                    programId: TOKEN_PROGRAM_ID,
                }
            )

            tokenAccounts.value.forEach((e) => {
                const accountInfo = AccountLayout.decode(e.account.data)
                if (process.env.REACT_APP_USDC_ADDRESS == accountInfo.mint) {
                    var amount = accountInfo.amount
                    var normalAmount = amount.toString()
                    normalAmount /= Math.pow(10, process.env.REACT_APP_PRECISION)
                    var el = document.getElementsByClassName('your-usdc')
                    if (el[0]) {
                        el[0].textContent = normalAmount
                    }
                }
            })
        }
    }

    async joinGame(e) {
        var eventHolder = e.target.dataset
        socket.emit('blockGame', ({
            game: eventHolder,
            account: window.localStorage.getItem('account'),
            token: window.localStorage.getItem('token'),
            refreshToken: window.localStorage.getItem('refreshToken')
        }))
    }

    spinner() {
        var el = document.getElementById('spinner')
        if (el) {
            el.classList.remove('hidden')
        }
    }

    spinnerHide() {
        var el = document.getElementById('spinner')
        if (el) {
            el.classList.add('hidden')
        }
    }

    renderGames() {
        if (that.props.game == "Cannot join game") {
            return "Cannot join this game"
        }
        if (that.props.game.respond == "GL") {
            var jsx = []
            for (var i = 0; i < that.props.game.gameSettings.length; i++) {
                if (window.localStorage.getItem("account") != that.props.game.gameSettings[i].gameSettings.hostAccount) {
                    jsx.push(<div className="my-4 d-md-flex blue-font neon-border-bottom-mobile">
                        <p className="col-md-4 text-center">{that.props.game.gameSettings[i].gameName}</p>
                        <p className="col-md-4 text-center">{that.props.game.gameSettings[i].gameSettings.rating}</p>
                        <p className="col-md-2 text-center">{that.props.game.gameSettings[i].gameSettings.rank.bet}</p>
                        <div className="col-md-2 text-center d-flex">
                            <button className="btn no-btn blue-font mx-auto" data-roomnumber={that.props.game.gameSettings[i].roomNumber} data-gamename={that.props.game.gameSettings[i].gameName} data-account={that.props.game.gameSettings[i].gameSettings.hostAccount} data-bet={that.props.game.gameSettings[i].gameSettings.rank.bet} onClick={this.joinGame}>Join</button>
                        </div>
                    </div>)
                }
            }
            oldGames = jsx
            return <div>{jsx}</div>
        }

        if (that.props.game.respond == "OG") {
            return "You cannot join your own game!"
        }

        if (that.props.game.respond == "GJNT") {
            window.localStorage.setItem("token", that.props.game.gameDetails.token)
            that.redirect(that.props.game.gameDetails.gameName, that.props.game.gameDetails.roomNumber)
        }

        if (that.props.game.respond == "GJ") {
            that.redirect(that.props.game.gameDetails.gameName, that.props.game.gameDetails.roomNumber)
        }

        return <div>{jsx}</div>

    }

    redirect(gameName, roomNumber) {
        socket.emit('myIp', ({ account: window.localStorage.getItem('account'), name: window.localStorage.getItem('name') }))
        that.props.history.push('/' + gameName + '/' + roomNumber)
    }

    componentDidMount() {
        socket.emit('joinAdminRoom', 'join')
        that.props.listGames()
        socket.on('redirectPlayer', props => {
            if (props.account) {
                if (props.account.toUpperCase() == window.localStorage.getItem('account').toUpperCase()) {
                    that.redirect(props.gameName, props.roomNumber)
                }
            }
        })

        socket.on('gamesChanged', () => {
            that.props.listGames()
        })

        socket.on('blockSuccesfull', async (props) => {
            if ("solana" in window) {
                var gotIn = false
                const provider = window.solana
                if (provider.isPhantom) {
                    var connection = new Connection(
                        clusterApiUrl(process.env.REACT_APP_NETWORK),
                        'confirmed'
                    )

                    const tokenAccounts = await connection.getTokenAccountsByOwner(
                        new PublicKey(window.localStorage.getItem('account')),
                        {
                            programId: TOKEN_PROGRAM_ID,
                        }
                    )

                    if (!tokenAccounts) {
                        socket.emit('unlockOnError', {
                            game: {
                                gameName: props.gameName,
                                roomNumber: props.roomNumber
                            },
                            account: window.localStorage.getItem('account'),
                            token: window.localStorage.getItem('token'),
                            refreshToken: window.localStorage.getItem('refreshToken')
                        })
                    }

                    tokenAccounts.value.forEach(async (e) => {
                        const accountInfo = AccountLayout.decode(e.account.data)
                        if (process.env.REACT_APP_USDC_ADDRESS == accountInfo.mint) {
                            gotIn = true
                            var recieverWallet = new PublicKey(process.env.REACT_APP_USDC_OUR_ACCOUNT)
                            const transaction = new Transaction().add(
                                createTransferInstruction(
                                    new PublicKey(e.pubkey),
                                    recieverWallet,
                                    new PublicKey(window.localStorage.getItem('account')),
                                    props.bet * Math.pow(10, process.env.REACT_APP_PRECISION),
                                )
                            )

                            transaction.feePayer = await provider.publicKey
                            if (!transaction.feePayer) {
                                socket.emit('unlockOnError', {
                                    game: {
                                        gameName: props.gameName,
                                        roomNumber: props.roomNumber
                                    },
                                    account: window.localStorage.getItem('account'),
                                    token: window.localStorage.getItem('token'),
                                    refreshToken: window.localStorage.getItem('refreshToken')
                                })
                            }
                            let blockhashObj = await connection.getLatestBlockhash()
                            transaction.recentBlockhash = blockhashObj.blockhash
                            if (!transaction.recentBlockhash) {
                                socket.emit('unlockOnError', {
                                    game: {
                                        gameName: props.gameName,
                                        roomNumber: props.roomNumber
                                    },
                                    account: window.localStorage.getItem('account'),
                                    token: window.localStorage.getItem('token'),
                                    refreshToken: window.localStorage.getItem('refreshToken')
                                })
                            }
                            if (transaction) {
                                let signed = await provider.signTransaction(transaction)
                                let signature = await connection.sendRawTransaction(signed.serialize())
                                that.spinner()
                                await connection.confirmTransaction(signature)
                                that.spinnerHide()
                                that.showTokens()
                                that.props.joinGame(
                                    props.gameName,
                                    props.roomNumber,
                                    window.localStorage.getItem('account'),
                                    signature
                                )
                            } else {
                                socket.emit('unlockOnError', {
                                    game: {
                                        gameName: props.gameName,
                                        roomNumber: props.roomNumber
                                    },
                                    account: window.localStorage.getItem('account'),
                                    token: window.localStorage.getItem('token'),
                                    refreshToken: window.localStorage.getItem('refreshToken')
                                })
                            }
                        }
                    })
                    if (!gotIn) {
                        socket.emit('unlockOnError', {
                            game: {
                                gameName: props.gameName,
                                roomNumber: props.roomNumber
                            },
                            account: window.localStorage.getItem('account'),
                            token: window.localStorage.getItem('token'),
                            refreshToken: window.localStorage.getItem('refreshToken')
                        })

                        // show prompt that you do not have spl-tokens
                    }

                    gotIn = false
                }
            } else {
                window.open("https://www.phantom.app/", "_blank")
            }
        })
    }

    render() {
        return (
            <div>
                {/* <div className="yellow-font fixed-width-options">
                    <div>
                        <label>Synchronous Game</label>
                        <input id="sync" className="radio-flexed ms-4" name="time-radio" type="radio" onChange={this.asyncChange} defaultChecked />
                    </div> */}
                {/* <div>
                        <label>Asynchronous Game</label>
                        <input id="async" className="radio-flexed" name="time-radio" type="radio" onChange={this.syncChange} />
                    </div> */}
                {/* </div> */}
                <p className="blue-font mt-5 text-center">{this.state.blockchain}</p>
                <div className="neon-border no-padding-lr container faq-container max-height-500 join-game-overflow join-game mt-5">
                    <div className="row neon-border-bottom">
                        <div className="col-md-4 game-name dark-blue-font text-center">
                            Game
                        </div>
                        <div className="col-md-4 host dark-blue-font text-center">
                            Opponent Rating
                        </div>
                        <div className="col-md-2 bet dark-blue-font text-center">
                            Bet
                        </div>
                        <div className="col-md-2 join-button dark-blue-font text-center">

                        </div>
                    </div>
                    <div className="minus-margins">
                        {this.renderGames()}
                    </div>
                </div>
                <div id="spinner" className="position-absolute alligned-middle z-200 hidden text-center">
                    <div className="spinner-border text-primary" role="status">
                    </div><br></br>
                    Please do not close this window. You will be redirected automaticly.
                </div>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListOngoingGames))