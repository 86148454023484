import { Component } from 'react'
import { connect } from 'react-redux'
import Hex from './Hex'
import NotFound from './GameNotFound'
import {
    mapStateToProps,
    mapDispatchToProps
} from '../functions/mapStateToProps'
import Chessboard from 'chessboardjsx'
import * as Chess from 'chess.js'

var that, boardGenerated, moves, move, started, id, winner, swap, redSwap, gameStrings, chess, fens, currentMove, pgns
var texts = []

class GameObserver extends Component {
    constructor() {
        super()
        this.state = { game: <div></div>, fen: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1' }
        that = this
        boardGenerated = false
        move = 0
        started = false
        swap = false
        redSwap = false
        chess = new Chess(this.state.fen)
        currentMove = 0
    }

    chooseGame(game) {
        switch (game) {
            case "Hex":
                that.setState({ game: <Hex gameState={this.props.game} /> })
                break;
            default:
                that.setState({ game: <div></div> })
        }
    }

    checkIfEnded(username, game, id) {
        that.props.checkIfGameEnded(username, game, id)
    }

    generateBoard(size, currentBoard) {
        let arr = []
        let horizontalMax = size + size - 0.5
        let svgWidth = 100 / horizontalMax
        arr.push(<h2 className="widther d-table-cell"></h2>)
        for (var i = 0; i < size; i++) {
            arr.push(<h2 className="yellow-font text-center d-table-cell number-top">{i + 1}</h2>)
        }
        for (var it = 0; it < size; it++) {
            for (var iteration = 0; iteration < size; iteration++) {
                if (it == 0) {
                    if (iteration == 0) {
                        var chr = String.fromCharCode(65 + it)
                        arr.push(<h2 className={"yellow-font text-center number-top max-content letter-left me-2 m-bot-5"}>{chr}</h2>)
                        arr.push(<svg onClick={that.move} className="architecture m-bot-5" version="1.1" id={'svg-' + it + '-' + iteration} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40" xmlSpace="preserve">
                            <polygon className="hex-field" id={it + '-' + iteration + '-polygon'} onClick={that.move} points="20,0 40,10 40,30 20,40 0,30 0,10" />
                            <path id="lineAB" d="M 0 10 l 20 -10" stroke="#75ff00" strokeWidth="2" fill="none" />
                            <path id="lineBC" d="M 20 0 l 20 10" stroke="#75ff00" strokeWidth="2" fill="none" />
                            <path id="lineAB" d="M 0 10 l 0 20" stroke="#00d7ff" strokeWidth="2" fill="none" />
                            <path id="lineBC" d="M 0 30 l 20 10" stroke="#00d7ff" strokeWidth="2" fill="none" />
                        </svg>)
                    } else {
                        if (iteration == size - 1) {
                            arr.push(<svg className="architecture m-bot-5" version="1.1" id={'svg-' + it + '-' + iteration} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40" xmlSpace="preserve">
                                <polygon className="hex-field" id={it + '-' + iteration + '-polygon'} onClick={that.move} points="20,0 40,10 40,30 20,40 0,30 0,10" />
                                <path id="lineAB" d="M 0 10 l 20 -10" stroke="#75ff00" strokeWidth="2" fill="none" />
                                <path id="lineBC" d="M 20 0 l 10 5" stroke="#75ff00" strokeWidth="2" fill="none" />
                                <path id="lineAB" d="M 30 5 l 10 5" stroke="#00d7ff" strokeWidth="2" fill="none" />
                                <path id="lineBC" d="M 40 30 l 0 -20" stroke="#00d7ff" strokeWidth="2" fill="none" />
                            </svg>)
                        } else {
                            arr.push(<svg className="architecture m-bot-5" version="1.1" id={'svg-' + it + '-' + iteration} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40" xmlSpace="preserve">
                                <polygon className="hex-field" id={it + '-' + iteration + '-polygon'} onClick={that.move} points="20,0 40,10 40,30 20,40 0,30 0,10" />
                                <path id="lineAB" d="M 0 10 l 20 -10" stroke="#75ff00" strokeWidth="2" fill="none" />
                                <path id="lineBC" d="M 20 0 l 20 10" stroke="#75ff00" strokeWidth="2" fill="none" />
                            </svg>)

                        }
                    }
                } else {
                    if (iteration == 0) {
                        var chr = String.fromCharCode(65 + it)
                        if (it == size - 1) {
                            arr.push(<h2 className={"yellow-font text-center number-top max-content letter-left me-2" + " pl" + it + "it" + iteration}>{chr}</h2>)
                            arr.push(<svg className={"architecture " + "pl" + it} version="1.1" id={'svg-' + it + '-' + iteration} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40" xmlSpace="preserve">
                                <polygon className="hex-field" id={it + '-' + iteration + '-polygon'} onClick={that.move} points="20,0 40,10 40,30 20,40 0,30 0,10" />
                                <path id="lineAB" d="M 0 10 l 0 20" stroke="#00d7ff" strokeWidth="2" fill="none" />
                                <path id="lineBC" d="M 0 30 l 10 5" stroke="#00d7ff" strokeWidth="2" fill="none" />
                                <path id="lineAB" d="M 10 35 l 10 5" stroke="#75ff00" strokeWidth="2" fill="none" />
                                <path id="lineBC" d="M 20 40 l 20 -10" stroke="#75ff00" strokeWidth="2" fill="none" />
                            </svg>)
                        } else {
                            arr.push(<h2 className={"yellow-font text-center number-top max-content letter-left me-2" + " pl" + it + "it" + iteration}>{chr}</h2>)
                            arr.push(<svg className={"architecture " + "pl" + it} version="1.1" id={'svg-' + it + '-' + iteration} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40" xmlSpace="preserve">
                                <polygon className="hex-field" id={it + '-' + iteration + '-polygon'} onClick={that.move} points="20,0 40,10 40,30 20,40 0,30 0,10" />
                                <path id="lineAB" d="M 0 10 l 0 20" stroke="#00d7ff" strokeWidth="2" fill="none" />
                                <path id="lineBC" d="M 0 30 l 20 10" stroke="#00d7ff" strokeWidth="2" fill="none" />
                            </svg>)
                        }

                    } else {
                        if (it == size - 1) {
                            if (iteration == size - 1) {
                                arr.push(<svg className={"architecture " + "pl" + it} version="1.1" id={'svg-' + it + '-' + iteration} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40" xmlSpace="preserve">
                                    <polygon className="hex-field" id={it + '-' + iteration + '-polygon'} onClick={that.move} points="20,0 40,10 40,30 20,40 0,30 0,10" />
                                    <path id="lineAB" d="M 0 30 l 20 10" stroke="#75ff00" strokeWidth="2" fill="none" />
                                    <path id="lineBC" d="M 20 40 l 20 -10" stroke="#75ff00" strokeWidth="2" fill="none" />
                                    <path id="lineAB" d="M 20 0 l 20 10" stroke="#00d7ff" strokeWidth="2" fill="none" />
                                    <path id="lineBC" d="M 40 30 l 0 -20" stroke="#00d7ff" strokeWidth="2" fill="none" />
                                </svg>)
                            } else {
                                arr.push(<svg className={"architecture " + "pl" + it} version="1.1" id={'svg-' + it + '-' + iteration} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40" xmlSpace="preserve">
                                    <polygon className="hex-field" id={it + '-' + iteration + '-polygon'} onClick={that.move} points="20,0 40,10 40,30 20,40 0,30 0,10" />
                                    <path id="lineAB" d="M 0 30 l 20 10" stroke="#75ff00" strokeWidth="2" fill="none" />
                                    <path id="lineBC" d="M 20 40 l 20 -10" stroke="#75ff00" strokeWidth="2" fill="none" />
                                </svg>)
                            }
                        } else {
                            if (iteration == size - 1) {
                                arr.push(<svg className={"architecture " + "pl" + it} version="1.1" id={'svg-' + it + '-' + iteration} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40" xmlSpace="preserve">
                                    <polygon className="hex-field" id={it + '-' + iteration + '-polygon'} onClick={that.move} points="20,0 40,10 40,30 20,40 0,30 0,10" />
                                    <path id="lineAB" d="M 20 0 l 20 10" stroke="#00d7ff" strokeWidth="2" fill="none" />
                                    <path id="lineBC" d="M 40 30 l 0 -20" stroke="#00d7ff" strokeWidth="2" fill="none" />
                                </svg>)
                            } else {
                                arr.push(<svg className={"architecture " + "pl" + it} version="1.1" id={'svg-' + it + '-' + iteration} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40" xmlSpace="preserve">
                                    <polygon className="hex-field" id={it + '-' + iteration + '-polygon'} onClick={that.move} points="20,0 40,10 40,30 20,40 0,30 0,10" />
                                </svg>)
                            }
                        }

                    }
                }

                if (iteration == size - 1) {
                    arr.push(<br />)
                }
            }
        }
        boardGenerated = true
        return arr
    }

    currentBoard(currentBoard) {
        for (var i = 0; i < currentBoard.player1Moves.length; i++) {
            document.getElementById(currentBoard.player1Moves[i]).classList.add("red-player")
        }

        for (var i = 0; i < currentBoard.player2Moves.length; i++) {
            document.getElementById(currentBoard.player2Moves[i]).classList.add("blue-player")
        }
    }

    renderGame() {
        if (that.props.game.respond == "FOUNDGAME") {
            if (that.props.match.params.game == "Hex") {
                var el2 = document.getElementById("hide-chess")
                var el3 = document.getElementById("hide-chess2")
                if (el2 && el3) {
                    el2.classList.remove("hidden")
                    el3.classList.remove("hidden")
                }

                console.log(that.props.game)
                var arr = that.generateBoard(that.props.game.game.gameSettings.boardSize, that.props.game.game.currentBoard)
                moves = that.props.game.game
                gameStrings = that.props.game.game.gameString
                var text1 = document.getElementsByClassName("p1")
                text1[0].innerHTML = `${that.props.game.game.players.player1.username}`
                var text2 = document.getElementsByClassName("p2")
                text2[0].innerHTML = `${that.props.game.game.players.player2.username}`
                if (moves.player1Moves.length > moves.player2Moves.length) {
                    winner = 1
                } else if (moves.player2Moves.length == moves.player1Moves.length) {
                    winner = 2
                } else {
                    redSwap = true
                    swap = true
                    if (moves.player2Moves.length > moves.player1Moves.length) {
                        winner = 2
                    } else {
                        winner = 1
                    }
                }
                var el = document.getElementById('hide-animations-end')
                if (el) {
                    el.classList.add('hidden')
                }
                return arr
            } else if (that.props.match.params.game == "Chess") {
                var el2 = document.getElementById("white-piece")
                var el3 = document.getElementById("black-piece")
                if (el2 && el3) {
                    el2.classList.remove("hidden")
                    el3.classList.remove("hidden")
                }
                var text1 = document.getElementsByClassName("p1")
                text1[0].innerHTML = `${that.props.game.game.players.player1.username}`
                var text2 = document.getElementsByClassName("p2")
                text2[0].innerHTML = `${that.props.game.game.players.player2.username}`
                fens = that.props.game.game.fens
                pgns = that.props.game.game.pgn.split(";")
                pgns.pop()
                var testTexts = that.props.game.game.gameString.split(";")
                testTexts.pop()
                texts = [" "]
                for (var i = 0; i < testTexts.length; i++) {
                    var elements = testTexts[i].split(":")
                    texts.push(elements[1])
                }
                if (that.props.match.params.username == that.props.game.game.players.player1.username) {
                    return <Chessboard
                        position={that.state.fen}
                        pieces={{
                            wP: () => (
                                <img
                                    className="chess-piece"
                                    src={"/img/pawn_white.png"}
                                    alt={"wP"}
                                />
                            ),
                            wR: () => (
                                <img
                                    className="chess-piece"
                                    src={"/img/rook_white.png"}
                                    alt={"wR"}
                                />
                            ),
                            wN: () => (
                                <img
                                    className="chess-piece"
                                    src={"/img/knight_white.png"}
                                    alt={"wN"}
                                />
                            ),
                            wB: () => (
                                <img
                                    className="chess-piece"
                                    src={"/img/bishop_white.png"}
                                    alt={"wB"}
                                />
                            ),
                            wQ: () => (
                                <img
                                    className="chess-piece"
                                    src={"/img/queen_white.png"}
                                    alt={"wQ"}
                                />
                            ),
                            wK: () => (
                                <img
                                    className="chess-piece"
                                    src={"/img/king_white.png"}
                                    alt={"wK"}
                                />
                            ),
                            bP: () => (
                                <img
                                    className="chess-piece"
                                    src={"/img/pawn_black.png"}
                                    alt={"bP"}
                                />
                            ),
                            bR: () => (
                                <img
                                    className="chess-piece"
                                    src={"/img/rook_black.png"}
                                    alt={"bwR"}
                                />
                            ),
                            bN: () => (
                                <img
                                    className="chess-piece"
                                    src={"/img/knight_black.png"}
                                    alt={"bN"}
                                />
                            ),
                            bB: () => (
                                <img
                                    className="chess-piece"
                                    src={"/img/bishop_black.png"}
                                    alt={"bB"}
                                />
                            ),
                            bQ: () => (
                                <img
                                    className="chess-piece"
                                    src={"/img/queen_black.png"}
                                    alt={"bQ"}
                                />
                            ),
                            bK: () => (
                                <img
                                    className="chess-piece"
                                    src={"/img/king_black.png"}
                                    alt={"bK"}
                                />
                            ),
                        }}
                        lightSquareStyle={{ backgroundImage: `url(/img/WHITE_FIELD.jpg)` }}
                        darkSquareStyle={{ backgroundImage: `url(/img/BLACK_FIELD.jpg)` }}
                    />
                } else {
                    return <Chessboard
                        position={that.state.fen}
                        orientation='black'
                        pieces={{
                            wP: () => (
                                <img
                                    className="chess-piece"
                                    src={"/img/pawn_white.png"}
                                    alt={"wP"}
                                />
                            ),
                            wR: () => (
                                <img
                                    className="chess-piece"
                                    src={"/img/rook_white.png"}
                                    alt={"wR"}
                                />
                            ),
                            wN: () => (
                                <img
                                    className="chess-piece"
                                    src={"/img/knight_white.png"}
                                    alt={"wN"}
                                />
                            ),
                            wB: () => (
                                <img
                                    className="chess-piece"
                                    src={"/img/bishop_white.png"}
                                    alt={"wB"}
                                />
                            ),
                            wQ: () => (
                                <img
                                    className="chess-piece"
                                    src={"/img/queen_white.png"}
                                    alt={"wQ"}
                                />
                            ),
                            wK: () => (
                                <img
                                    className="chess-piece"
                                    src={"/img/king_white.png"}
                                    alt={"wK"}
                                />
                            ),
                            bP: () => (
                                <img
                                    className="chess-piece"
                                    src={"/img/pawn_black.png"}
                                    alt={"bP"}
                                />
                            ),
                            bR: () => (
                                <img
                                    className="chess-piece"
                                    src={"/img/rook_black.png"}
                                    alt={"bwR"}
                                />
                            ),
                            bN: () => (
                                <img
                                    className="chess-piece"
                                    src={"/img/knight_black.png"}
                                    alt={"bN"}
                                />
                            ),
                            bB: () => (
                                <img
                                    className="chess-piece"
                                    src={"/img/bishop_black.png"}
                                    alt={"bB"}
                                />
                            ),
                            bQ: () => (
                                <img
                                    className="chess-piece"
                                    src={"/img/queen_black.png"}
                                    alt={"bQ"}
                                />
                            ),
                            bK: () => (
                                <img
                                    className="chess-piece"
                                    src={"/img/king_black.png"}
                                    alt={"bK"}
                                />
                            ),
                        }}
                        lightSquareStyle={{ backgroundImage: `url(/img/WHITE_FIELD.jpg)` }}
                        darkSquareStyle={{ backgroundImage: `url(/img/BLACK_FIELD.jpg)` }}
                    />
                }
            }
        }

        if (that.props.game.respond == "GNF") {
            return <NotFound />
        }
    }

    start() {
        if (!started) {
            if (that.props.match.params.game == "Hex") {
                started = true
                document.getElementById('autoplay').textContent = "Stop Replay"
                id = setInterval(() => {
                    var mv = gameStrings.split(";")
                    var mvs
                    var mvArr = []
                    for (var i = 0; i < mv.length; i++) {
                        mvs = mv[i].split(" ")
                        if (mvs[1] != undefined) {
                            mvArr.push(mvs[1])
                        }
                    }

                    if (move < mvArr.length) {
                        if (move % 2 == 0) {
                            document.getElementById(mvArr[move]).classList.add("red-player")
                        } else {
                            document.getElementById(mvArr[move]).classList.add("blue-player")
                        }
                        move++
                    } else {
                        clearInterval(id)
                    }
                }, 500)
            } else if (that.props.match.params.game == "Chess") {
                started = true
                document.getElementById('autoplay').textContent = "Stop Replay"
                id = setInterval(() => {
                    if (currentMove < fens.length - 1) {
                        currentMove++
                        chess = new Chess(fens[currentMove])
                        that.setState({
                            fen: fens[currentMove]
                        })
                    } else {
                        clearInterval(id)
                    }
                }, 1000)
            }
        } else {
            started = false
            document.getElementById('autoplay').textContent = "Start Replay"
            clearInterval(id)
        }
    }

    restart() {
        if (that.props.match.params.game == "Hex") {
            move = 0;
            var elems = document.querySelectorAll(".red-player");

            [].forEach.call(elems, function (el) {
                el.classList.remove("red-player")
            })

            elems = document.querySelectorAll(".blue-player");

            [].forEach.call(elems, function (el) {
                el.classList.remove("blue-player")
            })
        } else if (that.props.match.params.game == "Chess") {
            chess = new Chess('rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1')
            that.setState({
                fen: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1'
            })
            currentMove = 0
        }
    }

    prev() {
        if (that.props.match.params.game == "Hex") {
            if (move > 0) {
                var mv = gameStrings.split(";")
                var mvs
                var mvArr = []
                for (var i = 0; i < mv.length; i++) {
                    mvs = mv[i].split(" ")
                    if (mvs[1] != undefined) {
                        mvArr.push(mvs[1])
                    }
                }
                move--
                if (move % 2 == 0) {
                    document.getElementById(mvArr[move]).classList.remove("red-player")
                } else {
                    document.getElementById(mvArr[move]).classList.remove("blue-player")
                }

            }
        } else if (that.props.match.params.game == "Chess") {
            if (currentMove > 0) {
                currentMove--
                chess = new Chess(fens[currentMove])
                that.setState({
                    fen: fens[currentMove]
                })
            }
        }
    }

    next() {
        if (that.props.match.params.game == "Hex") {
            var mv = gameStrings.split(";")
            var mvs
            var mvArr = []
            for (var i = 0; i < mv.length; i++) {
                mvs = mv[i].split(" ")
                if (mvs[1] != undefined) {
                    mvArr.push(mvs[1])
                }
            }

            if (move < mvArr.length) {
                if (move % 2 == 0) {
                    document.getElementById(mvArr[move]).classList.add("red-player")
                } else {
                    document.getElementById(mvArr[move]).classList.add("blue-player")
                }
                move++
            }
        } else if (that.props.match.params.game == "Chess") {
            if (currentMove < fens.length - 1) {
                currentMove++
                chess = new Chess(fens[currentMove])
                that.setState({
                    fen: fens[currentMove]
                })
            }
        }
    }

    finish() {
        if (that.props.match.params.game == "Hex") {
            move = moves.player1Moves.length + moves.player2Moves.length
            that.currentBoard(moves)
        } else if (that.props.match.params.game == "Chess") {
            currentMove = fens.length - 1
            chess = new Chess(fens[currentMove])
            that.setState({
                fen: fens[currentMove]
            })
        }
    }

    componentDidUpdate() {
        if (that.props.match.params.game == "Chess") {
            var els = document.getElementsByClassName("inCheck")
            for (var i = 0; i < els.length; i) {
                els[i].classList.remove("inCheck")
            }

            var elementslm = document.getElementsByClassName('lastMove')
            if (elementslm[0] && elementslm[1]) {
                elementslm[0].classList.remove('lastMove')
                elementslm[0].classList.remove('lastMove')
            }

            if (texts[currentMove]) {
                var sourceSquare = document.querySelectorAll(`[data-squareid='${texts[currentMove].split(" ")[1]}']`)
                var targetSquare = document.querySelectorAll(`[data-squareid='${texts[currentMove].split(" ")[3]}']`)
                if (sourceSquare[0] && targetSquare[0]) {
                    sourceSquare[0].classList.add('lastMove')
                    targetSquare[0].classList.add('lastMove')
                }
            }

            if (currentMove % 2 == 0) {
                if (chess.in_check()) {
                    var el = document.getElementById("gameBoard")
                    if (el) {
                        var childs = el.children[0].children[0].children
                        for (var i = 0; i < childs.length; i++) {
                            for (var j = 0; j < childs[i].children.length; j++) {
                                if (childs[i].children[j].children[0]) {
                                    if (childs[i].children[j].children[0].children[0]) {
                                        if (childs[i].children[j].children[0].children[0].dataset.testid) {
                                            var test = childs[i].children[j].children[0].children[0].dataset.testid.split("-")
                                            if (test[0] == "wK") {
                                                childs[i].children[j].children[0].classList.add("inCheck")
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                if (chess.in_check()) {
                    var el = document.getElementById("gameBoard")
                    if (el) {
                        var childs = el.children[0].children[0].children
                        for (var i = 0; i < childs.length; i++) {
                            for (var j = 0; j < childs[i].children.length; j++) {
                                if (childs[i].children[j].children[0]) {
                                    if (childs[i].children[j].children[0].children[0]) {
                                        if (childs[i].children[j].children[0].children[0].dataset.testid) {
                                            var test = childs[i].children[j].children[0].children[0].dataset.testid.split("-")
                                            if (test[0] == "bK") {
                                                childs[i].children[j].children[0].classList.add("inCheck")
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    componentWillUnmount() {
        clearInterval(id)
    }

    componentDidMount() {
        var el = document.getElementById("bg")
        if (el) {
            el.classList.add("indoor-bg")
        }
        boardGenerated = false
        move = 0
        started = false
        swap = false
        redSwap = false
        this.checkIfEnded(this.props.match.params.username, this.props.match.params.game, this.props.match.params.id)
        this.chooseGame(this.props.match.params.game)
    }

    render() {
        return (
            <div className="game position-relative">
                {this.state.game}
                <div className="d-flex mx-auto max-content">
                    <div className="my-4 players">
                        <ul className="my-4 user-data2">
                            <div className="d-flex">
                                <ol className="p1 my-4 p-2 white-font fixed-name-widht smaller-size"></ol>
                                <svg className="architecture mt-4 hidden" version="1.1" id="hide-chess" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40">
                                    <polygon className="hex-field red-player rp" points="20,0 40,10 40,30 20,40 0,30 0,10"></polygon>
                                </svg>
                                <div id="white-piece" className="hidden my-3">
                                    <img src="/img/pawn_white.png" className="chess-piece" alt="white pawn" />
                                </div>
                                {/* <svg id="white-piece" viewBox="1 1 43 43" width="70" height="70" className="background-light hidden near-name-asset" >
                                    <g>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="45" height="45">
                                            <path className="white-piece" d="M 22,9 C 19.79,9 18,10.79 18,13 C 18,13.89 18.29,14.71 18.78,15.38 C 16.83,16.5 15.5,18.59 15.5,21 C 15.5,23.03 16.44,24.84 17.91,26.03 C 14.91,27.09 10.5,31.58 10.5,39.5 L 33.5,39.5 C 33.5,31.58 29.09,27.09 26.09,26.03 C 27.56,24.84 28.5,23.03 28.5,21 C 28.5,18.59 27.17,16.5 25.22,15.38 C 25.71,14.71 26,13.89 26,13 C 26,10.79 24.21,9 22,9 z"></path>
                                        </svg>
                                    </g>
                                </svg> */}
                            </div>
                            <div className="d-flex">
                                <ol className="p2 my-4 p-2 white-font fixed-name-widht smaller-size"></ol>
                                <svg className="architecture mt-4 hidden" version="1.1" id="hide-chess2" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40">
                                    <polygon className="hex-field blue-player bp" points="20,0 40,10 40,30 20,40 0,30 0,10"></polygon>
                                </svg>
                                <div id="black-piece" className="hidden my-3">
                                    <img src="/img/pawn_black.png" className="chess-piece" alt="white pawn" />
                                </div>
                                {/* <svg id="black-piece" viewBox="1 1 43 43" width="70" height="70" className="background-light hidden near-name-asset" >
                                    <g>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="45" height="45">
                                            <path className="black-piece" d="M 22,9 C 19.79,9 18,10.79 18,13 C 18,13.89 18.29,14.71 18.78,15.38 C 16.83,16.5 15.5,18.59 15.5,21 C 15.5,23.03 16.44,24.84 17.91,26.03 C 14.91,27.09 10.5,31.58 10.5,39.5 L 33.5,39.5 C 33.5,31.58 29.09,27.09 26.09,26.03 C 27.56,24.84 28.5,23.03 28.5,21 C 28.5,18.59 27.17,16.5 25.22,15.38 C 25.71,14.71 26,13.89 26,13 C 26,10.79 24.21,9 22,9 z"></path>
                                        </svg>
                                    </g>
                                </svg> */}
                            </div>
                        </ul>
                    </div>
                    <div id="gameBoard" style={{ marginLeft: 70 + 'px' }}>
                        {this.renderGame()}
                    </div>
                </div>
                <div className="controls mt-5 mx-auto max-width text-center d-flex p-4 game-data">
                    <button id="autoplay" className="white-font mx-2 btn smaller-size" onClick={this.start}>Start Replay</button>
                    <button id="restart" className="white-font mx-2 btn smaller-size" onClick={this.restart}>Restart</button>
                    <button id="prev" className="white-font mx-2 btn smaller-size" onClick={this.prev}>Previous Move</button>
                    <button id="next" className="white-font mx-2 btn smaller-size" onClick={this.next}>Next Move</button>
                    <button id="end" className="white-font mx-2 btn smaller-size" onClick={this.finish}>Finish</button>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GameObserver)