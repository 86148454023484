import { Component } from 'react'
import ListOngoingGames from './ListOngoingGames.js'

class Join extends Component {
    componentDidMount() {
        var el = document.getElementById("bg")
        if (el) {
            el.classList.add("indoor-bg")
        }

        var navbar = document.getElementById("navbarSupportedContent")
        if (navbar) {
            navbar.classList.remove("show")
        }
    }

    render() {
        return (
            <div className="join-games position-relative">
                <ListOngoingGames />
            </div>
        )
    }
}

export default Join