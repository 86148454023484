import { Component } from 'react'
import { connect } from 'react-redux'

import { socket } from '../components/App'

import {
    mapStateToProps,
    mapDispatchToProps
} from '../functions/mapStateToProps'

var that

class Players extends Component {
    constructor() {
        super()
        this.state = { player1: 'No player', rating1: 'Rating not fetched', player2: 'No player', rating2: 'Rating not fetched' }
        that = this
    }

    handleSocket() {
        if (socket) {
            socket.on('DataSended', (data) => {
                if (data.names.length == 1) {
                    that.setState({ player1: data.names[0], rating1: data.ratings[0] })
                } else if (data.names.length == 2) {
                    that.setState({ player1: data.names[0], rating1: data.ratings[0], player2: data.names[1], rating2: data.ratings[1] })
                }
            })
        }
    }

    displayInstruction(el) {
        var attribute = el.target.getAttribute('state')
        var div
        if (attribute == 'active') {
            div = 'User is online!'
        } else if (attribute == 'telegram') {
            div = 'User telegram account is connected!'
        } else {
            if (el.target.getAttribute('src') == "/img/active.png") {
                div = 'User is online!'
            } else {
                div = 'User is offline!'
            }
        }
        if (el.target.id == 'p1') {
            var element = document.getElementById("p1-place")
            if (element) {
                element.innerHTML = ''
                element.innerHTML += div
                element.classList.remove("hidden")
            }
        } else {
            var element = document.getElementById("p2-place")
            if (element) {
                element.innerHTML = ''
                element.innerHTML += div
                element.classList.remove("hidden")
            }
        }
    }

    hideInstruction() {
        var elements = document.getElementsByClassName('activity-tip')
        for (var i = 0; i < elements.length; i++) {
            elements[i].classList.add("hidden")
        }
    }

    componentDidMount() {
        this.handleSocket()
        var params = window.location.pathname
        params = params.split("/")
        if (params[1] && params[2]) {
            if (params[2].length > 20) {
                var el = document.getElementById("game-id")
                if (el) {
                    el.parentNode.classList.add("hidden")
                }
            } else {
                var el = document.getElementById("game-id")
                if (el) {
                    el.textContent = params[2]
                }
            }
        }
    }

    render() {
        return (
            <div className="my-4 hidden players">
                <ul className="my-4 user-data">
                    <div id="red-player-one" className="d-flex p-relative">
                        <img src="/img/right.png" style={{ position: 'absolute' }} className="player1-arrow smallest-img height-max activity hidden" />
                        <ol className="position-relative my-4 p-2 white-font fixed-name-widht smaller-size"><span id="user1">{this.state.player1}</span> - {this.state.rating1}: <div id="p1-place" className="position-absolute neon-border white-font dark-bg hidden smaller-padding-important activity-tip neon-border" style={{ right: -150 + 'px', zIndex: 100, fontSize: 15 + 'px' }}></div></ol>
                        <div id="white-piece" className="my-3">
                            <img src="/img/pawn_white.png" id="white-pawn" className="chess-piece" alt="white pawn" />
                        </div>
                        <svg id="red-player-hex" className="architecture mt-4" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40">
                            <polygon className="hex-field red-player" points="20,0 40,10 40,30 20,40 0,30 0,10"></polygon>
                        </svg>
                    </div>
                    <div id="blue-player-two" className="d-flex p-relative">
                        <img src="/img/right.png" style={{ position: 'absolute' }} className="player2-arrow smallest-img height-max activity hidden" />
                        <ol className="position-relative my-4 p-2 white-font fixed-name-widht smaller-size"><span id="user2">{this.state.player2}</span> - {this.state.rating2}: <div id="p2-place" className="position-absolute neon-border white-font dark-bg hidden smaller-padding-important activity-tip neon-border" style={{ right: -150 + 'px', zIndex: 100, fontSize: 15 + 'px' }}></div></ol>
                        <div id="black-piece" className="my-3">
                            <img src="/img/pawn_black.png" id="black-pawn" className="chess-piece" alt="white pawn" />
                        </div>
                        <svg id="blue-player-hex" className="architecture mt-4" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40">
                            <polygon className="hex-field blue-player" points="20,0 40,10 40,30 20,40 0,30 0,10"></polygon>
                        </svg>
                    </div>
                </ul>
                <ul className="my-5 game-data px-4">
                    <div>
                        <h2 className="y-font smaller-size">Move time remaining: <span id="time-left" className="bigger-size">24:00:00</span></h2>
                        <h2 className="white-font smaller-size" >Pot size: <span id="pot-size"></span></h2>
                        <h2 className="white-font smaller-size" >Game ID: <span id="game-id"></span></h2>
                        <h2 className="white-font smaller-size" >Winner takes all the crypto USDC - less 11% fee.</h2>
                    </div>
                </ul>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Players)