import { Component } from 'react'
import { connect } from 'react-redux'
import Hex from './Hex'
import ChessComponent from './Chess'
import NotFound from './GameNotFound'

import { socket } from './App'

import {
    mapStateToProps,
    mapDispatchToProps
} from '../functions/mapStateToProps'

var that

class Game extends Component {
    constructor() {
        super()
        this.state = { game: <div></div> }
        that = this
    }

    chooseGame(game) {
        switch (game) {
            case "Hex":
                that.setState({ game: <Hex gameState={this.props.game} /> })
                break
            case "Chess":
                that.setState({ game: <ChessComponent /> })
                break
            default:
                that.setState({ game: <div></div> })
        }
    }

    checkIfExists(game, id) {
        that.props.checkIfGameExist(game, id, window.localStorage.getItem('account'))
    }

    joinRoom(props) {
        socket.emit('joinRoom', ({ gameName: props.gameName, roomNumber: props.roomNumber, account: props.account, token: props.token, refreshToken: props.refreshToken }))
        socket.on('UNF', () => {
            that.props.history.push("/userNotFound")
        })

        // socket.on('Unauthorized', () => {
        //     that.props.history.push("/unauthorized")
        // })
    }

    renderGame() {
        if (that.props.game.respond == "GF") {
            that.joinRoom({ gameName: this.props.match.params.game, roomNumber: this.props.match.params.id, account: window.localStorage.getItem('account'), token: window.localStorage.getItem('token'), refreshToken: window.localStorage.getItem('refreshToken') })
        }

        if (that.props.game.respond == "GNF") {
            return <NotFound />
        }
    }

    componentDidMount() {
        var el = document.getElementById("bg")
        if (el) {
            el.classList.add("indoor-bg")
        }
        this.checkIfExists(this.props.match.params.game, this.props.match.params.id)
        this.chooseGame(this.props.match.params.game)
    }

    render() {
        return (
            <div className="game">
                {this.renderGame()}
                {this.state.game}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Game)