import { Component } from 'react'
import { socket } from './App'

class WithdrawConfirmation extends Component {
    constructor() {
        super()
    }

    confirmWithdraw() {
        var el = document.getElementById("withdraw-confirm")
        if (el) {
            el.classList.add("hidden")
        }
        var params = window.location.pathname
        params = params.split("/")
        socket.emit('withdraw', ({ account: window.localStorage.getItem("account"), token: window.localStorage.getItem("token"), refreshToken: window.localStorage.getItem("refreshToken"), gameName: params[1], roomNumber: params[2] }))
    }

    rejectWihdraw() {
        var el = document.getElementById("withdraw-confirm")
        if (el) {
            el.classList.add("hidden")
        }
    }

    render() {
        return (
            <div id="withdraw-confirm" className="alligned-middle dark-bg text-center px-5 py-5 radius small-padding-mobile hidden new-prompts">
                <p className="text-center blue-font my-0">Are you sure you want to finish this game and forfeit USDC back to your account?</p>

                <div className="d-inline-flex text-center mx-auto max-content">
                    <button id="confirm-withdraw" className="btn own-btn yellow-font mx-2" onClick={this.confirmWithdraw}>Confirm</button>
                    <button id="reject-withdraw" className="btn own-btn yellow-font mx-2" onClick={this.rejectWihdraw}>Reject</button>
                </div>
            </div>
        )
    }
}

export default WithdrawConfirmation