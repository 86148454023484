import { Component } from 'react'
import {
    Link
} from "react-router-dom";


class Lost extends Component {
    render() {
        return (
            <div className="alligned-middle dark-bg text-center px-5 py-5 radius small-padding-mobile new-prompts">
                <h2 className="yellow-font text-center my-2">Defeat</h2>
                <img src="/img/lost.png" alt="Won" className="small-image text-center mx-auto my-2" />
                <p className="text-center blue-font my-0">The other player has beaten you this time.</p>

                <div className="d-inline-flex text-center mx-auto max-content"><img className="arrows" src="/img/right.png" alt="right" /><Link to="/join" className="blue-font no-button big-height-back" >Get back on the horse</Link><img className="arrows" src="/img/left.png" alt="left" /></div>
            </div>
        )
    }
}

export default Lost