import { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { mapStateToProps, mapDispatchToProps } from '../functions/mapStateToProps'
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react'
import {
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    SolletExtensionWalletAdapter,
    SolletWalletAdapter,
    TorusWalletAdapter
} from '@solana/wallet-adapter-wallets'
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { clusterApiUrl, Connection, PublicKey } from '@solana/web3.js'
import { AccountLayout, TOKEN_PROGRAM_ID } from '@solana/spl-token'

require('@solana/wallet-adapter-react-ui/styles.css')

var that, ref, id
class ConnectSolana extends Component {
    constructor(props) {
        super(props)
        that = this
        this.state = {
            network: process.env.REACT_APP_NETWORK,
            endpoint: clusterApiUrl(process.env.REACT_APP_NETWORK),
            wallets: [
                new PhantomWalletAdapter(),
                // new SlopeWalletAdapter(),
                // new SolflareWalletAdapter({ network }),
                // new TorusWalletAdapter(),
                // new SolletWalletAdapter({ network }),
                // new SolletExtensionWalletAdapter({ network }),
            ],
            wallet: null,
            jsx: <div></div>
        }
    }

    async showTokens() {
        const provider = window.solana
        if (provider.isPhantom) {
            var connection = new Connection(
                clusterApiUrl(process.env.REACT_APP_NETWORK),
                'confirmed'
            )

            const tokenAccounts = await connection.getTokenAccountsByOwner(
                new PublicKey(window.localStorage.getItem('account')),
                {
                    programId: TOKEN_PROGRAM_ID,
                }
            )
            tokenAccounts.value.forEach((e) => {
                const accountInfo = AccountLayout.decode(e.account.data)
                if (process.env.REACT_APP_USDC_ADDRESS == accountInfo.mint) {
                    var amount = accountInfo.amount
                    var normalAmount = amount.toString()
                    normalAmount /= Math.pow(10, process.env.REACT_APP_PRECISION)
                    var el = document.getElementsByClassName('your-usdc')
                    if (el[0]) {
                        el[0].textContent = normalAmount
                    }
                }
            })
        }
    }

    startCheckingInterval() {
        id = setInterval(() => {
            for (var i = 0; i < that.state.wallets.length; i++) {
                if (that.state.wallets[i]._wallet) {
                    if (that.state.wallets[i].name == "Solflare" || that.state.wallets[i].name == "Slope") {
                        window.localStorage.setItem('account', that.state.wallets[i]._publicKey)
                        that.props.fetchUsername(that.state.wallets[i]._publicKey, window.localStorage.getItem("token"), window.localStorage.getItem("refreshToken"))
                        that.setState({ wallet: that.state.wallets[i]._publicKey })
                    } else {
                        window.localStorage.setItem('account', that.state.wallets[i]._wallet._publicKey)
                        that.props.fetchUsername(that.state.wallets[i]._wallet._publicKey, window.localStorage.getItem("token"), window.localStorage.getItem("refreshToken"))
                        that.setState({ wallet: that.state.wallets[i]._wallet._publicKey })
                    }
                    that.stopCheckingInterval()
                    break
                }
            }
        }, 100)
    }

    stopCheckingInterval() {
        if (id) {
            clearInterval(id)
        }
    }

    deleteUnusedElemets() {
        var el = document.getElementsByClassName('wallet-adapter-modal-middle')
        var el2 = document.getElementsByClassName('wallet-adapter-modal-list-more')

        if (el[0]) {
            el[0].remove()
        }

        if (el2[0]) {
            el2[0].remove()
        }
    }

    welcomeTour() {
        that.props.history.push('/welcomeTour')
    }

    renderAccountNumber() {
        return (
            <div className="d-flex max-content mx-auto">
                <ConnectionProvider endpoint={this.state.endpoint}>
                    <WalletProvider wallets={this.state.wallets} autoConnect>
                        <WalletModalProvider>
                            <div className="d-sm-flex max-content mx-auto">
                                <WalletMultiButton onClick={that.showWallets} />
                                <button id="onBoard" className="blue-font no-button ms-3 me-sm-5 me-sm-1-own" onClick={this.welcomeTour}>Welcome tour</button>
                            </div>
                        </WalletModalProvider>
                    </WalletProvider>
                </ConnectionProvider>
            </div>
        )
    }

    showWallets() {
        that.stopCheckingInterval()
        that.startCheckingInterval()
    }

    login() {
        var pass = document.getElementById("password")
        if (pass) {
            that.props.askNewRefreshToken(window.localStorage.getItem("account"), pass.value)
        }
    }

    createPassword() {
        var pass = document.getElementById("password")
        var rpass = document.getElementById("repeat-password")
        if (pass && rpass) {
            if (rpass.value == pass.value) {
                that.props.changePassword(window.localStorage.getItem("account"), pass.value)
            } else {
                that.passwordsDontMatch()
            }
        }
    }

    passwordsDontMatch() {

    }

    renderUsername() {
        if (this.props.game.respond == "NF") {

        }

        if (this.props.game.respond == 'Unauthorized') {
            return <Redirect to="/unauthorized" />
        }
        if (this.props.user.status == "Cannot fetch username" || this.props.user.status == "Fetching username" || this.props.user.respond == 'NF') {

        } else {
            if (this.props.user.respond == 'Unauthorized') {
                return <Redirect to="/unauthorized" />
            }
            if (window.localStorage.getItem("account") != undefined) {

            }

            if (this.props.user.respond == 'UG') {
                window.localStorage.setItem("name", this.props.user.name)
                window.localStorage.setItem('adminToken', this.props.user.adminToken)
                window.localStorage.setItem('token', this.props.user.token)
                window.localStorage.setItem('refreshToken', this.props.user.refreshToken)
                var el = document.getElementsByClassName('your-username')
                if (el[0]) {
                    el[0].textContent = window.localStorage.getItem('name')
                }
                document.getElementsByClassName('navbar-nav')[0].style.display = ''
                document.getElementsByClassName('navbar-nav')[1].style.display = ''
                document.getElementsByClassName('navbar-toggler')[0].style.display = ''
                document.getElementById('hc').style.display = 'none'
                that.showTokens()
            }

            if (this.props.user.respond == "NP") {
                document.getElementsByClassName('navbar-nav')[0].style.display = 'none'
                document.getElementsByClassName('navbar-nav')[1].style.display = 'none'
                document.getElementsByClassName('navbar-toggler')[0].style.display = 'none'
                document.getElementById('hc').style.display = ''
                return (
                    <div className="new-prompts">
                        <p className="white-font text-center first-margin">Create password for your account:</p>
                        <input type="password" className="shining-border-input mb-2 mx-auto" name="password" id="password"></input>
                        <p className="white-font text-center first-margin">Repeat password:</p>
                        <input type="password" className="shining-border-input mb-2 mx-auto" name="repeat-password" id="repeat-password"></input>
                        <div className="text-center mx-auto my-4">
                            <button id="password" className="btn own-btn" onClick={that.createPassword}>CREATE PASSWORD</button>
                        </div>
                    </div>
                )
            }

            if (this.props.user.respond == "BP") {
                document.getElementsByClassName('navbar-nav')[0].style.display = 'none'
                document.getElementsByClassName('navbar-nav')[1].style.display = 'none'
                document.getElementsByClassName('navbar-toggler')[0].style.display = 'none'
                return (
                    <div className="new-prompts">
                        <p className="white-font text-center first-margin">Password is incorrect, please try again:</p>
                        <input type="password" className="shining-border-input mb-2 mx-auto" name="password" id="password"></input>
                        <div className="text-center mx-auto my-4">
                            <button id="password" className="btn own-btn" onClick={that.login}>LOGIN</button>
                        </div>
                    </div>
                )
            }

            if (this.props.user.respond == 'Logout') {
                window.localStorage.removeItem("name")
                window.localStorage.removeItem('adminToken')
                window.localStorage.removeItem('token')
                window.localStorage.removeItem('refreshToken')
                document.getElementsByClassName('navbar-nav')[0].style.display = 'none'
                document.getElementsByClassName('navbar-nav')[1].style.display = 'none'
                document.getElementsByClassName('navbar-toggler')[0].style.display = 'none'
                document.getElementById('hc').style.display = ''
                return (
                    <div className="new-prompts">
                        <p className="white-font text-center first-margin">Your password:</p>
                        <input type="password" className="shining-border-input mb-2 mx-auto" name="password" id="password"></input>
                        <div className="text-center mx-auto my-4">
                            <button id="password" className="btn own-btn" onClick={that.login}>LOGIN</button>
                        </div>
                    </div>
                )
            }
            if (this.props.user.respond == 'RTG') {
                window.localStorage.setItem("refreshToken", this.props.user.refreshToken)
                document.getElementsByClassName('navbar-nav')[0].style.display = ''
                document.getElementsByClassName('navbar-nav')[1].style.display = ''
                document.getElementsByClassName('navbar-toggler')[0].style.display = ''
                that.showTokens()
            }
            if (this.props.user.respond == 'TG') {
                window.localStorage.setItem("token", this.props.user.token)
                document.getElementsByClassName('navbar-nav')[0].style.display = ''
                document.getElementsByClassName('navbar-nav')[1].style.display = ''
                document.getElementsByClassName('navbar-toggler')[0].style.display = ''
                that.showTokens()
            }

            if (this.props.user.status == "Asking for refresh token") {
                document.getElementsByClassName('navbar-nav')[0].style.display = 'none'
                document.getElementsByClassName('navbar-nav')[1].style.display = 'none'
                document.getElementsByClassName('navbar-toggler')[0].style.display = 'none'
            }
        }
    }

    toTerms(e) {
        e.preventDefault()
        that.props.history.push('/terms')
    }

    toPrivacy(e) {
        e.preventDefault()
        that.props.history.push('/privacy')
    }

    renderRegisterForm() {
        if (this.props.game.respond == "NF") {
            document.getElementsByClassName('hide-connected')[0].style.display = 'none'
            document.getElementsByClassName('navbar-nav')[0].style.display = 'none'
            document.getElementsByClassName('navbar-nav')[1].style.display = 'none'
            document.getElementsByClassName('navbar-toggler')[0].style.display = 'none'
            var metamask = document.getElementById("connectingMetamask")
            if (metamask) {
                metamask.classList.add("register-input")
            }
            if (ref != undefined) {
                return (
                    <div className="new-prompts">
                        <p className="white-font text-center first-margin">Create own Handle:</p>
                        <input type="text" className="shining-border-input mb-2 mx-auto" name="username" id="username"></input>
                        <p className="white-font text-center first-margin">Choose password:</p>
                        <input type="password" className="shining-border-input mb-2 mx-auto" name="password" id="password"></input>
                        <p className="white-font text-center first-margin">Repeat password:</p>
                        <input type="password" className="shining-border-input mb-2 mx-auto" name="repeat-password" id="repeat-password"></input>
                        <div className="text-center mx-auto my-4">
                            <button id="username" className="btn own-btn" onClick={this.registerUser}>SIGN IN</button>
                        </div>
                        <p className="white-font text-center first-margin">By registering you accept <a href="#" className="white-font" onClick={that.toTerms}>Terms and Conditions</a> and <a href="#" className="white-font" onClick={that.toPrivacy}>Privacy Policy</a></p>
                    </div>
                )
            } else {
                return (
                    <div className="new-prompts">
                        <p className="white-font text-center first-margin">Create own Handle:</p>
                        <input type="text" className="shining-border-input mb-2 mx-auto" name="username" id="username"></input>
                        <p className="white-font text-center first-margin">Choose password:</p>
                        <input type="password" className="shining-border-input mb-2 mx-auto" name="password" id="password"></input>
                        <p className="white-font text-center first-margin">Repeat password:</p>
                        <input type="password" className="shining-border-input mb-2 mx-auto" name="repeat-password" id="repeat-password"></input>
                        <div className="text-center mx-auto my-4">
                            <button id="username" className="btn own-btn" onClick={this.registerUser}>SIGN IN</button>
                        </div>
                        <p className="white-font text-center first-margin">By registering you accept <a href="#" className="white-font" onClick={that.toTerms}>Terms and Conditions</a> and <a href="#" className="white-font" onClick={that.toPrivacy}>Privacy Policy</a></p>
                    </div>
                )
            }
        }

        if (this.props.user.status == "Cannot fetch username" || this.props.user.respond == 'NF') {
            document.getElementsByClassName('hide-connected')[0].style.display = 'none'
            document.getElementsByClassName('navbar-nav')[0].style.display = 'none'
            document.getElementsByClassName('navbar-nav')[1].style.display = 'none'
            document.getElementsByClassName('navbar-toggler')[0].style.display = 'none'
            var metamask = document.getElementById("connectingMetamask")
            if (metamask) {
                metamask.classList.add("register-input")
            }
            if (ref != undefined) {
                return (
                    <div className="new-prompts">
                        <p className="white-font text-center first-margin">Create own Handle:</p>
                        <input type="text" className="shining-border-input mb-2 mx-auto" name="username" id="username"></input>
                        <p className="white-font text-center first-margin">Choose password:</p>
                        <input type="password" className="shining-border-input mb-2 mx-auto" name="password" id="password"></input>
                        <p className="white-font text-center first-margin">Repeat password:</p>
                        <input type="password" className="shining-border-input mb-2 mx-auto" name="repeat-password" id="repeat-password"></input>
                        <div className="text-center mx-auto my-4">
                            <button id="username" className="btn own-btn" onClick={this.registerUser}>SIGN IN</button>
                        </div>
                        <p className="white-font text-center first-margin">By registering you accept <a href="#" className="white-font" onClick={that.toTerms}>Terms and Conditions</a> and <a href="#" className="white-font" onClick={that.toPrivacy}>Privacy Policy</a></p>
                    </div>
                )

            } else {
                return (
                    <div className="new-prompts">
                        <p className="white-font text-center first-margin">Create own Handle:</p>
                        <input type="text" className="shining-border-input mb-2 mx-auto" name="username" id="username"></input>
                        <p className="white-font text-center first-margin">Choose password:</p>
                        <input type="password" className="shining-border-input mb-2 mx-auto" name="password" id="password"></input>
                        <p className="white-font text-center first-margin">Repeat password:</p>
                        <input type="password" className="shining-border-input mb-2 mx-auto" name="repeat-password" id="repeat-password"></input>
                        <div className="text-center mx-auto my-4">
                            <button id="username" className="btn own-btn" onClick={this.registerUser}>SIGN IN</button>
                        </div>
                        <p className="white-font text-center first-margin">By registering you accept <a href="#" className="white-font" onClick={that.toTerms}>Terms and Conditions</a> and <a href="#" className="white-font" onClick={that.toPrivacy}>Privacy Policy</a></p>
                    </div>
                )
            }
        }

        if (this.props.user.status == "Fetching username") {
            document.getElementsByClassName('hide-connected')[0].style.display = 'none'
            document.getElementsByClassName('navbar-nav')[0].style.display = 'none'
            document.getElementsByClassName('navbar-nav')[1].style.display = 'none'
            document.getElementsByClassName('navbar-toggler')[0].style.display = 'none'
        }

        if (this.props.user.respond == 'HT') {
            document.getElementsByClassName('hide-connected')[0].style.display = 'none'
            document.getElementsByClassName('navbar-nav')[0].style.display = 'none'
            document.getElementsByClassName('navbar-nav')[1].style.display = 'none'
            document.getElementsByClassName('navbar-toggler')[0].style.display = 'none'
            var metamask = document.getElementById("connectingMetamask")
            if (metamask) {
                metamask.classList.add("register-input-error")
            }
            if (ref != undefined) {
                return (
                    <div className="new-prompts">
                        <p className="white-font text-center first-margin">User with that handle already exists!</p>
                        <p className="white-font text-center first-margin">Create own Handle:</p>
                        <input type="text" className="shining-border-input mb-2 mx-auto" name="username" id="username"></input>
                        <p className="white-font text-center first-margin">Choose password:</p>
                        <input type="password" className="shining-border-input mb-2 mx-auto" name="password" id="password"></input>
                        <p className="white-font text-center first-margin">Repeat password:</p>
                        <input type="password" className="shining-border-input mb-2 mx-auto" name="repeat-password" id="repeat-password"></input>
                        <div className="text-center mx-auto my-4">
                            <button id="username" className="btn own-btn" onClick={this.registerUser}>SIGN IN</button>
                        </div>
                        <p className="white-font text-center first-margin">By registering you accept <a href="#" className="white-font" onClick={that.toTerms}>Terms and Conditions</a> and <a href="#" className="white-font" onClick={that.toPrivacy}>Privacy Policy</a></p>
                    </div>
                )

            } else {
                return (
                    <div className="new-prompts">
                        <p className="white-font text-center first-margin">User with that handle already exists!</p>
                        <p className="white-font text-center first-margin">Create own Handle:</p>
                        <input type="text" className="shining-border-input mb-2 mx-auto" name="username" id="username"></input>
                        <p className="white-font text-center first-margin">Choose password:</p>
                        <input type="password" className="shining-border-input mb-2 mx-auto" name="password" id="password"></input>
                        <p className="white-font text-center first-margin">Repeat password:</p>
                        <input type="password" className="shining-border-input mb-2 mx-auto" name="repeat-password" id="repeat-password"></input>
                        <div className="text-center mx-auto my-4">
                            <button id="username" className="btn own-btn" onClick={this.registerUser}>SIGN IN</button>
                        </div>
                        <p className="white-font text-center first-margin">By registering you accept <a href="#" className="white-font" onClick={that.toTerms}>Terms and Conditions</a> and <a href="#" className="white-font" onClick={that.toPrivacy}>Privacy Policy</a></p>
                    </div>
                )
            }
        }
    }

    registerUser() {
        const userButton = document.getElementById('username')
        const passButton = document.getElementById('password')
        if (userButton && passButton) {
            const passRButton = document.getElementById('repeat-password')
            if (passRButton) {
                if (passButton.value != "") {
                    if (passButton.value == passRButton.value) {
                        that.props.createAccount(window.localStorage.getItem('account'), userButton.value, passButton.value)

                        window.localStorage.setItem('first-time', true)
                    } else {
                        that.passwordsDontMatch()
                    }
                }
            }
        }
    }

    accountCreated() {
        if (this.props.user.respond == 'UF') {
            window.localStorage.setItem('adminToken', this.props.user.adminToken)
            window.localStorage.setItem('name', this.props.user.name)
            document.getElementsByClassName('navbar-nav')[0].style.display = ''
            document.getElementsByClassName('navbar-nav')[1].style.display = ''
            document.getElementsByClassName('navbar-toggler')[0].style.display = ''
            var el = document.getElementsByClassName('your-username')
            if (el[0]) {
                el[0].textContent = window.localStorage.getItem('name')
            }
            that.showTokens()
            return 'Something went wrong! User is created already!'
        }
        else if (this.props.user.respond == 'UC') {
            window.localStorage.setItem('refreshToken', this.props.user.refreshToken)
            window.localStorage.setItem('token', this.props.user.token)
            window.localStorage.setItem('name', this.props.user.name)
            window.localStorage.setItem('adminToken', this.props.user.adminToken)
            document.getElementsByClassName('navbar-nav')[0].style.display = ''
            document.getElementsByClassName('navbar-nav')[1].style.display = ''
            document.getElementsByClassName('navbar-toggler')[0].style.display = ''
            var el = document.getElementsByClassName('your-username')
            if (el[0]) {
                el[0].textContent = window.localStorage.getItem('name')
            }
            that.showTokens()
            alert('Welcome to TheTables! To get started, you must have some USDC. Next go to the Lobby to find or host a game.')
            var result = window.confirm('Do you want to add telegram and discord to receive notifications right now?')
            this.props.user.respond = 'UCC'
            if (result) {
                return <Redirect to="/playerProfile" />
            }
        }
    }

    componentDidMount() {
        var refArr = window.location.search.substr(1).split('&')
        var refLinkArr = refArr[0].split("=")
        ref = refLinkArr[1]
        document.getElementsByClassName('navbar-nav')[0].style.display = 'none'
        document.getElementsByClassName('navbar-nav')[1].style.display = 'none'
        document.getElementsByClassName('navbar-toggler')[0].style.display = 'none'
        this.stopCheckingInterval()
        this.startCheckingInterval()
        this.deleteUnusedElemets()
        window.localStorage.removeItem("shown")
        window.localStorage.removeItem("showing")
    }

    render() {
        window.localStorage.setItem('account', that.state.wallet)
        return (
            <div id="connectingMetamask" className="position-absolute z-200">
                <div id="hc" className="align-middle text-center registering hide-connected">
                    {this.renderAccountNumber()}
                    {this.renderUsername()}
                    {this.accountCreated()}
                    {this.state.jsx}
                </div>
                <div id="add-prompt" className="align-middle padding-small radius empty registering">
                    {this.renderRegisterForm()}
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectSolana)