import {
    LIST_TOKENS_REQUEST,
    LIST_TOKENS_SUCCESS,
    LIST_TOKENS_ERROR
} from '../actions/admin-actions'

export default function tokens(state = {}, action) {
    switch (action.type) {
        case LIST_TOKENS_REQUEST:
            return "Getting tokens"
        case LIST_TOKENS_SUCCESS:
            return (action.payload)
        case LIST_TOKENS_ERROR:
            return (action.payload)
        default:
            return state
    }
}