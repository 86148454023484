import { Component } from 'react'

var that

class Menu extends Component {
    constructor() {
        super()
        that = this
    }

    componentDidMount() {
        var el = document.getElementsByClassName('your-username')
        if (el[0]) {
            el[0].textContent = window.localStorage.getItem('name')
        }
    }

    main(e) {
        e.preventDefault()
        that.props.history.push("/")
    }

    toHost(e) {
        e.preventDefault()
        that.props.history.push("/host")
    }

    toJoin(e) {
        e.preventDefault()
        that.props.history.push("/join")
    }

    myGames(e) {
        e.preventDefault()
        that.props.history.push("/myGames")
    }

    FAQ(e) {
        e.preventDefault()
        that.props.history.push("/FAQ")
    }

    playerProfile(e) {
        e.preventDefault()
        that.props.history.push('/playerProfile')
    }

    finishedGames(e) {
        e.preventDefault()
        that.props.history.push("/finishedGames")
    }

    welcomeTour(e) {
        e.preventDefault()
        that.props.history.push('/welcomeTour')
    }

    tutorials(e) {
        e.preventDefault()
        that.props.history.push('/tutorials')
    }

    logout(e) {
        e.preventDefault()
        window.localStorage.removeItem("name")
        window.localStorage.removeItem('adminToken')
        window.localStorage.removeItem('token')
        window.localStorage.removeItem('refreshToken')
        window.location.pathname = "/"
    }

    mouseOver(e) {
        var classes = e.target.classList
        if (classes.contains("tooltip-usdc")) {
            var el = document.getElementById('tooltip-usdc')
            if (el) {
                el.classList.remove('hidden')
            }
        }
    }

    mouseOut(e) {
        var el = document.getElementById('tooltip-usdc')
        if (el) {
            el.classList.add('hidden')
        }
    }

    render() {
        return (
            <div>
                <nav className="navbar navbar-expand-xl p-sm-5">
                    <div className="container-fluid">
                        <a href="#" onClick={this.main} className="position-relative">
                            <img src="/img/logo.png" alt="logo" className="img-fluid img-sm position-relative z-100" />
                            <img src="/img/red-decal.png" alt="decal" className="img-fluid decal-material position-absolute" />
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mr-fix mb-2 mb-xl-0">

                                <li className="nav-item dropdown">
                                    <a className="dropdown-toggle nav-link" aria-expanded="false" data-bs-toggle="dropdown"
                                        href="#">Play</a>
                                    <div className="dropdown-menu">
                                        {/* <a className="dropdown-item" aria-current="page" href="#" onClick={this.matchmaking}>Matchmaking</a> */}
                                        <a className="dropdown-item" aria-current="page" href="#" onClick={this.myGames}>My Matches</a>
                                        <a className="dropdown-item" aria-current="page" href="#" onClick={this.toHost}>Host a Match</a>
                                        <a className="dropdown-item" aria-current="page" href="#" onClick={this.toJoin}>Join a Match</a>
                                    </div>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="dropdown-toggle nav-link" aria-expanded="false" data-bs-toggle="dropdown"
                                        href="#">Manage</a>
                                    <div className="dropdown-menu">
                                        {/* <a className="dropdown-item" aria-current="page" href="#" onClick={this.redirectCoins}>Cashier</a> */}
                                        <a className="dropdown-item" aria-current="page" href="#" onClick={this.finishedGames}>Past Matches</a>
                                        <a className="dropdown-item" aria-current="page" href="#" onClick={this.playerProfile}>My profile</a>
                                        {/* <a className="dropdown-item" aria-current="page" href="#" onClick={this.transactions}>Transfers</a> */}
                                    </div>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="dropdown-toggle nav-link" aria-current="false" data-bs-toggle="dropdown" href="#">Help</a>
                                    <div className="dropdown-menu">
                                        <a className="dropdown-item" aria-current="page" href="#" onClick={this.tutorials}>Play guide</a>
                                        {/* <a className="dropdown-item" aria-current="page" href="#" onClick={this.FAQ}>FAQ</a> */}
                                    </div>
                                </li>
                                {/* <li className="nav-item dropdown">
                                    <a className="nav-link" aria-current="page" href="#" onClick={this.welcomeTour}>Get Started</a>
                                </li> */}
                                <li className="nav-item dropdown">
                                    <a className="nav-link" aria-current="page" href="#" onClick={this.logout}>Logout</a>
                                </li>
                            </ul>
                            <ul className="navbar-nav ms-auto mb-2 mb-xl-0 money">
                                <li className="nav-item">
                                    <span className="your-usdc dark-blue-font lh-53 clickable" onMouseOver={this.mouseOver} onMouseOut={this.mouseOut}>0</span><img src="/img/usdc.png" className="tiny-image mt--10" alt="USDC" />
                                    <div id="tooltip-usdc" className="new-prompts position-absolute neon-border white-font dark-bg hidden smaller-padding-important" style={{ right: 30 + 'px', zIndex: 1000 }}>Please switch to PlayableWeb blockchain<br></br>in your MetaMask to see your balance</div>
                                </li>
                                <li className="nav-item">
                                    <span className="your-username dark-blue-font lh-53"></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        )
    }
}
export default Menu