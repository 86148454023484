import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom"
import { AccountLayout, TOKEN_PROGRAM_ID, createTransferInstruction } from '@solana/spl-token'
import { Connection, clusterApiUrl, Transaction, PublicKey } from '@solana/web3.js'

import {
    mapStateToProps,
    mapDispatchToProps
} from '../functions/mapStateToProps'

import ChooseGame from './ChooseGame'
import RankingGameOption from './RankingGameOption'


import { socket } from './App'

var that
var redirecting

class GameOptions extends Component {

    constructor(props) {
        super(props)
        this.state = { blockchain: "" }
        that = this
        redirecting = false
    }

    checkRankingProperties(rankRadio) {
        const bet = document.getElementById("bet")
        const betValue = bet.value / 1
        var betting = true
        if (betValue < 5) {
            return false
        }
        return { ranking: true, isBetting: betting, bet: betValue }
    }

    checkTimeProperties(timeRadio) {
        if (timeRadio[0].checked) {
            const minutes = document.getElementsByName("minutes_range")
            var minutesForGame = minutes[0].value
            const seconds = document.getElementsByName("seconds_range")
            var secondsAddMove = seconds[0].value
            return { sync: true, minutes: minutesForGame, seconds: secondsAddMove }
        } else {
            const days = document.getElementsByName("days_range")
            var daysForMove = days[0].value
            return { sync: false, days: daysForMove }
        }
    }

    handleHostRedirect() {
        if (that.props.game.respond == 'GC') {
            socket.emit('getGamesAgain')
            that.redirect(that.props.game.gameDetails.gameName, that.props.game.gameDetails.roomNumber)
        }
    }

    async showTokens() {
        const provider = window.solana
        if (provider.isPhantom) {
            var connection = new Connection(
                clusterApiUrl(process.env.REACT_APP_NETWORK),
                'confirmed'
            )

            const tokenAccounts = await connection.getTokenAccountsByOwner(
                new PublicKey(window.localStorage.getItem('account')),
                {
                    programId: TOKEN_PROGRAM_ID,
                }
            )

            tokenAccounts.value.forEach((e) => {
                const accountInfo = AccountLayout.decode(e.account.data)
                if (process.env.REACT_APP_USDC_ADDRESS == accountInfo.mint) {
                    var amount = accountInfo.amount
                    var normalAmount = amount.toString()
                    normalAmount /= Math.pow(10, process.env.REACT_APP_PRECISION)
                    var el = document.getElementsByClassName('your-usdc')
                    if (el[0]) {
                        el[0].textContent = normalAmount
                    }
                }
            })
        }
    }

    async hostGame() {
        const rankRadio = document.getElementsByName("ranking-radio")
        const rankingObject = that.checkRankingProperties(rankRadio)
        if (rankingObject == false) {
            return
        }
        const game = document.getElementsByName("games")
        if (game[0].value == "Go") {
            that.setState({ blockchain: "Go game is coming out soon!" })
            return
        }

        if ("solana" in window) {
            const provider = window.solana
            var gotIn = false
            if (provider.isPhantom) {
                var connection = new Connection(
                    clusterApiUrl(process.env.REACT_APP_NETWORK),
                    'confirmed'
                )

                const tokenAccounts = await connection.getTokenAccountsByOwner(
                    new PublicKey(window.localStorage.getItem('account')),
                    {
                        programId: TOKEN_PROGRAM_ID,
                    }
                )

                tokenAccounts.value.forEach(async (e) => {
                    const accountInfo = AccountLayout.decode(e.account.data)
                    if (process.env.REACT_APP_USDC_ADDRESS == accountInfo.mint) {
                        gotIn = true
                        var recieverWallet = new PublicKey(process.env.REACT_APP_USDC_OUR_ACCOUNT)
                        const transaction = new Transaction().add(
                            createTransferInstruction(
                                new PublicKey(e.pubkey),
                                recieverWallet,
                                new PublicKey(window.localStorage.getItem('account')),
                                rankingObject.bet * Math.pow(10, process.env.REACT_APP_PRECISION),
                            )
                        )

                        transaction.feePayer = await provider.publicKey
                        let blockhashObj = await connection.getLatestBlockhash()
                        transaction.recentBlockhash = blockhashObj.blockhash
                        if (transaction) {
                            let signed = await provider.signTransaction(transaction)
                            let signature = await connection.sendRawTransaction(signed.serialize())
                            that.spinner()
                            await connection.confirmTransaction(signature)
                            that.spinnerHide()
                            that.showTokens()
                            that.props.createGame(
                                game[0].value,
                                window.localStorage.getItem('account'),
                                signature
                            )
                        }
                    }
                })

                if (!gotIn) {
                    // Show bad account prompt
                }
                gotIn = false
            }
        } else {
            window.open("https://www.phantom.app/", "_blank")
        }
    }

    spinner() {
        var host = document.getElementById('host')
        if (host) {
            host.disabled = true
        }

        var el = document.getElementById('spinner')
        if (el) {
            el.classList.remove('hidden')
        }
    }

    spinnerHide() {
        var host = document.getElementById('host')
        if (host) {
            host.disabled = false
        }

        var el = document.getElementById('spinner')
        if (el) {
            el.classList.add('hidden')
        }
    }

    redirect(gameName, roomNumber) {
        socket.emit('myIp', ({ account: window.localStorage.getItem('account'), name: window.localStorage.getItem('name') }))
        that.props.history.push('/' + gameName + '/' + roomNumber)
    }

    componentWillUnmount() {
        socket.emit('leaveAllHostUsers', (window.localStorage.getItem("account")))
    }

    componentDidMount() {
        socket.on('redirectPlayer', props => {
            if (props.account) {
                if (props.account.toUpperCase() == window.localStorage.getItem('account').toUpperCase()) {
                    that.redirect(props.gameName, props.roomNumber)
                }
            }
        })
    }

    render() {
        return (
            <div className="text-left blue-font smaller-font mt-5 neon-border container faq-container">
                <div className="py-4 text-center"><ChooseGame /></div>
                {/* <div><GameTimeOption /></div> */}
                <div><RankingGameOption /></div>
                <div className="text-center">
                    <button id="host" className="btn blue-font" onClick={this.hostGame}> HOST GAME </button>
                </div>
                {this.handleHostRedirect()}
                <p className="blue-font text-center mt-4">{this.state.blockchain}</p>
                <div id="spinner" className="position-absolute alligned-middle z-200 hidden text-center">
                    <div className="spinner-border text-primary" role="status">
                    </div><br></br>
                    Please do not close this window. You will be redirected automaticly.
                </div>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GameOptions))