import { Component } from 'react'

class Terms extends Component {
    constructor() {
        super()
    }

    componentDidMount() {
        var mm = document.getElementById("connectingMetamask")
        if (mm) {
            mm.classList.add("hidden")
        }

        var navbar = document.getElementById("navbarSupportedContent")
        if (navbar) {
            navbar.classList.remove("show")
        }
    }

    componentWillUnmount() {
        var mm = document.getElementById("connectingMetamask")
        if (mm) {
            mm.classList.remove("hidden")
        }
    }


    render() {
        return (
            <div className="blue-font w-700 mx-auto py-4 container-fluid width-100-mobile position-relative neon-border max-height-500 join-game-overflow">

            </div>
        )
    }
}

export default Terms