import { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from "react-router-dom";

import {
    mapStateToProps,
    mapDispatchToProps
} from '../functions/mapStateToProps'

var that

class ListMyGames extends Component {

    constructor(props) {
        super(props)
        that = this
    }

    syncChange() {
        that.props.listMyAsyncGames(window.localStorage.getItem("account"))
    }

    asyncChange() {
        that.props.listMyGames(window.localStorage.getItem("account"))
    }

    joinGame(e) {
        that.props.history.push(e.target.dataset.gamename + "/" + e.target.dataset.roomnumber)
    }

    renderGames() {
        if (that.props.game == "Cannot join game") {
            return "Cannot join this game"
        }
        if (that.props.game.respond == "OWNGAMES") {
            var jsx = []
            for (var i = 0; i < that.props.game.gameSettings.length; i++) {
                if (that.props.game.gameSettings[i].timeLeft != undefined && that.props.game.gameSettings[i].timeLeft != null && that.props.game.gameSettings[i].timeLeft != "null" && that.props.game.gameSettings[i].timeLeft != "none") {
                    var seconds = that.props.game.gameSettings[i].timeLeft % 60
                    if (seconds < 10) {
                        seconds = `0${seconds}`
                    }
                    var minutes = that.props.game.gameSettings[i].timeLeft / 60
                    minutes = Math.floor(minutes % 60)
                    if (minutes < 10) {
                        minutes = `0${minutes}`
                    }
                    var hours = Math.floor(that.props.game.gameSettings[i].timeLeft / (60 * 60))
                    if (hours < 10) {
                        hours = `0${hours}`
                    }
                    if (that.props.game.gameSettings[i].opponent == window.localStorage.getItem("name")) {
                        jsx.push(
                            <div className="my-4 d-md-flex blue-font neon-border-bottom-mobile">
                                <p className="col-md-3 text-center">{that.props.game.gameSettings[i].gameName}</p>
                                <p className="col-md-3 text-center">{that.props.game.gameSettings[i].opponent}</p>
                                <p className="col-md-2 text-center">{that.props.game.gameSettings[i].gameSettings.rank.bet}</p>
                                <p className="col-md-2 text-center">{hours + ":" + minutes + ":" + seconds}</p>
                                <div className="col-md-2 text-center d-flex position-relative">
                                    <button className="btn no-btn blue-font mx-auto" data-roomnumber={that.props.game.gameSettings[i].roomNumber} data-gamename={that.props.game.gameSettings[i].gameName} data-account={that.props.game.gameSettings[i].gameSettings.hostAccount} data-bet={that.props.game.gameSettings[i].gameSettings.rank.bet} onClick={this.joinGame}>Reconnect</button>
                                </div>
                            </div>)
                    } else {
                        jsx.push(
                            <div className="my-4 d-md-flex blue-font neon-border-bottom-mobile">
                                <p className="col-md-3 text-center">{that.props.game.gameSettings[i].gameName}</p>
                                <p className="col-md-3 text-center">{that.props.game.gameSettings[i].opponent}</p>
                                <p className="col-md-2 text-center">{that.props.game.gameSettings[i].gameSettings.rank.bet}</p>
                                <p className="col-md-2 text-center">{hours + ":" + minutes + ":" + seconds}</p>
                                <div className="col-md-2 text-center d-flex position-relative">
                                    <button className="btn no-btn blue-font mx-auto" data-roomnumber={that.props.game.gameSettings[i].roomNumber} data-gamename={that.props.game.gameSettings[i].gameName} data-account={that.props.game.gameSettings[i].gameSettings.hostAccount} data-bet={that.props.game.gameSettings[i].gameSettings.rank.bet} onClick={this.joinGame}>Reconnect</button>
                                </div>
                            </div>)
                    }
                } else {
                    jsx.push(
                        <div className="my-4 d-md-flex blue-font neon-border-bottom-mobile">
                            <p className="col-md-3 text-center">{that.props.game.gameSettings[i].gameName}</p>
                            <p className="col-md-3 text-center">{that.props.game.gameSettings[i].opponent}</p>
                            <p className="col-md-2 text-center">{that.props.game.gameSettings[i].gameSettings.rank.bet}</p>
                            <p className="col-md-2 text-center"></p>
                            <div className="col-md-2 text-center d-flex">
                                <button className="btn no-btn blue-font mx-auto" data-roomnumber={that.props.game.gameSettings[i].roomNumber} data-gamename={that.props.game.gameSettings[i].gameName} data-account={that.props.game.gameSettings[i].gameSettings.hostAccount} data-bet={that.props.game.gameSettings[i].gameSettings.rank.bet} onClick={this.joinGame}>Reconnect</button>
                            </div>
                        </div>)
                }
            }
            return <div>{jsx}</div>
        }

        if (that.props.game.respond == "OG") {
            return "You cannot join your own game!"
        }

        if (that.props.game.respond == "GJNT") {
            window.localStorage.setItem("token", that.props.game.game.token)
            return <Redirect to={that.props.game.game.gameName + "/" + that.props.game.game.roomNumber} />
        }
        if (that.props.game.respond == "GJ") {
            return <Redirect to={that.props.game.game.gameName + "/" + that.props.game.game.roomNumber} />
        }
    }

    componentDidMount() {
        that.props.listMyGames(window.localStorage.getItem("account"))
        var el = document.getElementById("bg")
        if (el) {
            el.classList.add("indoor-bg")
        }

        var navbar = document.getElementById("navbarSupportedContent")
        if (navbar) {
            navbar.classList.remove("show")
        }
    }

    render() {
        return (
            <div className="position-relative">
                <div className="neon-border no-padding-lr container faq-container max-height-500 join-game-overflow">
                    <div className="row neon-border-bottom">
                        <div className="col-md-3 game-name dark-blue-font text-center">
                            Game
                        </div>
                        <div className="col-md-3 host dark-blue-font text-center">
                            On Move
                        </div>
                        <div className="col-md-2 bet dark-blue-font text-center">
                            Bet
                        </div>
                        <div className="col-md-2 time-left dark-blue-font text-center">
                            Time Left
                        </div>
                        <div className="col-md-2 join-button dark-blue-font text-center">

                        </div>
                    </div>
                    <div className="minus-margins">
                        {this.renderGames()}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListMyGames)