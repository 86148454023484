import { Component } from 'react'
import {
    Link
} from "react-router-dom";


class GameWithdrawn extends Component {
    render() {
        return (
            <div className="alligned-middle dark-bg text-center px-5 py-5 radius small-padding-mobile new-prompts">
                <h2 className="yellow-font text-center my-2">You withdrawn your game succesfully!</h2>
                <p className="text-center blue-font mb-2">Your USDC has been sended back to you!</p>
                <div className="d-inline-flex text-center mx-auto max-content"><img className="arrows" src="/img/right.png" alt="right" /><Link to="/join" className="blue-font no-button big-height-back" >Go Back!</Link><img className="arrows" src="/img/left.png" alt="left" /></div>
            </div>
        )
    }
}

export default GameWithdrawn