import { Component } from 'react'
import {
    Link
} from "react-router-dom";

class UserNotFound extends Component {
    constructor() {
        super()
    }

    componentDidMount() {
        var el = document.getElementById("bg")
        if (el) {
            el.classList.add("indoor-bg")
        }

        var navbar = document.getElementById("navbarSupportedContent")
        if (navbar) {
            navbar.classList.remove("show")
        }
    }

    render() {
        return (
            <div className="alligned-middle dark-bg text-center px-5 py-5 radius small-padding-mobile new-prompts">
                <h2 className="yellow-font text-center my-2">Not found!</h2>
                <img src="/img/sad.png" alt="sad" className="small-image text-center mx-auto my-2" />
                <p className="text-center blue-font my-0">User has not been found,</p>
                <p className="text-center blue-font mb-2">sorry about that, let's get you back.</p>
                <div className="d-inline-flex text-center mx-auto max-content"><img className="arrows" src="/img/right.png" alt="right" /><Link to="/" className="blue-font no-button big-height-back" >BACK</Link><img className="arrows" src="/img/left.png" alt="left" /></div>
            </div>
        )
    }
}

export default UserNotFound