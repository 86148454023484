import axios from 'axios'

export const CHECK_ADMIN_REQUEST = 'check_admin_request'
export const CHECK_ADMIN_SUCCESS = 'check_admin_success'
export const CHECK_ADMIN_ERROR = 'check_admin_error'

export const ISSUE_TOKEN_REQUEST = 'issue_token_request'
export const ISSUE_TOKEN_SUCCESS = 'issue_token_success'
export const ISSUE_TOKEN_ERROR = 'issue_token_error'


export const LIST_TOKENS_REQUEST = 'list_tokens_request'
export const LIST_TOKENS_SUCCESS = 'list_tokens_success'
export const LIST_TOKENS_ERROR = 'list_tokens_error'

export function checkIfAdministrator(account, adminToken) {
    return function (dispatch) {
        dispatch(checkAdminRequest())
        axios.get(process.env.REACT_APP_ADMIN_PANEL, {
            params: {
                account: account,
                adminToken: adminToken
            }
        })
            .then((res) => {
                dispatch(checkAdminSuccess(res))
            }, (e) => {
                dispatch(checkAdminError(e))
            })
    }

    function checkAdminRequest() {
        return {
            type: CHECK_ADMIN_REQUEST
        }
    }

    function checkAdminSuccess(res) {
        return {
            type: CHECK_ADMIN_SUCCESS,
            payload: res
        }
    }

    function checkAdminError(e) {
        return {
            type: CHECK_ADMIN_ERROR,
            payload: e
        }
    }

}

export function issueToken(account, token, refreshToken, email) {
    const postBody = {
        account: account,
        token: token,
        refreshToken: refreshToken,
        email: email
    }
    return function (dispatch) {
        dispatch(checkAdminRequest())
        axios.post(process.env.REACT_APP_ISSUE_NEW_TOKEN, postBody)
            .then((res) => {
                dispatch(checkAdminSuccess(res))
            }, (e) => {
                dispatch(checkAdminError(e))
            })
    }

    function checkAdminRequest() {
        return {
            type: ISSUE_TOKEN_REQUEST
        }
    }

    function checkAdminSuccess(res) {
        return {
            type: ISSUE_TOKEN_SUCCESS,
            payload: res
        }
    }

    function checkAdminError(e) {
        return {
            type: ISSUE_TOKEN_ERROR,
            payload: e
        }
    }

}

export function getPasses(account, token, refreshToken) {
    return function (dispatch) {
        dispatch(listTokensRequest())
        axios.get(process.env.REACT_APP_LIST_TOKENS_ADDRESS, { params: { account: account, token: token, refreshToken: refreshToken } })
            .then((res) => {
                dispatch(listTokensSuccess(res))
            }, (e) => {
                dispatch(listTokensError(e))
            })
    }

    function listTokensRequest() {
        return {
            type: LIST_TOKENS_REQUEST
        }
    }

    function listTokensSuccess(res) {
        return {
            type: LIST_TOKENS_SUCCESS,
            payload: res
        }
    }

    function listTokensError(e) {
        return {
            type: LIST_TOKENS_ERROR,
            payload: e
        }
    }

}