import { Component } from 'react'
import { connect } from 'react-redux'

import {
    mapStateToProps,
    mapDispatchToProps
} from '../functions/mapStateToProps'

var that
var lastGameInfo = []
var lastTelegram, lastDiscord

class PlayerProfile extends Component {
    constructor() {
        super()
        this.state = { username: window.localStorage.getItem('name') }
        that = this
    }

    getData() {
        that.props.fetchRatings(window.localStorage.getItem("account"))
        that.props.fetchLogs(window.localStorage.getItem("account"))
        that.props.fetchTelegram(window.localStorage.getItem("account"))
        that.props.fetchDiscord(window.localStorage.getItem("account"))
    }

    objSort(obj) {
        var sortedArr = []
        var switcher = true
        var j = 0;
        while (switcher) {
            var smallest
            for (var i = j; i < obj.length; i++) {
                if (i == j) {
                    smallest = i
                } else {
                    if (obj[smallest].timestamp < obj[i].timestamp) {
                        smallest = i
                    }
                }
            }
            var temp
            temp = obj[j]
            obj[j] = obj[smallest]
            obj[smallest] = temp
            sortedArr.push(obj[j])
            if (sortedArr.length == obj.length) {
                switcher = false
            }
            j++;
        }
        return sortedArr
    }

    listInfo() {
        if (that.props.user.respond == "RATINGS") {
            var gameInfo = []
            for (var i = 0; i < that.props.user.ratings.length; i++) {
                gameInfo.push(<div className="all-info">
                    <div className="d-flex"><div className="game-name">{that.props.user.ratings[i].game}</div> : <div className="game-rating">{that.props.user.ratings[i].rating}</div>
                    </div>
                    <div className="d-flex">
                        <div className="wins me-4">
                            Wins: {that.props.user.ratings[i].w}
                        </div>
                        <div className="loses mx-4">
                            Loses: {that.props.user.ratings[i].l}
                        </div>
                        <div className="draws mx-4">
                            Draws: {that.props.user.ratings[i].t}
                        </div>
                        <div className="gp ms-4">
                            Games Played: {that.props.user.ratings[i].t + that.props.user.ratings[i].l + that.props.user.ratings[i].w}
                        </div>
                    </div>
                </div>)
            }
            lastGameInfo = gameInfo
            return gameInfo
        } else {
            return lastGameInfo
        }
    }

    setUsername() {
        var el = document.getElementById("tuname")
        var str = el.value.replace(/\s/g, '');
        if (str != "") {
            that.telegramTxt(el.value)
            that.props.createTelegram(el.value, window.localStorage.getItem("account"), window.localStorage.getItem("token"), window.localStorage.getItem("refreshToken"))
        }
    }

    setDiscordUsername() {
        var el = document.getElementById("duname")
        that.discordTxt(el.value)
        that.props.createDiscord(el.value, window.localStorage.getItem("account"), window.localStorage.getItem("token"), window.localStorage.getItem("refreshToken"))
    }

    discordTxt(name) {
        var dc = `Your discord username is ${name}. Please send a /connect command in command channel on PlayableWeb discord so we can send you notifications!`
        document.getElementById("dc").innerHTML = dc
    }

    telegramTxt(name) {
        var tg = `Your telegram username is ${name}. Please send a message to PlayableWebBot so we can send you notifications!`
        document.getElementById("tg").innerHTML = tg
    }

    telegram() {
        if (that.props.user.respond == "EMPTYTELEGRAM") {
            lastTelegram = <div id="tg" className="mb-4">To receive game notifications please connect your telegram by passing here your username and then sending "/start" message to: <a className="yellow-font" href="https://t.me/PlayableWebBot" target="_blank" rel="noopener noreferrer">PlayableWebBot</a> <div className="telegram-conection-form mt-4"><input type="text" id="tuname" required /><button className="btn own-btn blue-font ms-2" onClick={that.setUsername}>Change telegram username</button></div></div>
        }

        if (that.props.user.respond == "TELEGRAM") {
            lastTelegram = <div className="mb-2">Telegram connected!</div>
        }

        if (that.props.user.respond == "TELEGRAMNOTCONNECTED") {
            lastTelegram = <div id="tg" className="mb-2 max-width mx-auto">Your telegram username is {that.props.user.telegramUsername}. Please send "/start" message to: <a className="yellow-font" href="https://t.me/PlayableWebBot" target="_blank" rel="noopener noreferrer">PlayableWebBot</a> so we can send you notifications!</div>
        }
        return lastTelegram
    }

    discord() {
        if (that.props.user.respond == "EMPTYDISCORD") {
            lastDiscord = <div id="dc" className="mb-4">To receive game notifications please connect your discord by passing here your username with discriminator (format <strong className="yellow-font">username#number</strong>) and then send /connect command in commands channel on <a className="yellow-font" href="https://discord.gg/wuUpRrK8" target="_blank" rel="noopener noreferrer">PlayableWeb discord</a> <div className="discord-conection-form mt-4"><input type="text" id="duname" required /><button className="btn own-btn blue-font ms-2" onClick={that.setDiscordUsername}>Change discord username</button></div></div>
        }

        if (that.props.user.respond == "DISCORD") {
            lastDiscord = <div className="mb-2">Discord connected!</div>
        }

        if (that.props.user.respond == "DISCORDNOTCONNECTED") {
            lastDiscord = <div id="dc" className="mb-2 max-width mx-auto">Your discord username is {that.props.user.discordUsername}. Please send a /connect message in commands channel on <a className="yellow-font" href="https://discord.gg/wuUpRrK8" target="_blank" rel="noopener noreferrer">PlayableWeb discord</a> so we can send you notifications!</div>
        }
        return lastDiscord
    }

    mouseOver(e) {
        var classes = e.target.classList
        if (classes.contains("your-tokens")) {
            var el = document.getElementById('tooltip-pwt')
            if (el) {
                el.classList.remove('hidden')
            }
        }
    }

    mouseOut(e) {
        var el = document.getElementById('tooltip-pwt')
        if (el) {
            el.classList.add('hidden')
        }
    }

    componentDidMount() {
        var el = document.getElementById("bg")
        if (el) {
            el.classList.add("indoor-bg")
        }

        this.getData()

        var navbar = document.getElementById("navbarSupportedContent")
        if (navbar) {
            navbar.classList.remove("show")
        }
    }

    render() {
        return (
            <div className="profile position-relative mt-5">
                <div className="container faq-container neon-border">
                    <div className="d-flex profile-flex py-4 max-width mx-auto">
                        <h2 className="yellow-font profile-username">{this.state.username}</h2>
                        {/* <div className="pwt-coin" ><span id="pwt-coins" onMouseOver={this.mouseOver} onMouseOut={this.mouseOut} className="your-tokens yellow-font lh-53 clickable"></span><img src="/img/tablesCoin.png" className="tiny-image mt--10" /></div>
                        <div id="tooltip-pwt" className="position-absolute neon-border white-font dark-bg hidden smaller-padding-important" style={{top: 80 + 'px', marginLeft: -200 + 'px', zIndex: 1000}}>This is amount of PlayableWeb tokens you own. You can get them by playing games with others players.<br></br> In future you will be able to buy some cool assets on our webpage with them.</div> */}
                    </div>
                    <div className="profile-flex py-4 px-4 mx-auto text-center">
                        <div className="mb-2 blue-font">{this.telegram()}</div>
                    </div>
                    <div className="mt-2 blue-font px-5 pb-5">{this.listInfo()}</div>
                </div>
                {/* <div className="profile-flex py-4 px-4 mx-auto text-center">
                    <div className="mb-2 blue-font">{this.discord()}</div>
                </div> */}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerProfile)