import { Component } from 'react'

var that

class Main extends Component {
    constructor(props) {
        super(props)
        that = this
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    fade(element) {
        var op = 0;
        var timer = setInterval(function () {
            if (op >= 1) clearInterval(timer)
            element.style.opacity = op
            element.style.filter = 'alpha(opacity=' + op * 100 + ")"
            op += op * 0.1 || 0.1;
        }, 50)
    }

    fadeOut(element) {
        var op = 1;
        var timer = setInterval(function () {
            if (op <= 0) clearInterval(timer)
            element.style.opacity = op
            element.style.filter = 'alpha(opacity=' + op * 100 + ")"
            op -= op * 0.1 || 0.1
        }, 50)
    }

    async startVideo() {
        if (window.localStorage.getItem("account") && window.localStorage.getItem("refreshToken") && window.localStorage.getItem("token")) {
            var el = document.getElementById("entrance-video")
            var el2 = document.getElementById("quit")
            if (el && el2) {
                document.body.style.overflow = "hidden"
                that.fade(el)
                el.classList.remove("hidden")
                el2.classList.remove("hidden")
                await that.sleep(1000)
                el.play()
                el.onended = () => {
                    that.stopVideo()
                }
            }
        }
    }

    async stopVideo() {
        var el = document.getElementById("entrance-video")
        var el2 = document.getElementById("quit")
        var el3 = document.getElementById("bg")
        var el4 = document.getElementById("statement")
        if (el && el2 && el3 && el4) {
            el3.classList.add("indoor-bg")
            el4.classList.add("hidden")
            el2.classList.add("hidden")
            that.fadeOut(el)
            await that.sleep(1000)
            el.classList.add("hidden")
            document.body.style.overflow = ""
            that.props.history.push("/join")
        }
    }

    componentDidMount() {
        var el = document.getElementById("bg")
        if (el) {
            el.classList.remove("indoor-bg")
        }
    }

    render() {
        return (
            <div className="text-center dark-blue-font minus-margin-top">
                <p id="statement">Play chess for crypto stablecoins. Winner takes all!</p>
                <div id="start-video" className="position-absolute on-door clickable" onClick={this.startVideo}></div>
                <img src="/img/close.png" id="quit" onClick={this.stopVideo} className="position-fixed quit hidden clickable" />
                <video id="entrance-video" className="hidden video-fullscreen position-fixed">
                    <source src="/videos/EntranceVideo.mp4" type="video/mp4" />
                </video>
            </div>
        )
    }
}

export { Main }