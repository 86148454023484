import { Component } from 'react'
import { Link } from 'react-router-dom'

class Tutorial extends Component {
    constructor() {
        super()
    }

    componentDidMount() {
        var el = document.getElementById("bg")
        if (el) {
            el.classList.add("indoor-bg")
        }

        var navbar = document.getElementById("navbarSupportedContent")
        if (navbar) {
            navbar.classList.remove("show")
        }
    }

    render() {
        return (
            <div className="blue-font mx-auto py-4 px-4 container-fluid position-relative">
                <div className="row custom-ml-8 mt-5">
                    <div className="col-md-1">

                    </div>
                    <div className="col-md-3 text-center neon-border-mw">
                        <h2 className="yellow-font">Hex tutorial</h2>
                        <div className="d-inline-flex text-center mx-auto max-content">
                            <img className="arrows" src="/img/right.png" alt="right" />
                            <Link to="/tutorials/hex" className="blue-font no-button big-height-back" >PROCEED</Link>
                            <img className="arrows" src="/img/left.png" alt="left" />
                        </div>
                    </div>
                    <div className="col-md-3 text-center">

                    </div>
                    <div className="col-md-3 text-center neon-border-mw">
                        <h2 className="yellow-font">Chess tutorial</h2>
                        <div className="d-inline-flex text-center mx-auto max-content">
                            <img className="arrows" src="/img/right.png" alt="right" />
                            <Link to={{ pathname: "https://www.chessstrategyonline.com/tutorials" }} rel="noreferrer" target="_blank" className="blue-font no-button big-height-back" >PROCEED</Link>
                            <img className="arrows" src="/img/left.png" alt="left" />
                        </div>
                    </div>
                    <div className="col-md-1">

                    </div>
                </div>
            </div>
        )
    }
}

export default Tutorial