import { Component } from 'react'
import {
    ourBlockchain,
    ourBlockchainName,
    chainId
} from '../functions/contractsData'

var that

class FAQ extends Component {
    constructor() {
        super()
        that = this
    }

    componentDidMount() {
        var el = document.getElementById("bg")
        if (el) {
            el.classList.add("indoor-bg")
        }

        var navbar = document.getElementById("navbarSupportedContent")
        if (navbar) {
            navbar.classList.remove("show")
        }
    }

    render() {
        return (
            <div className="container faq-container pt-5">
                <div className="accordion yellow-font" id="faqs">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="faq2">
                            <button className="accordion-button collapsed blue-font" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="fakse" aria-controls="collapse2">
                                What is The Playable Web?
                            </button>
                        </h2>
                        <div id="collapse2" className="accordion-collapse collapse position-relative" aria-labelledby="faq2" data-bs-parent="#faqs">
                            <div className="accordion-body text-left">
                                The Playable Web is a decentralised autonomous organisation (DAO) which gives game developers a way to faciliate player-versus-player competitive play for crypto.
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="faq3">
                            <button className="accordion-button collapsed blue-font" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="fakse" aria-controls="collapse3">
                                Which cryptocurrencies can I play for?
                            </button>
                        </h2>
                        <div id="collapse3" className="accordion-collapse collapse position-relative" aria-labelledby="faq3" data-bs-parent="#faqs">
                            <div className="accordion-body text-left">
                                Currently, we provide game stakes in USDC only. This is a stable and liquid dollar proxy, providing the best liquidity for players holding other cryptocurrencies to enter the Playable Web.
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="faq4">
                            <button className="accordion-button collapsed blue-font" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="fakse" aria-controls="collapse4">
                                Do I have to transfer USDC to the Playable Web before I can play?
                            </button>
                        </h2>
                        <div id="collapse4" className="accordion-collapse collapse position-relative" aria-labelledby="faq4" data-bs-parent="#faqs">
                            <div className="accordion-body text-left">
                                The Playable Web gives you a USDC wallet on its private (Layer2) blockchain which holds your USDC for gameplay purposes. This arrangement means there are no gas fees or delays when transferring tokens around the Playable Web itself, speeding up the creation and settlement of match contracts.
                            </div>
                        </div>
                    </div>



                    {/* <div className="accordion-item">
                        <h2 className="accordion-header" id="faq5">
                            <button className="accordion-button collapsed blue-font" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="fakse" aria-controls="collapse5">
                               How do you match players?
                            </button>
                        </h2>
                        <div id="collapse5" className="accordion-collapse collapse position-relative" aria-labelledby="faq5" data-bs-parent="#faqs">
                            <div className="accordion-body text-left">
                               In the Playable Web, players are matched according to their track record with each game and their staking limit. Players are matched as close as possible in skill to ensure a fun and fair experience.
                            </div>
                        </div>
                    </div> */}
                    {/* 
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="faq6">
                            <button className="accordion-button collapsed blue-font" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="fakse" aria-controls="collapse6">
                                How do you prevent cheating?
                            </button>
                        </h2>
                        <div id="collapse6" className="accordion-collapse collapse position-relative" aria-labelledby="faq6" data-bs-parent="#faqs">
                            <div className="accordion-body text-left">
                                The Playable Web deploys a range of anti-cheat strategies. Some are structural - for example, it prevents collusion by blind matching. 
                                There are also other methods at work, such as analysis of gameplay to identify people using bots. If you think your opponent is cheating
                                it is the responsibility of the game developer, such as The Tables, to investigate and take action. 
                             </div>
                        </div>
                    </div> */}

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="faq7">
                            <button className="accordion-button collapsed blue-font" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="fakse" aria-controls="collapse7">
                                How do I get paid if I win? Can I withdraw?
                            </button>
                        </h2>
                        <div id="collapse7" className="accordion-collapse collapse position-relative" aria-labelledby="faq7" data-bs-parent="#faqs">
                            <div className="accordion-body text-left">
                                As soon as the match contract is resolved, your USDC balance on the Playable Web will be credited with the amount of contract, minus fees. You can withdraw your USDC to your wallet on the mainnet at any time.
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="faq8">
                            <button className="accordion-button collapsed blue-font" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="fakse" aria-controls="collapse8">
                                What are the fees?
                            </button>
                        </h2>
                        <div id="collapse8" className="accordion-collapse collapse position-relative" aria-labelledby="faq8" data-bs-parent="#faqs">
                            <div className="accordion-body text-left">
                                The Playable Web charges a fee of 4% of the total stake. Game Developers set their own fees which are displayed for you before you agree to join or host a match. In the case of The Tables, the fee is 7%. All the fees are deducted from the prize when it is paid to the winner at the end of the match.
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="faq9">
                            <button className="accordion-button collapsed blue-font" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="fakse" aria-controls="collapse9">
                                What happens if I don't make my move in time?
                            </button>
                        </h2>
                        <div id="collapse9" className="accordion-collapse collapse position-relative" aria-labelledby="faq9" data-bs-parent="#faqs">
                            <div className="accordion-body text-left">
                                On the Tables we give you 36 hours to make your move. If you don't make your move, you automatically forfeit the game and your oppoonent is the winner.
                            </div>
                        </div>
                    </div>



                    <div className="accordion-item">
                        <h2 className="accordion-header" id="faq10">
                            <button className="accordion-button collapsed blue-font" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="fakse" aria-controls="collapse10">
                                How do I add the Playable Web blockchain to my Metamask account?
                            </button>
                        </h2>
                        <div id="collapse10" className="accordion-collapse collapse position-relative" aria-labelledby="faq10" data-bs-parent="#faqs">
                            <div className="accordion-body text-left">
                                Use the following details:
                                <ul>
                                    <li>Network Name: {ourBlockchainName}</li>
                                    <li>New RPC URL: {ourBlockchain[0]} </li>
                                    <li>ChainID: {chainId}</li>
                                </ul>

                            </div>
                        </div>
                    </div>



                </div>
            </div>
        )
    }
}
export default FAQ