import React, { Component } from 'react'
import socketIOClient from 'socket.io-client'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { Main } from './Main'
import ConnectSolana from './ConnectSolana'
import Host from './Host'
import Join from './Join'
import Game from './Game'
import GameObserver from './GameObserver'
import Menu from './Menu'
import Footer from './Footer'
import NoFound from './NoFound'
import UserNotFound from './UserNotFound'
import Unauthorized from './Unauthorized'
import ListMyGames from './ListMyGames'
import FinishedGames from './FinishedGames'
import FAQ from './FAQ'
import PlayerProfile from './PlayerProfile'
import WelcomeTour from './WelcomeTour'
import Tutorial from './Tutorial'
import HexTutorial from './HexTutorial'
import TransactionHistory from './TransactionHistory'
import Terms from './Terms'
import Privacy from './Privacy'

var socket

class App extends Component {
  constructor(props) {
    super(props)
    this.state = { socket: socketIOClient(process.env.REACT_APP_BACKEND_ADDRESS, { 'transports': ['websocket'] }) }
    socket = this.state.socket
  }

  render() {
    return (
      <BrowserRouter>
        <div id="bg" className="background">
          <Route component={Menu} />
          <Route component={ConnectSolana} />
          <Switch>
            <Route exact path="/" component={Main} />
            <Route exact path="/host" component={Host} />
            <Route exact path="/join" component={Join} />
            <Route exact path="/myGames" component={ListMyGames} />
            <Route exact path="/finishedGames" component={FinishedGames} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/transactions" component={TransactionHistory} />
            <Route exact path="/FAQ" component={FAQ} />
            <Route exact path="/playerProfile" component={PlayerProfile} />
            <Route exact path="/userNotFound" component={UserNotFound} />
            <Route exact path="/unauthorized" component={Unauthorized} />
            <Route exact path="/welcomeTour" component={WelcomeTour} />
            <Route exact path="/tutorials" component={Tutorial} />
            <Route exact path="/tutorials/hex" component={HexTutorial} />
            <Route exact path="/tutorials/chess" component={() => {
              window.location.href = 'https://www.chessstrategyonline.com/tutorials'
              return null
            }} />
            <Route path="/watch/:username/:game/:id" component={GameObserver} />
            <Route path="/:game/:id" component={Game} />
            <Route component={NoFound} />
          </Switch>
          <Route component={Footer} />
        </div>
      </BrowserRouter>
    )
  }
}

export { App, socket }
