import {
    FETCH_USERNAME_SUCCESS,
    FETCH_USERNAME_REQUEST,
    FETCH_USERNAME_ERROR,
    CREATE_ACCOUNT_SUCCESS,
    CREATE_ACCOUNT_REQUEST,
    CREATE_ACCOUNT_ERROR,
    NEW_REFRESHTOKEN_ERROR,
    NEW_REFRESHTOKEN_REQUEST,
    NEW_REFRESHTOKEN_SUCCESS,
    NEW_TOKEN_ERROR,
    NEW_TOKEN_REQUEST,
    NEW_TOKEN_SUCCESS,
    CREATE_RATINGS_ERROR,
    CREATE_RATINGS_REQUEST,
    CREATE_RATINGS_SUCCESS,
    FETCH_TELEGRAM_ERROR,
    FETCH_TELEGRAM_REQUEST,
    FETCH_TELEGRAM_SUCCESS,
    FETCH_TUSERNAME_ERROR,
    FETCH_TUSERNAME_REQUEST,
    FETCH_TUSERNAME_SUCCESS,
    FETCH_DISCORD_ERROR,
    FETCH_DISCORD_REQUEST,
    FETCH_DISCORD_SUCCESS,
    FETCH_DUSERNAME_ERROR,
    FETCH_DUSERNAME_REQUEST,
    FETCH_DUSERNAME_SUCCESS,
    CHANGE_PASS_ERROR,
    CHANGE_PASS_REQUEST,
    CHANGE_PASS_SUCCESS
} from '../actions/user-actions'

export default function user(state = {}, action) {
    switch (action.type) {
        case FETCH_USERNAME_REQUEST:
            return { status: 'Fetching username' }
        case FETCH_USERNAME_SUCCESS:
            return (action.payload.data)
        case FETCH_USERNAME_ERROR:
            return { status: 'Cannot fetch username' }
        case FETCH_TUSERNAME_REQUEST:
            return { status: 'Fetching username telegram' }
        case FETCH_TUSERNAME_SUCCESS:
            return (action.payload.data)
        case FETCH_TUSERNAME_ERROR:
            return { status: 'Cannot fetch username telegram' }
        case FETCH_DUSERNAME_REQUEST:
            return { status: 'Fetching username discord' }
        case FETCH_DUSERNAME_SUCCESS:
            return (action.payload.data)
        case FETCH_DUSERNAME_ERROR:
            return { status: 'Cannot fetch username discord' }
        case FETCH_TELEGRAM_REQUEST:
            return { status: 'Fetching telegram' }
        case FETCH_TELEGRAM_SUCCESS:
            return (action.payload.data)
        case FETCH_TELEGRAM_ERROR:
            return { status: 'Cannot fetch telegram' }
        case FETCH_DISCORD_REQUEST:
            return { status: 'Fetching discord' }
        case FETCH_DISCORD_SUCCESS:
            return (action.payload.data)
        case FETCH_DISCORD_ERROR:
            return { status: 'Cannot fetch discord' }
        case CREATE_ACCOUNT_REQUEST:
            return { status: 'Creating user' }
        case CREATE_ACCOUNT_SUCCESS:
            return (action.payload.data)
        case CREATE_ACCOUNT_ERROR:
            return { status: 'Account not created' }
        case NEW_REFRESHTOKEN_REQUEST:
            return { status: 'Asking for refresh token' }
        case NEW_REFRESHTOKEN_SUCCESS:
            return (action.payload.data)
        case NEW_REFRESHTOKEN_ERROR:
            return { status: 'Refresh token not changed' }
        case NEW_TOKEN_REQUEST:
            return { status: 'Asking for token' }
        case NEW_TOKEN_SUCCESS:
            return (action.payload.data)
        case NEW_TOKEN_ERROR:
            return { status: 'Token not created' }
        case CREATE_RATINGS_REQUEST:
            return { status: 'Asking for ratings' }
        case CREATE_RATINGS_SUCCESS:
            return (action.payload.data)
        case CREATE_RATINGS_ERROR:
            return { status: 'Ratings not fetched' }
        case CHANGE_PASS_REQUEST:
            return { status: 'Asking for changing passwords' }
        case CHANGE_PASS_SUCCESS:
            return (action.payload.data)
        case CHANGE_PASS_ERROR:
            return { status: 'NP' }
        default:
            return (state)
    }
}