import axios from 'axios'

export const CREATE_GAME_REQUEST = 'create_game_request'
export const CREATE_GAME_SUCCESS = 'create_game_success'
export const CREATE_GAME_ERROR = 'create_game_error'
export const JOIN_GAME_REQUEST = 'join_game_request'
export const JOIN_GAME_SUCCESS = 'join_game_success'
export const JOIN_GAME_ERROR = 'join_game_error'
export const LIST_ALL_GAMES_REQUEST = 'list_all_games_request'
export const LIST_ALL_GAMES_SUCCESS = 'list_all_games_success'
export const LIST_ALL_GAMES_ERROR = 'list_all_games_error'
export const LIST_ALL_ASYNC_GAMES_REQUEST = 'list_all_async_games_request'
export const LIST_ALL_ASYNC_GAMES_SUCCESS = 'list_all_async_games_success'
export const LIST_ALL_ASYNC_GAMES_ERROR = 'list_all_async_games_error'
export const GAME_EXISTS_REUQUEST = 'game_exists_request'
export const GAME_EXISTS_SUCCESS = 'game_exists_success'
export const GAME_EXISTS_ERROR = 'game_exists_error'
export const GAME_ENDED_REUQUEST = 'game_ended_request'
export const GAME_ENDED_SUCCESS = 'game_ended_success'
export const GAME_ENDED_ERROR = 'game_ended_error'
export const LIST_MY_ALL_GAMES_REQUEST = 'list_my_all_games_request'
export const LIST_MY_ALL_GAMES_SUCCESS = 'list_my_all_games_success'
export const LIST_MY_ALL_GAMES_ERROR = 'list_my_all_games_error'
export const LIST_MY_ALL_ASYNC_GAMES_REQUEST = 'list_my_all_async_games_request'
export const LIST_MY_ALL_ASYNC_GAMES_SUCCESS = 'list_my_all_async_games_success'
export const LIST_MY_ALL_ASYNC_GAMES_ERROR = 'list_my_all_async_games_error'
export const LIST_FINISHED_REQUEST = 'list_finished_request'
export const LIST_FINISHED_SUCCESS = 'list_finished_success'
export const LIST_FINISHED_ERROR = 'list_finished_error'

export function createGame(gameChoosen, account, signature) {
    const postBody = {
        account,
        gameChoosen,
        signature
    }

    return function (dispatch) {
        dispatch(createGameRequest())
        axios.post(process.env.REACT_APP_HOST, postBody)
            .then((res) => {
                dispatch(createGameSuccess(res))
            }, (e) => {
                dispatch(createGameError(e))
            })
    }

    function createGameRequest() {
        return {
            type: CREATE_GAME_REQUEST
        }
    }

    function createGameSuccess(res) {
        return {
            type: CREATE_GAME_SUCCESS,
            payload: res
        }
    }

    function createGameError(e) {
        return {
            type: CREATE_GAME_ERROR,
            payload: e
        }
    }
}

export function joinGame(gameName, roomNumber, account, signature) {
    const postBody = {
        account: account,
        gameName: gameName,
        roomNumber: roomNumber,
        signature: signature
    }
    return function (dispatch) {
        dispatch(joinGameRequest())
        axios.post(process.env.REACT_APP_JOIN, postBody)
            .then((res) => {
                dispatch(joinGameSuccess(res))
            }, (e) => {
                dispatch(joinGameError(e))
            })
    }

    function joinGameRequest() {
        return {
            type: JOIN_GAME_REQUEST
        }
    }

    function joinGameSuccess(res) {
        return {
            type: JOIN_GAME_SUCCESS,
            payload: res
        }
    }

    function joinGameError(e) {
        return {
            type: JOIN_GAME_ERROR,
            payload: e
        }
    }
}

export function listGames() {
    return function (dispatch) {
        dispatch(listGamesRequest())
        axios.get(process.env.REACT_APP_LIST_GAMES)
            .then((res) => {
                dispatch(listGamesSuccess(res))
            }, (e) => {
                dispatch(listGamesError(e))
            })
    }

    function listGamesRequest() {
        return {
            type: CREATE_GAME_REQUEST
        }
    }

    function listGamesSuccess(res) {
        return {
            type: CREATE_GAME_SUCCESS,
            payload: res
        }
    }

    function listGamesError(e) {
        return {
            type: CREATE_GAME_ERROR,
            payload: e
        }
    }
}

export function listAsyncGames() {
    return function (dispatch) {
        dispatch(listAsyncGamesRequest())
        axios.get(process.env.REACT_APP_LIST_ASYNC_GAMES)
            .then((res) => {
                dispatch(listAsyncGamesSuccess(res))
            }, (e) => {
                dispatch(listAsyncGamesError(e))
            })
    }

    function listAsyncGamesRequest() {
        return {
            type: CREATE_GAME_REQUEST
        }
    }

    function listAsyncGamesSuccess(res) {
        return {
            type: CREATE_GAME_SUCCESS,
            payload: res
        }
    }

    function listAsyncGamesError(e) {
        return {
            type: CREATE_GAME_ERROR,
            payload: e
        }
    }
}

export function checkIfGameExist(game, id, account) {
    var backendAddress = process.env.REACT_APP_GAME_EXIST + `?game=${game}&id=${id}&account=${account}`
    return function (dispatch) {
        dispatch(gameExistsRequest())
        axios.get(backendAddress)
            .then((res) => {
                dispatch(gameExistsSuccess(res))
            }, (e) => {
                dispatch(gameExistsError(e))
            })
    }

    function gameExistsRequest() {
        return {
            type: GAME_EXISTS_REUQUEST
        }
    }

    function gameExistsSuccess(res) {
        return {
            type: GAME_EXISTS_SUCCESS,
            payload: res
        }
    }

    function gameExistsError(e) {
        return {
            type: GAME_EXISTS_ERROR,
            payload: e
        }
    }
}

export function checkIfGameEnded(username, game, id) {
    var backendAddress = process.env.REACT_APP_GAME_ENDED + `?uname=${username}&game=${game}&id=${id}`
    return function (dispatch) {
        dispatch(gameExistsRequest())
        axios.get(backendAddress)
            .then((res) => {
                dispatch(gameExistsSuccess(res))
            }, (e) => {
                dispatch(gameExistsError(e))
            })
    }

    function gameExistsRequest() {
        return {
            type: GAME_ENDED_REUQUEST
        }
    }

    function gameExistsSuccess(res) {
        return {
            type: GAME_ENDED_SUCCESS,
            payload: res
        }
    }

    function gameExistsError(e) {
        return {
            type: GAME_ENDED_ERROR,
            payload: e
        }
    }
}

export function listMyGames(address) {
    return function (dispatch) {
        dispatch(listGamesRequest())
        axios.get(process.env.REACT_APP_LIST_MY_GAMES, { params: { address: address } })
            .then((res) => {
                dispatch(listGamesSuccess(res))
            }, (e) => {
                dispatch(listGamesError(e))
            })
    }

    function listGamesRequest() {
        return {
            type: CREATE_GAME_REQUEST
        }
    }

    function listGamesSuccess(res) {
        return {
            type: CREATE_GAME_SUCCESS,
            payload: res
        }
    }

    function listGamesError(e) {
        return {
            type: CREATE_GAME_ERROR,
            payload: e
        }
    }
}

export function listFinishedAddress(address) {
    return function (dispatch) {
        dispatch(listFinishedRequest())
        axios.get(process.env.REACT_APP_LIST_FINISHED_GAMES, { params: { address: address } })
            .then((res) => {
                dispatch(listFinishedSuccess(res))
            }, (e) => {
                dispatch(listFinishedError(e))
            })
    }

    function listFinishedRequest() {
        return {
            type: LIST_FINISHED_REQUEST
        }
    }

    function listFinishedSuccess(res) {
        return {
            type: LIST_FINISHED_SUCCESS,
            payload: res
        }
    }

    function listFinishedError(e) {
        return {
            type: LIST_FINISHED_ERROR,
            payload: e
        }
    }
}


export function listMyAsyncGames(address) {
    return function (dispatch) {
        dispatch(listGamesRequest())
        axios.get(process.env.REACT_APP_LIST_ASYNC_GAMES, { params: { address: address } })
            .then((res) => {
                dispatch(listGamesSuccess(res))
            }, (e) => {
                dispatch(listGamesError(e))
            })
    }

    function listGamesRequest() {
        return {
            type: CREATE_GAME_REQUEST
        }
    }

    function listGamesSuccess(res) {
        return {
            type: CREATE_GAME_SUCCESS,
            payload: res
        }
    }

    function listGamesError(e) {
        return {
            type: CREATE_GAME_ERROR,
            payload: e
        }
    }
}