import { Component } from 'react'
import {
    Link
} from "react-router-dom";


class Won extends Component {
    render() {
        return (
            <div className="alligned-middle dark-bg text-center px-5 py-5 radius small-padding-mobile new-prompts">
                <h2 className="yellow-font text-center my-2">You win!</h2>
                <img src="/img/won.png" alt="Won" className="small-image text-center mx-auto my-2" />
                <p className="text-center blue-font my-0">You won!</p>
                <p className="text-center blue-font mb-2">The USDC in the pot has been transferred to your wallet (after Game Dev and DAO fees) and your ranking updated.</p>
                <div className="d-inline-flex text-center mx-auto max-content"><img className="arrows" src="/img/right.png" alt="right" /><Link to="/join" className="blue-font no-button big-height-back" >Keep going</Link><img className="arrows" src="/img/left.png" alt="left" /></div>
            </div>
        )
    }
}

export default Won