import { Component } from 'react'
import { connect } from 'react-redux'

import {
    mapStateToProps,
    mapDispatchToProps
} from '../functions/mapStateToProps'

var that, lastTxs

class TransactionHistory extends Component {
    constructor() {
        super()
        that = this
    }

    componentDidMount() {
        this.props.fetchLogs(window.localStorage.getItem('account'))

        var el = document.getElementById("bg")
        if (el) {
            el.classList.add("indoor-bg")
        }

        var navbar = document.getElementById("navbarSupportedContent")
        if (navbar) {
            navbar.classList.remove("show")
        }
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    renderTransactionlist() {
        if (this.props.logs.logsAll != undefined) {
            var jsx = []
            this.props.logs.logsAll.slice().reverse().forEach(element => {
                var el = element.timestamp.split("T")
                if (el[0]) {
                    var all = el[0].split("-")
                    if (all.length >= 3) {
                        var year = all[0]
                        var month = all[1]
                        var day = all[2]
                        var formattedTime = day + '.' + month + '.' + year;
                        if (element.text.action == "withdraw") {
                            jsx.push(<div className="my-4 d-md-flex blue-font neon-border-bottom-mobile">
                                <p className="col-md-3 text-center">
                                    {element.text.action == "mint" ? "Deposit" : "Withdrawal"}

                                </p>
                                <p className="col-md-3 text-center">
                                    {element.text.amount} USDC
                                </p>
                                <p className="col-md-3 text-center">
                                    {this.capitalizeFirstLetter(element.type)}
                                </p>
                                <p className="col-md-3 text-center">
                                    {formattedTime}
                                </p>
                            </div>)

                        } else {
                            jsx.push(<div className="my-4 d-md-flex blue-font neon-border-bottom-mobile">
                                <p className="col-md-3 text-center">
                                    {element.text.action == "mint" ? "Deposit" : "Withdrawal"}

                                </p>
                                <p className="col-md-3 text-center">
                                    {element.text.amount / 1000000} USDC
                                </p>
                                <p className="col-md-3 text-center">
                                    {this.capitalizeFirstLetter(element.type)}
                                </p>
                                <p className="col-md-3 text-center">
                                    {formattedTime}
                                </p>
                            </div>)

                        }
                    }
                }

            })
            lastTxs = jsx
            return <div>{jsx}</div>
        }
        return <div>{lastTxs}</div>
    }

    render() {
        return (
            <div className="admin position-relative">
                <h2 className="yellow-font p-4 text-center">Transfers to and from your PlayableWeb account.</h2>
                <div className="neon-border no-padding-lr container faq-container max-height-500 join-game-overflow">
                    <div className="row neon-border-bottom">
                        <div className="col-md-3 game-name blue-font text-center">
                            Type
                        </div>
                        <div className="col-md-3 host blue-font text-center">
                            Amount
                        </div>
                        <div className="col-md-3 bet blue-font text-center">
                            Status
                        </div>
                        <div className="col-md-3 time-left blue-font text-center">
                            Date
                        </div>

                    </div>
                    <div className="minus-margins">
                        {this.renderTransactionlist()}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionHistory)