import { fetchUsername } from './user-actions'

export const CONNECT_ACCOUNT_REQUEST = 'connect_account_request'
export const CONNECT_ACCOUNT_SUCCESS = 'connect_account_success'
export const CONNECT_ACCOUNT_ERROR = 'connect_account_error'

export function connectAccounts(wallet) {
    return async function (dispatch) {
        dispatch(connectAccountRequest())
        const accounts = await window.ethereum.request({ method: 'eth_accounts' })
        if (accounts[0] == undefined) {
            dispatch(connectAccountError())
        } else {
            dispatch(connectAccountSuccess(accounts[0]))
            dispatch(fetchUsername(wallet, window.localStorage.getItem("token"), window.localStorage.getItem("refreshToken")))
        }
    }

    function connectAccountRequest() {
        return {
            type: CONNECT_ACCOUNT_REQUEST
        }
    }

    function connectAccountSuccess(account) {
        return {
            type: CONNECT_ACCOUNT_SUCCESS,
            payload: { account: account }
        }
    }

    function connectAccountError() {
        return {
            type: CONNECT_ACCOUNT_ERROR,
            payload: 'Error'
        }
    }
}

export function connectWallet(ethereum) {
    return async function (dispatch) {
        dispatch(connectWalletRequest())
        try {
            await ethereum.request({ method: 'eth_requestAccounts' })
            const accounts = await ethereum.request({ method: 'eth_accounts' })
            if (accounts[0] == undefined) {
                dispatch(connectWalletError())
            } else {
                dispatch(connectWalletSuccess(accounts[0]))
                dispatch(fetchUsername(accounts[0], window.localStorage.getItem("token"), window.localStorage.getItem("refreshToken")))
            }
        } catch (e) {
            dispatch(connectWalletError())
        }
    }

    function connectWalletRequest() {
        return {
            type: CONNECT_ACCOUNT_REQUEST
        }
    }

    function connectWalletSuccess(account) {
        return {
            type: CONNECT_ACCOUNT_SUCCESS,
            payload: { account: account }
        }
    }

    function connectWalletError() {
        return {
            type: CONNECT_ACCOUNT_ERROR,
            payload: 'Error'
        }
    }
}