import { Component } from 'react'
import { socket } from './App'
import Won from './Won'
import Lost from './Lost'
import Players from './Players'
import BottomButtons from './BottomButtonsComponent'
import WithdrawConfirmation from './WithdrawConfirmation'
import ForfeitConfirmation from './ForfeitConfirmation'
import GameWithdrawn from './GameWithdrawn'
import Waiting from './Waiting'

var that, gameFinished, boardGenerated, times, intervalId, pth, ended, flashingInterval, hidden, showing, shown, nameFlashInterval, showPathId, showPathBlue
var pathRed = ['0-4-polygon', '1-4-polygon', '2-4-polygon', '3-4-polygon', '4-4-polygon', '5-4-polygon', '6-4-polygon', '7-4-polygon', '8-4-polygon', '9-4-polygon', '10-4-polygon']
var pathBlue = ['5-0-polygon', '5-1-polygon', '5-2-polygon', '5-3-polygon', '5-4-polygon', '5-5-polygon', '5-6-polygon', '5-7-polygon', '5-8-polygon', '5-9-polygon', '5-10-polygon']
class Hex extends Component {
    constructor() {
        super()
        that = this
        this.state = { status: <div></div>, jsx: <div></div>, player1: [], player2: [], rotationValue: 0 }
        gameFinished = false
        boardGenerated = false
        ended = false
        hidden = false
        showing = false
        shown = true
    }

    showPath(path, player, names) {
        if (!window.localStorage.getItem("shown")) {
            var moves = 0
            var name = names
            if (player == 1) {
                showPathId = setInterval(() => {
                    if (moves < path.length) {
                        if (player == 1) {

                            var el = document.getElementById(path[moves])
                            if (el) {
                                el.classList.add("rpo")
                            }
                        } else {
                            var el = document.getElementById(path[moves])
                            if (el) {
                                el.classList.add("bpo")
                            }
                        }
                        moves++
                    } else {
                        if (player == 1) {
                            var elems = document.querySelectorAll(".rpo");

                            [].forEach.call(elems, function (el) {
                                el.classList.remove("rpo")
                            })
                        } else {
                            elems = document.querySelectorAll(".bpo");

                            [].forEach.call(elems, function (el) {
                                el.classList.remove("bpo")
                            })
                        }
                        if (player == 1) {
                            clearInterval(showPathId)
                        } else {
                            window.localStorage.setItem("shown", true)
                            window.localStorage.removeItem("showing")
                            clearInterval(showPathId)
                        }
                    }
                }, 150)
                var id = setTimeout(() => {
                    that.showPath(pathBlue, 2, names)
                    clearTimeout(id)
                }, 150 * 11)
            } else {
                showPathBlue = setInterval(() => {
                    if (moves < path.length) {
                        if (player == 1) {

                            var el = document.getElementById(path[moves])
                            if (el) {
                                el.classList.add("rpo")
                            }
                        } else {
                            var el = document.getElementById(path[moves])
                            if (el) {
                                el.classList.add("bpo")
                            }
                        }
                        moves++
                    } else {
                        if (player == 1) {
                            var elems = document.querySelectorAll(".rpo");

                            [].forEach.call(elems, function (el) {
                                el.classList.remove("rpo")
                            })
                        } else {
                            elems = document.querySelectorAll(".bpo");

                            [].forEach.call(elems, function (el) {
                                el.classList.remove("bpo")
                            })
                        }
                        if (player == 1) {
                            clearInterval(showPathBlue)
                        } else {
                            window.localStorage.setItem("shown", true)
                            window.localStorage.removeItem("showing")
                            if (window.localStorage.getItem("name") == name.names[0]) {
                                that.playerFlash(1)
                            } else {

                                that.playerFlash(2)
                            }
                            clearInterval(showPathBlue)
                        }
                    }
                }, 150)
            }


        }
    }

    playerFlash(number) {
        var el = document.getElementById('hide-animations-end')
        if (el) {
            el.classList.add("hidden")
        }
        var i = 0;
        if (number == 1) {
            var el2 = document.getElementById('red-player-one')
            if (el2) {
                nameFlashInterval = setInterval(() => {
                    if (shown) {
                        el2.classList.add('hidden-vis')
                        shown = false
                    } else {
                        el2.classList.remove('hidden-vis')
                        shown = true
                        i++
                    }
                    if (i >= 3) {
                        clearInterval(nameFlashInterval)
                    }
                }, 300)
            }
        } else {
            var el2 = document.getElementById('blue-player-two')
            if (el2) {
                nameFlashInterval = setInterval(() => {
                    if (shown) {
                        el2.classList.add('hidden-vis')
                        shown = false
                    } else {
                        el2.classList.remove('hidden-vis')
                        shown = true
                        i++
                    }
                    if (i >= 3) {
                        clearInterval(nameFlashInterval)
                    }
                }, 300)
            }
        }
    }

    handleSocket() {
        var el2 = document.getElementById("white-piece")
        if (el2) {
            el2.classList.add("hidden")
        }
        var el3 = document.getElementById("black-piece")
        if (el3) {
            el3.classList.add("hidden")
        }
        if (socket) {
            socket.on('DataSended', (data) => {
                that.arrows(data.onMove)
                if (data.pot) {
                    var elementPot = document.getElementById("pot-size")
                    if (elementPot) {
                        elementPot.textContent = data.pot
                    }
                }
                if (data.time) {
                    times = data.time
                    that.displayTime(times)
                    if (intervalId) {
                        clearInterval(intervalId)
                    }
                    intervalId = setInterval(() => {
                        times--
                        that.displayTime(times)
                    }, 1000)
                }
                if (data.names.length == 2) {
                    var params = window.location.pathname
                    params = params.split("/")
                    socket.emit('checkUsersInRoom', ({ gameName: params[1], roomNumber: params[2], account: window.localStorage.getItem('account') }))
                }
                if (data.names.length == 2 && data.currentBoard.player1Moves.length == 0 && data.currentBoard.player2Moves.length == 0) {
                    if (!window.localStorage.getItem("shown") && !window.localStorage.getItem("showing")) {
                        window.localStorage.setItem("showing", true)
                        var el = document.getElementById('hide-animations-end')
                        that.showPath(pathRed, 1, data, data.names)
                        if (el) {
                            el.classList.remove("hidden")
                        }
                    }
                } else {
                    var el = document.getElementById('hide-animations-end')
                    if (el) {
                        el.classList.add("hidden")
                    }
                }

                if (boardGenerated == true) {
                    return
                }
                that.generateBoard(data.boardSize, data.currentBoard, data.onMove)
            })
        }
    }

    arrows(onMove) {
        var arr1 = document.getElementsByClassName('player1-arrow')
        var arr2 = document.getElementsByClassName('player2-arrow')
        if (onMove) {
            that.hideWithdraw()
            that.showForfeit()
            if (onMove == 'active') {
                arr1[0].style.display = "block"
                arr2[0].style.display = "none"
            } else {
                arr1[0].style.display = "none"
                arr2[0].style.display = "block"
            }
        } else {
            that.hideForfeit()
            that.showWithdraw()
        }
    }

    generateBoard(size, currentBoard, onMove) {
        let arr = []
        let horizontalMax = size + size - 0.5
        let svgWidth = 100 / horizontalMax
        arr.push(<h2 className="widther d-table-cell"></h2>)
        for (var i = 0; i < size; i++) {
            arr.push(<h2 className="yellow-font text-left d-table-cell number-top">{i + 1}</h2>)
        }
        for (var it = 0; it < size; it++) {
            for (var iteration = 0; iteration < size; iteration++) {
                if (it == 0) {
                    if (iteration == 0) {
                        var chr = String.fromCharCode(65 + it)
                        arr.push(<h2 className={"yellow-font text-center number-top max-content letter-left me-2 m-bot-5"}>{chr}</h2>)
                        arr.push(<svg onClick={that.move} className="architecture m-bot-5" version="1.1" id={'svg-' + it + '-' + iteration} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40" xmlSpace="preserve">
                            <polygon className="clickable hex-field" id={it + '-' + iteration + '-polygon'} onClick={that.move} points="20,0 40,10 40,30 20,40 0,30 0,10" />
                            <path id="lineAB" d="M 0 10 l 20 -10" stroke="#75ff00" strokeWidth="2" fill="none" />
                            <path id="lineBC" d="M 20 0 l 20 10" stroke="#75ff00" strokeWidth="2" fill="none" />
                            <path id="lineAB" d="M 0 10 l 0 20" stroke="#00d7ff" strokeWidth="2" fill="none" />
                            <path id="lineBC" d="M 0 30 l 20 10" stroke="#00d7ff" strokeWidth="2" fill="none" />
                        </svg>)
                    } else {
                        if (iteration == size - 1) {
                            arr.push(<svg className="architecture m-bot-5" version="1.1" id={'svg-' + it + '-' + iteration} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40" xmlSpace="preserve">
                                <polygon className="clickable hex-field" id={it + '-' + iteration + '-polygon'} onClick={that.move} points="20,0 40,10 40,30 20,40 0,30 0,10" />
                                <path id="lineAB" d="M 0 10 l 20 -10" stroke="#75ff00" strokeWidth="2" fill="none" />
                                <path id="lineBC" d="M 20 0 l 10 5" stroke="#75ff00" strokeWidth="2" fill="none" />
                                <path id="lineAB" d="M 30 5 l 10 5" stroke="#00d7ff" strokeWidth="2" fill="none" />
                                <path id="lineBC" d="M 40 30 l 0 -20" stroke="#00d7ff" strokeWidth="2" fill="none" />
                            </svg>)
                        } else {
                            arr.push(<svg className="architecture m-bot-5" version="1.1" id={'svg-' + it + '-' + iteration} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40" xmlSpace="preserve">
                                <polygon className="clickable hex-field" id={it + '-' + iteration + '-polygon'} onClick={that.move} points="20,0 40,10 40,30 20,40 0,30 0,10" />
                                <path id="lineAB" d="M 0 10 l 20 -10" stroke="#75ff00" strokeWidth="2" fill="none" />
                                <path id="lineBC" d="M 20 0 l 20 10" stroke="#75ff00" strokeWidth="2" fill="none" />
                            </svg>)

                        }
                    }
                } else {
                    if (iteration == 0) {
                        var chr = String.fromCharCode(65 + it)
                        if (it == size - 1) {
                            arr.push(<h2 className={"yellow-font text-center number-top max-content letter-left me-2" + " pl" + it + "it" + iteration}>{chr}</h2>)
                            arr.push(<svg className={"architecture " + "pl" + it} version="1.1" id={'svg-' + it + '-' + iteration} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40" xmlSpace="preserve">
                                <polygon className="clickable hex-field" id={it + '-' + iteration + '-polygon'} onClick={that.move} points="20,0 40,10 40,30 20,40 0,30 0,10" />
                                <path id="lineAB" d="M 0 10 l 0 20" stroke="#00d7ff" strokeWidth="2" fill="none" />
                                <path id="lineBC" d="M 0 30 l 10 5" stroke="#00d7ff" strokeWidth="2" fill="none" />
                                <path id="lineAB" d="M 10 35 l 10 5" stroke="#75ff00" strokeWidth="2" fill="none" />
                                <path id="lineBC" d="M 20 40 l 20 -10" stroke="#75ff00" strokeWidth="2" fill="none" />
                            </svg>)
                        } else {
                            arr.push(<h2 className={"yellow-font text-center number-top max-content letter-left me-2" + " pl" + it + "it" + iteration}>{chr}</h2>)
                            arr.push(<svg className={"architecture " + "pl" + it} version="1.1" id={'svg-' + it + '-' + iteration} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40" xmlSpace="preserve">
                                <polygon className="clickable hex-field" id={it + '-' + iteration + '-polygon'} onClick={that.move} points="20,0 40,10 40,30 20,40 0,30 0,10" />
                                <path id="lineAB" d="M 0 10 l 0 20" stroke="#00d7ff" strokeWidth="2" fill="none" />
                                <path id="lineBC" d="M 0 30 l 20 10" stroke="#00d7ff" strokeWidth="2" fill="none" />
                            </svg>)
                        }

                    } else {
                        if (it == size - 1) {
                            if (iteration == size - 1) {
                                arr.push(<svg className={"architecture " + "pl" + it} version="1.1" id={'svg-' + it + '-' + iteration} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40" xmlSpace="preserve">
                                    <polygon className="clickable hex-field" id={it + '-' + iteration + '-polygon'} onClick={that.move} points="20,0 40,10 40,30 20,40 0,30 0,10" />
                                    <path id="lineAB" d="M 0 30 l 20 10" stroke="#75ff00" strokeWidth="2" fill="none" />
                                    <path id="lineBC" d="M 20 40 l 20 -10" stroke="#75ff00" strokeWidth="2" fill="none" />
                                    <path id="lineAB" d="M 20 0 l 20 10" stroke="#00d7ff" strokeWidth="2" fill="none" />
                                    <path id="lineBC" d="M 40 30 l 0 -20" stroke="#00d7ff" strokeWidth="2" fill="none" />
                                </svg>)
                            } else {
                                arr.push(<svg className={"architecture " + "pl" + it} version="1.1" id={'svg-' + it + '-' + iteration} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40" xmlSpace="preserve">
                                    <polygon className="clickable hex-field" id={it + '-' + iteration + '-polygon'} onClick={that.move} points="20,0 40,10 40,30 20,40 0,30 0,10" />
                                    <path id="lineAB" d="M 0 30 l 20 10" stroke="#75ff00" strokeWidth="2" fill="none" />
                                    <path id="lineBC" d="M 20 40 l 20 -10" stroke="#75ff00" strokeWidth="2" fill="none" />
                                </svg>)
                            }
                        } else {
                            if (iteration == size - 1) {
                                arr.push(<svg className={"architecture " + "pl" + it} version="1.1" id={'svg-' + it + '-' + iteration} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40" xmlSpace="preserve">
                                    <polygon className="clickable hex-field" id={it + '-' + iteration + '-polygon'} onClick={that.move} points="20,0 40,10 40,30 20,40 0,30 0,10" />
                                    <path id="lineAB" d="M 20 0 l 20 10" stroke="#00d7ff" strokeWidth="2" fill="none" />
                                    <path id="lineBC" d="M 40 30 l 0 -20" stroke="#00d7ff" strokeWidth="2" fill="none" />
                                </svg>)
                            } else {
                                arr.push(<svg className={"architecture " + "pl" + it} version="1.1" id={'svg-' + it + '-' + iteration} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40" xmlSpace="preserve">
                                    <polygon className="clickable hex-field" id={it + '-' + iteration + '-polygon'} onClick={that.move} points="20,0 40,10 40,30 20,40 0,30 0,10" />
                                </svg>)
                            }
                        }

                    }
                }

                if (iteration == size - 1) {
                    arr.push(<br />)
                }
            }
        }

        that.setState({ jsx: arr })

        that.currentBoard(currentBoard, onMove)
        var el = document.getElementsByClassName('players')
        if (el[0]) {
            el[0].classList.remove('hidden')
        }
        var el2 = document.getElementsByClassName('rotation')
        if (el2[0]) {
            el2[0].classList.remove('hidden')
        }
        boardGenerated = true
    }

    currentBoard(currentBoard, onMove) {
        var arr1 = document.getElementsByClassName('player1-arrow')
        var arr2 = document.getElementsByClassName('player2-arrow')
        if (onMove) {
            if (onMove == 'active') {
                arr1[0].style.display = "block"
                arr2[0].style.display = "none"
            } else {
                arr1[0].style.display = "none"
                arr2[0].style.display = "block"
            }
        }
        for (var i = 0; i < currentBoard.player1Moves.length; i++) {
            var el = document.getElementById(currentBoard.player1Moves[i])
            if (el) {
                el.classList.add("red-player")
            }
        }

        for (var i = 0; i < currentBoard.player2Moves.length; i++) {
            var el2 = document.getElementById(currentBoard.player2Moves[i])
            if (el2) {
                el2.classList.add("blue-player")
            }
        }
    }

    displayTime(time) {
        var seconds = time % 60
        if (seconds < 10) {
            seconds = `0${seconds}`
        }
        var minutes = time / 60
        minutes = Math.floor(minutes % 60)
        if (minutes < 10) {
            minutes = `0${minutes}`
        }
        var hours = Math.floor(time / (60 * 60))
        if (hours < 10) {
            hours = `0${hours}`
        }

        var el = document.getElementById('time-left')
        if (el) {
            el.innerHTML = hours + ":" + minutes + ":" + seconds
        }
    }

    move(e) {
        var params = window.location.pathname
        params = params.split("/")
        socket.emit("move", ({ gameName: params[1], roomNumber: params[2], account: window.localStorage.getItem('account'), token: window.localStorage.getItem('token'), refreshToken: window.localStorage.getItem('refreshToken'), field: e.target.id }))
    }

    rotate(e) {
        that.setState({
            rotationValue: e.target.value
        }, () => {
            var el = document.getElementById("rotate-input")
            el.value = that.state.rotationValue
            var text = "rotate("
            text += el.value
            text += "deg)"
            var text2 = "rotate("
            text2 += el.value * (-1)
            text2 += "deg)"
            var gb = document.getElementById("gameBoard")
            gb.style.transform = text
            var topNumbers = document.getElementsByClassName("number-top")
            for (var i = 0; i < topNumbers.length; i++) {
                topNumbers[i].style.transform = text2
            }
            var bg = document.getElementsByClassName("background")
            if ((el.value / 1 > 5 && el.value / 1 < 149) || (el.value / 1 < -55 && el.value > -180)) {
                if (el.value / 1 > 5 && el.value / 1 < 149) {
                    if (el.value < 25) {
                        bg[0].style.height = "calc(1000px - 229px)"
                        gb.style.marginTop = "100px"
                    } else if (el.value < 50) {
                        bg[0].style.height = "calc(1100px - 229px)"
                        gb.style.marginTop = "170px"
                    } else if (el.value < 75) {
                        bg[0].style.height = "1000px"
                        gb.style.marginTop = "240px"
                    } else if (el.value < 100) {
                        bg[0].style.height = "1000px"
                        gb.style.marginTop = "240px"
                    } else if (el.value < 125) {
                        bg[0].style.height = "calc(1100px - 229px)"
                        gb.style.marginTop = "170px"
                    } else {
                        bg[0].style.height = "calc(1000px - 229px)"
                        gb.style.marginTop = "100px"
                    }
                } else {
                    if (el.value > -80) {
                        bg[0].style.height = "calc(1000px - 229px)"
                        gb.style.marginTop = "100px"
                    } else if (el.value > -105) {
                        bg[0].style.height = "calc(1100px - 229px)"
                        gb.style.marginTop = "170px"
                    } else if (el.value > -130) {
                        bg[0].style.height = "1000px"
                        gb.style.marginTop = "240px"
                    } else if (el.value > -155) {
                        bg[0].style.height = "calc(1100px - 229px)"
                        gb.style.marginTop = "170px"
                    } else {
                        bg[0].style.height = "calc(1000px - 229px)"
                        gb.style.marginTop = "100px"
                    }
                }
            } else {
                bg[0].style.height = ""
                gb.style.marginTop = ""
            }
        })
    }

    checkIfWinner(moves, boardSize, player) {
        if (that.checkIfThereIsPath(moves, boardSize, player)) {
            return true
        }
        return false
    }

    checkIfThereIsPath(moves, boardSize, player) {
        var paths = []
        if (player == 1) {
            for (var i = 0; i < moves.length; i++) {
                for (var j = 0; j < boardSize; j++) {
                    if (moves[i] == `0-${j}-polygon`) {
                        if (ended == false) {
                            paths.push(that.checkPath(moves, boardSize, player, moves[i]))
                        }
                    }
                }
            }
        } else {
            for (var i = 0; i < moves.length; i++) {
                for (var j = 0; j < boardSize; j++) {
                    if (moves[i] == `${j}-0-polygon`) {
                        if (ended == false) {
                            paths.push(that.checkPath(moves, boardSize, player, moves[i]))
                        }
                    }
                }
            }
        }
        for (var i = 0; i < paths.length; i++) {
            if (paths[i]) {
                return true
            }
        }

        return false
    }

    checkPath(moves, boardSize, player, beggining) {
        var hasNeigbur = false;
        var usedFields = [];
        var neigbours = [];
        var pathID = beggining.split('-')
        pth = []
        usedFields.push(beggining);
        do {
            if (neigbours.length > 0) {
                usedFields.push(neigbours[neigbours.length - 1])
                pathID = neigbours[neigbours.length - 1].split('-')
                neigbours.pop()
            }
            pth.push(pathID[0] + "-" + pathID[1] + '-polygon')
            pathID[0] = pathID[0] / 1
            pathID[1] = pathID[1] / 1
            var neighbours = [
                { neigbur: pathID[0] + '-' + (pathID[1] - 1) + '-polygon' },
                { neigbur: (pathID[0] - 1) + '-' + pathID[1] + '-polygon' },
                { neigbur: (pathID[0] - 1) + '-' + (pathID[1] + 1) + '-polygon' },
                { neigbur: (pathID[0]) + '-' + (pathID[1] + 1) + '-polygon' },
                { neigbur: (pathID[0] + 1) + '-' + (pathID[1] - 1) + '-polygon' },
                { neigbur: (pathID[0] + 1) + '-' + (pathID[1]) + '-polygon' }
            ]
            for (var it = 0; it < 6; it++) {
                if (moves.includes(neighbours[it].neigbur)) {
                    neigbours.push(neighbours[it].neigbur)
                }
            }
            neigbours = neigbours.filter(function (el) {
                return usedFields.indexOf(el) < 0
            })
            if (neigbours.length > 0) {
                hasNeigbur = true
                for (var i = 0; i < neigbours.length; i++) {
                    if (that.checkIfHasEnding(neigbours[i], boardSize, player)) {
                        pth.push(neigbours[i])
                        ended = true
                        return true
                    }
                }
            } else {
                hasNeigbur = false
            }
        } while (hasNeigbur)

    }

    checkIfHasEnding(id, boardSize, player) {
        if (player == 1) {
            for (var ite = 0; ite < boardSize; ite++) {
                if (id == `${boardSize - 1}-${ite}-polygon`) {
                    return true
                }
            }
        }
        else {
            for (var ite = 0; ite < boardSize; ite++) {
                if (id == `${ite}-${boardSize - 1}-polygon`) {
                    return true
                }
            }
        }
        return false
    }

    componentDidMount() {
        this.handleSocket()
        var params = window.location.pathname
        params = params.split("/")
        socket.on("disconnected", () => {
            socket.emit('checkUsersInRoom', ({ gameName: params[1], roomNumber: params[2], account: window.localStorage.getItem('account') }))
        })
        socket.on("moved", (props) => {
            times = 60 * 60 * 24
            that.displayTime(times)
            if (props.token) {
                window.localStorage.setItem("token", props.token)
            }
            if (props.player != undefined) {
                var arr1 = document.getElementsByClassName('player1-arrow')
                var arr2 = document.getElementsByClassName('player2-arrow')
                if (props.player == 1) {
                    if (document.getElementById(props.field) != null) {
                        document.getElementById(props.field).classList.add("red-player")
                    }
                    if (arr1[0] != null && arr2[0] != null) {
                        arr1[0].style.display = "none"
                        arr2[0].style.display = "block"
                    }
                } else {
                    if (document.getElementById(props.field) != null) {
                        document.getElementById(props.field).classList.add("blue-player")

                    }
                    if (arr1[0] != null && arr2[0] != null) {
                        arr1[0].style.display = "block"
                        arr2[0].style.display = "none"
                    }
                }

            }
        })

        socket.on("gameWon", (props) => {
            if (props) {
                if (props.gameName == params[1] && props.roomNumber == params[2]) {
                    if (gameFinished == true) {
                        return
                    }
                    gameFinished = true
                    var el1 = document.getElementById('gameBoard')
                    if (el1) {
                        var elRed = el1.getElementsByClassName('red-player')
                        var elBlue = el1.getElementsByClassName('blue-player')
                        var currentBoard = { player: 0, moves: [] }
                        var arr1 = document.getElementsByClassName('player1-arrow')
                        var arr2 = document.getElementsByClassName('player2-arrow')
                        if (arr1[0] != null && arr2[0] != null) {
                            if (arr1[0].style.display == "none") {
                                currentBoard.player = 1
                                for (var i = 0; i < elRed.length; i++) {
                                    currentBoard.moves.push(elRed[i].id)
                                }
                            } else {
                                currentBoard.player = 2
                                for (var i = 0; i < elBlue.length; i++) {
                                    currentBoard.moves.push(elBlue[i].id)
                                }
                            }
                        }
                        clearInterval(intervalId)
                        socket.emit("disconnectFromChannel", ({ gameName: params[1], roomNumber: params[2] }))
                        var check = that.checkIfWinner(currentBoard.moves, 11, currentBoard.player)
                        if (check) {
                            flashingInterval = setInterval(() => {
                                if (hidden) {
                                    hidden = false
                                    for (var i = 0; i < pth.length; i++) {
                                        var el = document.getElementById(pth[i])
                                        if (el) {
                                            if (currentBoard.player == 1) {
                                                el.classList.add('red-player')
                                            } else {
                                                el.classList.add('blue-player')
                                            }
                                        }
                                    }
                                } else {
                                    hidden = true
                                    for (var i = 0; i < pth.length; i++) {
                                        var el = document.getElementById(pth[i])
                                        if (el) {
                                            if (currentBoard.player == 1) {
                                                el.classList.remove('red-player')
                                            } else {
                                                el.classList.remove('blue-player')
                                            }
                                        }
                                    }
                                }
                            }, 300)
                        }
                        setTimeout(() => {
                            that.setState({ status: <Won /> })
                        }, 1000)
                        setTimeout(() => {
                            clearInterval(flashingInterval)
                        }, 3000)
                        that.hideForfeit()
                    }
                }
            }
        })

        socket.on("gameLost", (props) => {
            if (props) {
                if (props.gameName == params[1] && props.roomNumber == params[2]) {
                    if (gameFinished == true) {
                        return
                    }
                    gameFinished = true
                    var el1 = document.getElementById('gameBoard')
                    if (el1) {
                        var elRed = el1.getElementsByClassName('red-player')
                        var elBlue = el1.getElementsByClassName('blue-player')
                        var currentBoard = { player: 0, moves: [] }
                        var arr1 = document.getElementsByClassName('player1-arrow')
                        var arr2 = document.getElementsByClassName('player2-arrow')
                        if (arr1[0] != null && arr2[0] != null) {
                            if (arr1[0].style.display == "none") {
                                currentBoard.player = 1
                                for (var i = 0; i < elRed.length; i++) {
                                    currentBoard.moves.push(elRed[i].id)
                                }
                            } else {
                                currentBoard.player = 2
                                for (var i = 0; i < elBlue.length; i++) {
                                    currentBoard.moves.push(elBlue[i].id)
                                }
                            }
                        }
                        clearInterval(intervalId)
                        var check = that.checkIfWinner(currentBoard.moves, 11, currentBoard.player)
                        socket.emit("disconnectFromChannel", ({ gameName: params[1], roomNumber: params[2] }))
                        if (check) {
                            flashingInterval = setInterval(() => {
                                if (hidden) {
                                    hidden = false
                                    for (var i = 0; i < pth.length; i++) {
                                        var el = document.getElementById(pth[i])
                                        if (el) {
                                            if (currentBoard.player == 1) {
                                                el.classList.add('red-player')
                                            } else {
                                                el.classList.add('blue-player')
                                            }
                                        }
                                    }
                                } else {
                                    hidden = true
                                    for (var i = 0; i < pth.length; i++) {
                                        var el = document.getElementById(pth[i])
                                        if (el) {
                                            if (currentBoard.player == 1) {
                                                el.classList.remove('red-player')
                                            } else {
                                                el.classList.remove('blue-player')
                                            }
                                        }
                                    }
                                }
                            }, 300)
                        }
                        setTimeout(() => {
                            that.setState({ status: <Lost /> })
                        }, 1000)
                        setTimeout(() => {
                            clearInterval(flashingInterval)
                        }, 3000)
                        that.hideForfeit()
                    }
                }
            }
        })

        socket.on("users", (props) => {
            if (props.count == 1) {
                var el1 = document.getElementById('user1')
                if (el1) {
                    if (window.localStorage.getItem("name") == el1.textContent) {
                        var el = document.getElementById("p1")
                        if (el) {
                            el.setAttribute('src', '/img/active.png')
                            el.setAttribute('state', 'active')
                        }
                        el = document.getElementById("p2")
                        if (el) {
                            if (props.telegram) {
                                el.setAttribute('src', '/img/half.png')
                                el.setAttribute('state', 'telegram')
                            } else {
                                el.setAttribute('src', '/img/waiting.png')
                                el.setAttribute('state', 'offline')
                            }
                        }
                    } else {
                        var el = document.getElementById("p1")
                        if (el) {
                            if (props.telegram) {
                                el.setAttribute('src', '/img/half.png')
                                el.setAttribute('state', 'telegram')
                            } else {
                                el.setAttribute('src', '/img/waiting.png')
                                el.setAttribute('state', 'offline')
                            }
                        }
                        el = document.getElementById("p2")
                        if (el) {
                            el.setAttribute('src', '/img/active.png')
                            el.setAttribute('state', 'active')
                        }
                    }
                }

            } else {
                var el = document.getElementById("p1")
                if (el) {
                    el.setAttribute('src', '/img/active.png')
                }
                el = document.getElementById("p2")
                if (el) {
                    el.setAttribute('src', '/img/active.png')
                }
            }
        })

        socket.on("gameStopped", props => {
            if (props) {
                window.localStorage.setItem("token", props)
            }
            that.hideWithdraw()
            that.setState({ status: <GameWithdrawn /> })
        })
    }

    showWithdraw() {
        var el = document.getElementById("withdraw")
        if (el) {
            el.classList.remove("hidden")
        }

        var el2 = document.getElementById("waiting")
        if (el2) {
            el2.classList.remove("hidden")
        }
    }

    showForfeit() {
        var el = document.getElementById("forfeit")
        if (el) {
            el.classList.remove("hidden")
        }
    }

    hideWithdraw() {
        var el = document.getElementById("withdraw")
        if (el) {
            el.classList.add("hidden")
        }

        var el2 = document.getElementById("waiting")
        if (el2) {
            el2.classList.add("hidden")
        }
    }

    hideForfeit() {
        var el = document.getElementById("forfeit")
        if (el) {
            el.classList.add("hidden")
        }
    }

    componentWillUnmount() {
        clearInterval(intervalId)
        clearInterval(flashingInterval)
        window.localStorage.removeItem("shown")
        window.localStorage.removeItem("showing")
        var params = window.location.pathname
        params = params.split("/")
        if (params[1] && params[2]) {
            socket.emit("disconnectFromChannel", ({ gameName: params[1], roomNumber: params[2] }))
        }
        socket.removeAllListeners()
    }

    render() {
        return (
            <div>
                <Waiting />
                <div className="d-flex mx-auto max-content">
                    <Players />
                    {this.state.status}
                    <div id="gameBoard">
                        {this.state.jsx}
                    </div>
                </div>
                <div id="hide-animations-end" className="position-absolute alligned-middle z-200">
                    <div className="align-middle new-prompts text-center neon-border smaller-padding-important">
                        <h2 className="white-font m-0 p-4">GET READY!</h2>
                    </div>
                </div>
                {/* <div className="rotate max-content mx-auto hidden rotation">
                    <h2 className="yellow-font text-center mt-4 mb-2">Rotate Board</h2>
                    <input type="range" id="rotate-input" name="game-rotate" min="-180" max="180" value={this.state.rotationValue} onChange={this.rotate} step="1"/>
                </div> */}
                <BottomButtons />
                <WithdrawConfirmation />
                <ForfeitConfirmation />
            </div>
        )
    }
}

export default Hex