import { Component } from 'react'
import { socket } from './App'

class ForfeitConfirmation extends Component {
    constructor() {
        super()
    }

    confirmForfeit() {
        var el = document.getElementById("forfeit-confirm")
        if (el) {
            el.classList.add("hidden")
        }
        var params = window.location.pathname
        params = params.split("/")
        socket.emit('forfeit', ({ account: window.localStorage.getItem("account"), token: window.localStorage.getItem("token"), refreshToken: window.localStorage.getItem("refreshToken"), gameName: params[1], roomNumber: params[2] }))
    }

    rejectForfeit() {
        var el = document.getElementById("forfeit-confirm")
        if (el) {
            el.classList.add("hidden")
        }
    }
    render() {
        return (
            <div id="forfeit-confirm" className="alligned-middle dark-bg text-center px-5 py-5 radius small-padding-mobile hidden new-prompts">
                <p className="text-center blue-font my-0">Are you sure you want to forfeit?</p>

                <div className="d-inline-flex text-center mx-auto max-content">
                    <button id="confirm-forfeit" className="btn own-btn yellow-font mx-2" onClick={this.confirmForfeit}>Confirm</button>
                    <button id="reject-forfeit" className="btn own-btn yellow-font mx-2" onClick={this.rejectForfeit}>Reject</button>
                </div>
            </div>
        )
    }
}

export default ForfeitConfirmation