import { Component } from 'react'
import { connect } from 'react-redux'

import {
    mapStateToProps,
    mapDispatchToProps
} from '../functions/mapStateToProps'

var that
class FinishedGames extends Component {

    constructor(props) {
        super(props)
        that = this
    }

    joinGame(e) {
        that.props.history.push("watch/" + window.localStorage.getItem("name") + "/" + e.target.dataset.gamename + "/" + e.target.dataset.roomnumber)
    }

    renderGames() {
        if (that.props.game.respond == "FINISHED") {
            var jsx = []
            for (var i = that.props.game.gf.length - 1; i > -1; i--) {
                var logs = that.props.game.gf[i].gameLogs
                var splt = logs.split(";")
                var last = splt[splt.length - 2].split(":")
                var winnerName = last[last.length - 1].split(" ")
                jsx.push(
                    <div className="my-4 d-md-flex blue-font neon-border-bottom-mobile">
                        <p className="col-md-3 text-center">{that.props.game.gf[i].gameName}</p>
                        <p className="col-md-4 text-center">{winnerName[2]}</p>
                        <p className="col-md-2 text-center">{that.props.game.gf[i].gameSettings.rank.bet}</p>
                        <div className="col-md-3 text-center d-flex">
                            <button className="btn no-btn blue-font p-0 m-10 mx-auto" data-roomnumber={that.props.game.gf[i].roomNumber} data-gamename={that.props.game.gf[i].gameName} data-account={that.props.game.gf[i].gameSettings.hostAccount} data-bet={that.props.game.gf[i].gameSettings.rank.bet} onClick={this.joinGame}>Replay</button>
                        </div>
                    </div>
                )
            }
            return <div>{jsx}</div>
        }
    }

    componentDidMount() {
        that.props.listFinishedAddress(window.localStorage.getItem("account"))
        var el = document.getElementById("bg")
        if (el) {
            el.classList.add("indoor-bg")
        }

        var navbar = document.getElementById("navbarSupportedContent")
        if (navbar) {
            navbar.classList.remove("show")
        }
    }

    render() {
        return (
            <div className="position-relative">
                <div className="neon-border no-padding-lr container faq-container max-height-500 join-game-overflow">
                    <div className="row neon-border-bottom">
                        <div className="col-md-3 game-name dark-blue-font text-center">
                            Game
                        </div>
                        <div className="col-md-4 host dark-blue-font text-center">
                            Winner
                        </div>
                        <div className="col-md-2 bet dark-blue-font text-center">
                            Bet
                        </div>
                        <div className="col-md-3 join-button dark-blue-font text-center">

                        </div>
                    </div>
                    <div className="minus-margins">
                        {this.renderGames()}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinishedGames)