import {
    CREATE_GAME_ERROR,
    CREATE_GAME_REQUEST,
    CREATE_GAME_SUCCESS,
    LIST_ALL_GAMES_ERROR,
    LIST_ALL_GAMES_REQUEST,
    LIST_ALL_GAMES_SUCCESS,
    LIST_ALL_ASYNC_GAMES_ERROR,
    LIST_ALL_ASYNC_GAMES_REQUEST,
    LIST_ALL_ASYNC_GAMES_SUCCESS,
    LIST_MY_ALL_ASYNC_GAMES_ERROR,
    LIST_MY_ALL_ASYNC_GAMES_REQUEST,
    LIST_MY_ALL_ASYNC_GAMES_SUCCESS,
    LIST_MY_ALL_GAMES_ERROR,
    LIST_MY_ALL_GAMES_REQUEST,
    LIST_MY_ALL_GAMES_SUCCESS,
    JOIN_GAME_ERROR,
    JOIN_GAME_REQUEST,
    JOIN_GAME_SUCCESS,
    GAME_EXISTS_ERROR,
    GAME_EXISTS_REUQUEST,
    GAME_EXISTS_SUCCESS,
    LIST_FINISHED_ERROR,
    LIST_FINISHED_REQUEST,
    LIST_FINISHED_SUCCESS,
    GAME_ENDED_ERROR,
    GAME_ENDED_REUQUEST,
    GAME_ENDED_SUCCESS
} from '../actions/games-actions'

export default function game(state = {}, action) {
    switch (action.type) {
        case CREATE_GAME_REQUEST:
            return "Requesting game creation"
        case CREATE_GAME_SUCCESS:
            return (action.payload.data)
        case CREATE_GAME_ERROR:
            return "Game not created"
        case LIST_ALL_GAMES_REQUEST:
            return "Requesting games list"
        case LIST_ALL_GAMES_SUCCESS:
            return (action.payload.data)
        case LIST_ALL_GAMES_ERROR:
            return "Something went wrong"
        case LIST_ALL_ASYNC_GAMES_REQUEST:
            return "Requesting async games list"
        case LIST_ALL_ASYNC_GAMES_SUCCESS:
            return (action.payload.data)
        case LIST_ALL_ASYNC_GAMES_ERROR:
            return "Cannot get async games"
        case LIST_MY_ALL_GAMES_REQUEST:
            return "Requesting games list"
        case LIST_MY_ALL_GAMES_SUCCESS:
            return (action.payload.data)
        case LIST_MY_ALL_GAMES_ERROR:
            return "Something went wrong"
        case LIST_MY_ALL_ASYNC_GAMES_REQUEST:
            return "Requesting async games list"
        case LIST_MY_ALL_ASYNC_GAMES_SUCCESS:
            return (action.payload.data)
        case LIST_MY_ALL_ASYNC_GAMES_ERROR:
            return "Cannot get async games"
        case JOIN_GAME_REQUEST:
            return "Joining game"
        case JOIN_GAME_SUCCESS:
            return (action.payload.data)
        case JOIN_GAME_ERROR:
            return "Cannot join game"
        case GAME_EXISTS_REUQUEST:
            return "Requesting for game"
        case GAME_EXISTS_SUCCESS:
            return (action.payload.data)
        case GAME_EXISTS_ERROR:
            return "Game not found"
        case GAME_ENDED_REUQUEST:
            return "Requesting for game"
        case GAME_ENDED_SUCCESS:
            return (action.payload.data)
        case GAME_ENDED_ERROR:
            return "Game not found"
        case LIST_FINISHED_REQUEST:
            return "Requesting for games"
        case LIST_FINISHED_SUCCESS:
            return (action.payload.data)
        case LIST_FINISHED_ERROR:
            return "User not found"
        default:
            return state
    }
}