import { Component } from 'react'

var rankingJsx, friendlyJsx, that

class RankingGameOption extends Component {

    constructor(props) {
        super(props)
        this.state = { jsx: <div></div>, bet: 5, rankingDown: -100, rankingUp: 100 }
        that = this
        rankingJsx = <div className="mb-2 text-center">How much USDC to play for: <input id="bet" className="bet-btn" type="text" value={this.state.bet} onChange={this.betAmount} /></div>
        friendlyJsx = <div>
            <div className="mb-2 text-center">How much USDC you want to bet: <input id="bet" className="bet-btn" type="text" value={this.state.bet} onChange={this.betAmountFriendly} /></div>
            <div className="mb-2 text-center">Weaker player max difference: <span>{this.state.rankingDown}</span> <input name="weaker_range" type="range" className="own-range ms-4" min="-500" max="0" value={this.state.rankingDown} onChange={this.weakerChange} step="1" /></div>
            <div className="mb-2 text-center">Stronger player max difference: <span>{this.state.rankingUp}</span> <input name="stronger_range" type="range" className="own-range ms-4" min="0" max="500" value={this.state.rankingUp} onChange={this.strongerChange} step="1" /></div>
        </div>
    }

    weakerChange(e) {
        that.setState({ rankingDown: e.target.value / 1 }, () => {
            friendlyJsx = <div>
                <div className="mb-2 text-center">How much TABLES you want to bet: <input id="bet" className="bet-btn" type="text" value={that.state.bet} onChange={that.betAmountFriendly} /></div>
                <div className="mb-2 text-center">Weaker player max difference: <span>{that.state.rankingDown}</span> <input name="weaker_range" className="own-range ms-4" type="range" min="-500" max="0" value={that.state.rankingDown} onChange={that.weakerChange} step="1" /></div>
                <div className="mb-2 text-center">Stronger player max difference: <span>{that.state.rankingUp}</span> <input name="stronger_range" className="own-range ms-4" type="range" min="0" max="500" value={that.state.rankingUp} onChange={that.strongerChange} step="1" /></div>
            </div>
            that.rankingChange()
        })
    }

    strongerChange(e) {
        that.setState({ rankingUp: e.target.value / 1 }, () => {
            friendlyJsx = <div>
                <div className="mb-2 text-center">How much TABLES you want to bet: <input id="bet" className="bet-btn" type="text" value={that.state.bet} onChange={that.betAmountFriendly} /></div>
                <div className="mb-2 text-center">Weaker player max difference: <span>{that.state.rankingDown}</span> <input name="weaker_range" className="own-range ms-4" type="range" min="-500" max="0" value={that.state.rankingDown} onChange={that.weakerChange} step="1" /></div>
                <div className="mb-2 text-center">Stronger player max difference: <span>{that.state.rankingUp}</span> <input name="stronger_range" className="own-range ms-4" type="range" min="0" max="500" value={that.state.rankingUp} onChange={that.strongerChange} step="1" /></div>
            </div>
            that.rankingChange()
        })
    }

    betAmountFriendly(e) {
        if (!Number.isNaN(e.target.value / 1)) {
            that.setState({
                bet: e.target.value / 1
            }, () => {
                friendlyJsx = <div>
                    <div className="mb-2 text-center">How much TABLES you want to bet: <input id="bet" className="bet-btn" type="text" value={that.state.bet} onChange={that.betAmountFriendly} /></div>
                    <div className="mb-2 text-center">Weaker player max difference: <span>{that.state.rankingDown}</span> <input name="weaker_range" className="own-range ms-4" type="range" min="-500" max="0" value={that.state.rankingDown} onChange={that.weakerChange} step="1" /></div>
                    <div className="mb-2 text-center">Stronger player max difference: <span>{that.state.rankingUp}</span> <input name="stronger_range" className="own-range ms-4" type="range" min="0" max="500" value={that.state.rankingUp} onChange={that.strongerChange} step="1" /></div>
                </div>
                that.rankingChange()
            })
        }
    }

    betAmount(e) {
        if (!Number.isNaN(e.target.value / 1)) {
            that.setState({
                bet: e.target.value / 1
            }, () => {
                rankingJsx = <div className="mb-2 text-center">How much TABLES you want to bet: <input id="bet" className="bet-btn" type="text" value={that.state.bet} onChange={that.betAmount} /></div>
                that.friendlyChange()
            })
        }
    }

    friendlyChange() {
        that.setState({
            jsx: rankingJsx
        })
    }

    rankingChange() {
        that.setState({
            jsx: friendlyJsx
        })
    }

    componentDidMount() {
        this.friendlyChange()
    }

    render() {
        return (
            <div>
                <div className="d-flex mb-2 text-center">
                    {/* <div className="mx-auto">
                        <label>Ranking Match</label>
                        <input id="ranking" className="radio-flexed" name="ranking-radio" type="radio" defaultChecked onChange={this.friendlyChange} />
                    </div>
                    <div className="mx-auto">
                        <label>Friendly Match</label>
                        <input id="friendly" className="radio-flexed" name="ranking-radio" type="radio" onChange={this.rankingChange} />
                    </div> */}
                </div>
                {this.state.jsx}
            </div>
        )
    }
}

export default RankingGameOption

