import axios from 'axios'

export const FETCH_DUSERNAME_SUCCESS = 'fetch_dusername_success'
export const FETCH_DUSERNAME_ERROR = 'fetch_dusername_error'
export const FETCH_DUSERNAME_REQUEST = 'fetch_dusername_request'
export const FETCH_TUSERNAME_SUCCESS = 'fetch_tusername_success'
export const FETCH_TUSERNAME_ERROR = 'fetch_tusername_error'
export const FETCH_TUSERNAME_REQUEST = 'fetch_tusername_request'
export const FETCH_USERNAME_SUCCESS = 'fetch_username_success'
export const FETCH_USERNAME_ERROR = 'fetch_username_error'
export const FETCH_USERNAME_REQUEST = 'fetch_username_request'
export const FETCH_TELEGRAM_SUCCESS = 'fetch_telegram_success'
export const FETCH_TELEGRAM_ERROR = 'fetch_telegram_error'
export const FETCH_TELEGRAM_REQUEST = 'fetch_telegram_request'
export const FETCH_DISCORD_SUCCESS = 'fetch_discord_success'
export const FETCH_DISCORD_ERROR = 'fetch_discord_error'
export const FETCH_DISCORD_REQUEST = 'fetch_discord_request'
export const CREATE_ACCOUNT_SUCCESS = 'create_account_success'
export const CREATE_ACCOUNT_ERROR = 'create_account_error'
export const CREATE_ACCOUNT_REQUEST = 'create_account_request'
export const NEW_REFRESHTOKEN_SUCCESS = 'new_refreshtoken_success'
export const NEW_REFRESHTOKEN_ERROR = 'new_refreshtoken_error'
export const NEW_REFRESHTOKEN_REQUEST = 'new_refreshtoken_request'
export const NEW_TOKEN_SUCCESS = 'new_token_success'
export const NEW_TOKEN_ERROR = 'new_token_error'
export const NEW_TOKEN_REQUEST = 'new_token_request'
export const CREATE_RATINGS_ERROR = 'create_ratings_error'
export const CREATE_RATINGS_REQUEST = 'create_ratings_request'
export const CREATE_RATINGS_SUCCESS = 'create_ratings_success'
export const LIST_LOGS_ERROR = 'list_logs_error'
export const LIST_LOGS_REQUEST = 'list_logs_request'
export const LIST_LOGS_SUCCESS = 'list_logs_success'
export const CHANGE_PASS_REQUEST = 'change_pass_request'
export const CHANGE_PASS_SUCCESS = 'change_pass_request'
export const CHANGE_PASS_ERROR = 'change_pass_request'

export function fetchUsername(account, token, refreshToken) {
    const postBody = {
        account: account,
        token: token,
        refreshToken: refreshToken
    }
    return function (dispatch) {
        dispatch(fetchUsernameRequest())
        axios.post(process.env.REACT_APP_CHECK_IF_REGISTERED, postBody)
            .then((res) => {
                dispatch(fetchUsernameSuccess(res))
            }, (e) => {
                dispatch(fetchUsernameError(e))
            })
    }
    function fetchUsernameRequest() {
        return {
            type: FETCH_USERNAME_REQUEST
        }
    }

    function fetchUsernameSuccess(res) {
        return {
            type: FETCH_USERNAME_SUCCESS,
            payload: res
        }
    }

    function fetchUsernameError(e) {
        return {
            type: FETCH_USERNAME_ERROR,
            payload: e
        }
    }
}

export function createAccount(account, name, password) {
    const postBody = {
        username: name,
        account: account,
        password: password
    }
    return function (dispatch) {
        dispatch(createAccountRequest())
        axios.post(process.env.REACT_APP_REGISTER, postBody)
            .then((res) => {
                dispatch(createAccountSuccess(res))
            }, (e) => {
                dispatch(createAccountError(e))
            })
    }

    function createAccountRequest() {
        return {
            type: CREATE_ACCOUNT_REQUEST
        }
    }

    function createAccountSuccess(res) {
        return {
            type: CREATE_ACCOUNT_SUCCESS,
            payload: res
        }
    }

    function createAccountError(e) {
        return {
            type: CREATE_ACCOUNT_ERROR,
            payload: e
        }
    }
}

export function askNewRefreshToken(account, password) {
    const postBody = {
        account: account,
        password: password
    }
    return function (dispatch) {
        dispatch(askRefreshTokenRequest())
        axios.post(process.env.REACT_APP_ASK_NEW_REFRESH_TOKEN, postBody)
            .then((res) => {
                dispatch(askRefreshTokenSuccess(res))
            }, (e) => {
                dispatch(askRefreshTokenError(e))
            })
    }

    function askRefreshTokenRequest() {
        return {
            type: NEW_REFRESHTOKEN_REQUEST
        }
    }

    function askRefreshTokenSuccess(res) {
        return {
            type: NEW_REFRESHTOKEN_SUCCESS,
            payload: res
        }
    }

    function askRefreshTokenError(e) {
        return {
            type: NEW_REFRESHTOKEN_ERROR,
            payload: e
        }
    }
}

export function changePassword(account, pass, oldPass) {
    const postBody = {
        account: account,
        pass: pass,
        oldPass: oldPass
    }
    return function (dispatch) {
        dispatch(changePassRequest())
        axios.post(process.env.REACT_APP_CHANGE_PASSWORD, postBody)
            .then((res) => {
                dispatch(changePassSuccess(res))
            }, (e) => {
                dispatch(changePassError(e))
            })
    }

    function changePassRequest() {
        return {
            type: CHANGE_PASS_REQUEST
        }
    }

    function changePassSuccess(res) {
        return {
            type: CHANGE_PASS_SUCCESS,
            payload: res
        }
    }

    function changePassError(e) {
        return {
            type: CHANGE_PASS_ERROR,
            payload: e
        }
    }
}

export function askNewToken(account, refreshToken) {
    const postBody = {
        account: account,
        refreshToken: refreshToken
    }
    return function (dispatch) {
        dispatch(askTokenRequest())
        axios.post(process.env.REACT_APP_ASK_NEW_TOKEN, postBody)
            .then((res) => {
                dispatch(askTokenSuccess(res))
            }, (e) => {
                dispatch(askTokenError(e))
            })
    }

    function askTokenRequest() {
        return {
            type: NEW_TOKEN_REQUEST
        }
    }

    function askTokenSuccess(res) {
        return {
            type: NEW_TOKEN_SUCCESS,
            payload: res
        }
    }

    function askTokenError(e) {
        return {
            type: NEW_TOKEN_ERROR,
            payload: e
        }
    }
}

export function fetchRatings(account) {
    return function (dispatch) {
        dispatch(listRatingsRequest())
        axios.get(process.env.REACT_APP_CHECK_RATINGS, { params: { account: account } })
            .then((res) => {
                dispatch(listRatingsSuccess(res))
            }, (e) => {
                dispatch(listRatingsError(e))
            })
    }

    function listRatingsRequest() {
        return {
            type: CREATE_RATINGS_REQUEST
        }
    }

    function listRatingsSuccess(res) {
        return {
            type: CREATE_RATINGS_SUCCESS,
            payload: res
        }
    }

    function listRatingsError(e) {
        return {
            type: CREATE_RATINGS_ERROR,
            payload: e
        }
    }
}

export function fetchLogs(account) {
    return function (dispatch) {
        dispatch(listLogsRequest())
        axios.get(process.env.REACT_APP_FETCH_LOGS, { params: { account: account } })
            .then((res) => {
                dispatch(listLogsSuccess(res))
            }, (e) => {
                dispatch(listLogsError(e))
            })
    }

    function listLogsRequest() {
        return {
            type: LIST_LOGS_REQUEST
        }
    }

    function listLogsSuccess(res) {
        return {
            type: LIST_LOGS_SUCCESS,
            payload: res
        }
    }

    function listLogsError(e) {
        return {
            type: LIST_LOGS_ERROR,
            payload: e
        }
    }
}

export function fetchTelegram(account) {
    return function (dispatch) {
        dispatch(fetchTelegramRequest())
        axios.get(process.env.REACT_APP_FETCH_TELEGRAM, { params: { account: account } })
            .then((res) => {
                dispatch(fetchTelegramSuccess(res))
            }, (e) => {
                dispatch(fetchTelegramError(e))
            })
    }
    function fetchTelegramRequest() {
        return {
            type: FETCH_TELEGRAM_REQUEST
        }
    }

    function fetchTelegramSuccess(res) {
        return {
            type: FETCH_TELEGRAM_SUCCESS,
            payload: res
        }
    }

    function fetchTelegramError(e) {
        return {
            type: FETCH_TELEGRAM_ERROR,
            payload: e
        }
    }
}

export function fetchDiscord(account) {
    return function (dispatch) {
        dispatch(fetchDiscordRequest())
        axios.get(process.env.REACT_APP_FETCH_DISCORD, { params: { account: account } })
            .then((res) => {
                dispatch(fetchDiscordSuccess(res))
            }, (e) => {
                dispatch(fetchDiscordError(e))
            })
    }
    function fetchDiscordRequest() {
        return {
            type: FETCH_DISCORD_REQUEST
        }
    }

    function fetchDiscordSuccess(res) {
        return {
            type: FETCH_DISCORD_SUCCESS,
            payload: res
        }
    }

    function fetchDiscordError(e) {
        return {
            type: FETCH_DISCORD_ERROR,
            payload: e
        }
    }
}

export function createTelegram(val, account, token, refreshToken) {
    const postBody = {
        account: account,
        val: val,
        token: token,
        refreshToken: refreshToken
    }
    return function (dispatch) {
        dispatch(fetchUsernameRequest())
        axios.post(process.env.REACT_APP_CREATE_USERNAME_TELEGRAM, postBody)
            .then((res) => {
                dispatch(fetchUsernameSuccess(res))
            }, (e) => {
                dispatch(fetchUsernameError(e))
            })
    }
    function fetchUsernameRequest() {
        return {
            type: FETCH_TUSERNAME_REQUEST
        }
    }

    function fetchUsernameSuccess(res) {
        return {
            type: FETCH_TUSERNAME_SUCCESS,
            payload: res
        }
    }

    function fetchUsernameError(e) {
        return {
            type: FETCH_TUSERNAME_ERROR,
            payload: e
        }
    }
}

export function createDiscord(val, account, token, refreshToken) {
    const postBody = {
        account: account,
        val: val,
        token: token,
        refreshToken: refreshToken
    }
    return function (dispatch) {
        dispatch(fetchDiscordUsernameRequest())
        axios.post(process.env.REACT_APP_CREATE_USERNAME_DISCORD, postBody)
            .then((res) => {
                dispatch(fetchDiscordUsernameSuccess(res))
            }, (e) => {
                dispatch(fetchDiscordUsernameError(e))
            })
    }
    function fetchDiscordUsernameRequest() {
        return {
            type: FETCH_DUSERNAME_REQUEST
        }
    }

    function fetchDiscordUsernameSuccess(res) {
        return {
            type: FETCH_DUSERNAME_SUCCESS,
            payload: res
        }
    }

    function fetchDiscordUsernameError(e) {
        return {
            type: FETCH_DUSERNAME_ERROR,
            payload: e
        }
    }
}
