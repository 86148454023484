import {

    LIST_LOGS_ERROR,
    LIST_LOGS_REQUEST,
    LIST_LOGS_SUCCESS,

} from '../actions/user-actions'

export default function user(state = {}, action) {
    switch (action.type) {
        case LIST_LOGS_REQUEST:
            return { status: 'Asking for logs' }
        case LIST_LOGS_SUCCESS:
            return (action.payload.data)
        case LIST_LOGS_ERROR:
            return { status: 'Logs not fetched' }
        default:
            return (state)
    }
}