import { Component } from 'react'

class WelcomeTour extends Component {
    constructor() {
        super()
    }

    componentDidMount() {
        var el = document.getElementById("bg")
        if (el) {
            el.classList.add("indoor-bg")
        }

        document.getElementById('connectingMetamask').style.display = 'none'

        var navbar = document.getElementById("navbarSupportedContent")
        if (navbar) {
            navbar.classList.remove("show")
        }
    }

    componentWillUnmount() {
        document.getElementById('connectingMetamask').style.display = ''
    }

    render() {
        return (
            <div className="blue-font container faq-container mx-auto p-5 container-fluid width-100-mobile position-relative neon-border max-height-500 join-game-overflow">
                <h1 className="yellow-font text-center">Welcome to The Chessmatch on The Playable Web!</h1>
                <p className="text-center dark-blue-font-small">Five Steps to start your journey to board game heaven</p>
                <strong className="yellow-font">Step 1: </strong>
                <p className="dark-blue-font-small">
                    Make sure you have downloaded and installed <a className="dark-blue-font-small" href={"https://metamask.io/"} target={"_blank"}>Metamask</a> on your Chrome browser.
                </p>
                <p className="dark-blue-font-small">
                    Game stakes on the Playable Web are all in <a className="dark-blue-font-small" href={"https://www.circle.com/en/usdc"} target={"_blank"}>USDC</a>, so make sure to load yourself up with some of that good stuff into your Metamask wallet before you want to play.
                </p>
                <p className="dark-blue-font-small">
                    You'll also need a small amount of ETH in your wallet to cover gas fees for exchanging USDC between your public wallet and your Playable Web wallet.
                </p>

                <img src="/img/step-1.png" className="w-100 my-4" />
                <p className="dark-blue-font-small">
                    <strong className="yellow-font">Step 2:</strong><br></br>  Get connected to The Playable Web
                    <ul className="dark-blue-font-small">
                        <li>Click "Get Started" on the front page of TheTables. </li>
                        <li>Log in to your Metamask wallet if prompted.</li>
                        <li>Accept the connection prompts to The Tables.</li>
                        <li>Create your handle. (This is the only public tag you will have in the Playable Web metaverse, so choose wisely as it can't be changed later)</li>
                    </ul>
                </p>

                <img src="/img/step-2.png" className="w-100 my-4" />
                <p className="dark-blue-font-small">
                    <strong className="yellow-font">Step 3:</strong> <br></br>Fund your Playable Web wallet
                </p>
                <ul className="dark-blue-font-small">
                    <li>Make sure your Metamask is connected to Ethereum Mainnet (so it can see your public wallet)</li>
                    <li>Head to "Manage" {'>'} "Cashier"</li>
                    <li>Input the amount of USDC you want to transfer in</li>
                    <li>Click "Deposit"</li>
                    <li>Follow the prompts in Metamask to Confirm the transfer; you'll need to have enough USDC and ETH to cover the transfer and the gas.</li>
                    <li>Now, switch your metamask to "Playable Web". As soon as the transfer is complete, your balance will show on the top right hand corner of the screen.</li>
                </ul>
                <br></br>
                <p className="dark-blue-font-small">
                    <strong>Top Tip:</strong> Your bet must be equal or greater than 5 USDC.
                </p>
                <img src="/img/step-3.png" className="w-100 my-4" />
                <p className="dark-blue-font-small">
                    <strong className="yellow-font">Step 4:</strong> <br></br>Go play!
                </p>
                <ul className="dark-blue-font-small">
                    <li>Go to the "Play Menu" and choose "Join Game" or "Host Game"</li>
                    <li>To <strong>HOST</strong> a game, set your stake and click "Host Game". Metamask will ask you to confirm transferring the USDC from your wallet on Playable Web to the match smart contract on our blockchain. Note that there is no gas to pay for this transfer, which is instant!</li>
                    <li>To <strong>JOIN</strong> a game, pick the one you fancy from the list of games in the Lobby, with their stakes and fees - as well as the ranking of the host. Metamask will ask you confirm transferring the stake into the match contract; this transfer is also instant and has no gas to pay.</li>
                    <li>Play!</li>
                    <li>At the end of the game, our smart contract will pay the combined stakes to the winner's USDC balance on the Playable Web instantly and gas-free. </li>
                </ul>
                <p className="dark-blue-font-small">
                    <strong className="yellow-font">Step 5:</strong> <br></br>Take the money
                </p>
                <ul className="dark-blue-font-small">
                    <li><strong>Make sure your Metamask is connected to the Ethereum Mainnet.</strong>. You'll also need some ETH to cover the gas fee.</li>
                    <li>To withdraw money from your USDC balance on Playable Web, go to "Manage" {'>'} "Cashier"</li>
                    <li>Enter the amount you want to cash out and click "Withdraw From Playable Web"</li>
                    <li>Metamask popup will let you confirm the gas.</li>
                    <li>That's it. Don't forget to switch back to Playable Web blockchain in Metamask to continue playing.</li>
                    <li>We are running this transactions once a hour so do not be afraid if it does not show up right away! Just be sure you have enough USDC on our blockchain at this time.</li>
                </ul>

            </div>
        )
    }
}

export default WelcomeTour