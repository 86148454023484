import {
    CONNECT_ACCOUNT_REQUEST,
    CONNECT_ACCOUNT_SUCCESS,
    CONNECT_ACCOUNT_ERROR
} from '../actions/metamask-actions'

export default function metamask(state = {}, action) {
    switch (action.type) {
        case CONNECT_ACCOUNT_REQUEST:
            return "Connecting wallet"
        case CONNECT_ACCOUNT_SUCCESS:
            return (action.payload)
        case CONNECT_ACCOUNT_ERROR:
            return "Wallet not-connected"
        default:
            return state
    }
}