import {
    CHECK_ADMIN_ERROR,
    CHECK_ADMIN_REQUEST,
    CHECK_ADMIN_SUCCESS,
    ISSUE_TOKEN_ERROR,
    ISSUE_TOKEN_REQUEST,
    ISSUE_TOKEN_SUCCESS
} from '../actions/admin-actions'

export default function game(state = {}, action) {
    switch (action.type) {
        case CHECK_ADMIN_REQUEST:
            return "Checking if you are administrator"
        case CHECK_ADMIN_SUCCESS:
            return (action.payload.data)
        case CHECK_ADMIN_ERROR:
            return "You are not an administrator!"
        case ISSUE_TOKEN_REQUEST:
            return "Requesting new token to website"
        case ISSUE_TOKEN_SUCCESS:
            return (action.payload.data)
        case ISSUE_TOKEN_ERROR:
            return "Something went wrong"
        default:
            return state
    }
}