import {
    askNewRefreshToken,
    askNewToken,
    fetchUsername,
    createAccount,
    fetchRatings,
    fetchLogs,
    fetchTelegram,
    createTelegram,
    fetchDiscord,
    createDiscord,
    changePassword
} from '../actions/user-actions'

import {
    connectAccounts,
    connectWallet
} from '../actions/metamask-actions'

import {
    createGame,
    listGames,
    listAsyncGames,
    joinGame,
    checkIfGameExist,
    listMyAsyncGames,
    listMyGames,
    listFinishedAddress,
    checkIfGameEnded
} from '../actions/games-actions'

import {
    checkIfAdministrator,
    issueToken,
    getPasses
} from '../actions/admin-actions'

export function mapStateToProps(state) {
    return {
        user: state.user,
        metamask: state.metamask,
        game: state.game,
        admin: state.admin,
        tokens: state.tokens,
        logs: state.logs
    }
}

export const mapDispatchToProps = { askNewRefreshToken, askNewToken, changePassword, fetchUsername, fetchDiscord, createDiscord, createTelegram, createAccount, fetchRatings, fetchLogs, fetchTelegram, connectAccounts, checkIfGameEnded, connectWallet, createGame, listGames, listFinishedAddress, listAsyncGames, listMyGames, listMyAsyncGames, joinGame, checkIfGameExist, checkIfAdministrator, issueToken, getPasses }