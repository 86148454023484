import { Component } from 'react'

class Footer extends Component {
    constructor() {
        super()
        this.state = {
            emailMailTo: "mailto: support@chessmatch.io",
            email: "support@chessmatch.io",
            twitter: "https://twitter.com/",
            instagram: "https://www.instagram.com/",
            facebook: "https://www.facebook.com/"
        }
    }
    render() {
        return (
            <div className="position-absolute w-100 bottom-0">
                <div className="d-sm-flex py-4 bottom-elements mx-auto">
                    <div className="email mx-4 text-center">
                        <a href={this.state.emailMailTo} className="dark-blue-font">{this.state.email}</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer