import { Component } from 'react'

var that, phase, id, shown = true
var phases = [
    {
        html: `Welcome to the Hex tutorial. You'll soon be on your way to being a Grand Master. To move through the tutorial click "Next", or accomplish the lesson task. To go back click "Previous". See you at The Tables soon.`,
        state: {
            p1: [],
            p2: []
        },
        hideNext: false
    },
    {
        html: `You and your opponents' handle and rating are shown on the left. Everyone starts their career with a rating of 1500, which goes up with each victory (or down with each loss). `,
        state: {
            p1: [],
            p2: []
        },
        hideNext: false
    },
    {
        html: 'Hex is a turn based game. The player whose move it is now is indicated by the ">" next to their handle.',
        state: {
            p1: [],
            p2: []
        },
        hideNext: false
    },
    {
        html: `The colour of each player's counters on the board is shown after their ranking`,
        state: {
            p1: [],
            p2: []
        },
        hideNext: false
    },
    {
        html: `The Green counter player makes the first move. In the rules of Hex, the other player can switch opponents counter by clicking on it in their first move, but doesn't have to. 
         This first-move-swap rule helps remove a strategic advantage of being the first to move, but that's down to you. 
         
        <p>To proceed, place your counter on E5, where the Green counter is.</p>`,
        state: {
            p1: ['4-4-polygon'],
            p2: []
        },
        further: '4-4-polygon',
        move: true,
        hideNext: false
    },
    {
        html: 'To win the game you need to connect both sides of the board using your counters not interrupted by opponent. Green plays top to bottom, Blue plays side to side - like this:',
        state: {
            p1: [],
            p2: ['4-0-polygon', '4-1-polygon', '4-2-polygon', '4-3-polygon', '4-4-polygon', '4-5-polygon', '4-6-polygon', '4-7-polygon', '4-8-polygon', '4-9-polygon', '4-10-polygon']
        },
        hideNext: false
    },
    {
        html: `Let's try it. Blue needs to place one counter to win the game by connecting the left and right edges ... go ahead and place a counter on A11 to win.`,
        state: {
            p1: ['0-0-polygon', '0-1-polygon', '0-2-polygon', '0-3-polygon', '1-3-polygon', '2-3-polygon', '3-3-polygon', '4-3-polygon', '6-3-polygon', '7-3-polygon'],
            p2: ['8-0-polygon', '7-1-polygon', '6-2-polygon', '5-3-polygon', '4-4-polygon', '4-5-polygon', '4-6-polygon', '3-7-polygon', '2-8-polygon', '1-9-polygon']
        },
        further: '0-10-polygon',
        move: true,
        hideNext: false
    },
    {
        html: `Good job! You are ready to play real game and win real USDC by connecting those edges. Head to the "Play" menu to host or join a game. Don't forget to load up your wallet with USDC first`,
        state: {
            p1: ['0-0-polygon', '0-1-polygon', '0-2-polygon', '0-3-polygon', '1-3-polygon', '2-3-polygon', '3-3-polygon', '4-3-polygon', '6-3-polygon', '7-3-polygon'],
            p2: ['8-0-polygon', '7-1-polygon', '6-2-polygon', '5-3-polygon', '4-4-polygon', '4-5-polygon', '4-6-polygon', '3-7-polygon', '2-8-polygon', '1-9-polygon', '0-10-polygon']
        },
        hideNext: true
    }
]
class HexTutorial extends Component {
    constructor() {
        super()
        that = this
    }

    generateBoard(size) {
        let arr = []
        let horizontalMax = size + size - 0.5
        let svgWidth = 100 / horizontalMax
        arr.push(<h2 className="widther d-table-cell"></h2>)
        for (var i = 0; i < size; i++) {
            arr.push(<h2 className="yellow-font text-center d-table-cell number-top">{i + 1}</h2>)
        }
        for (var it = 0; it < size; it++) {
            for (var iteration = 0; iteration < size; iteration++) {
                if (it == 0) {
                    if (iteration == 0) {
                        var chr = String.fromCharCode(65 + it)
                        arr.push(<h2 className={"yellow-font text-center number-top max-content letter-left me-2 m-bot-5"}>{chr}</h2>)
                        arr.push(<svg onClick={that.move} className="architecture m-bot-5" version="1.1" id={'svg-' + it + '-' + iteration} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40" xmlSpace="preserve">
                            <polygon className="hex-field clickable" onClick={this.move(it + '-' + iteration + '-polygon')} id={it + '-' + iteration + '-polygon'} points="20,0 40,10 40,30 20,40 0,30 0,10" />
                            <path id="lineAB" d="M 0 10 l 20 -10" stroke="#75ff00" strokeWidth="2" fill="none" />
                            <path id="lineBC" d="M 20 0 l 20 10" stroke="#75ff00" strokeWidth="2" fill="none" />
                            <path id="lineAB" d="M 0 10 l 0 20" stroke="#00d7ff" strokeWidth="2" fill="none" />
                            <path id="lineBC" d="M 0 30 l 20 10" stroke="#00d7ff" strokeWidth="2" fill="none" />
                        </svg>)
                    } else {
                        if (iteration == size - 1) {
                            arr.push(<svg className="architecture m-bot-5" version="1.1" id={'svg-' + it + '-' + iteration} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40" xmlSpace="preserve">
                                <polygon className="hex-field clickable" onClick={this.move(it + '-' + iteration + '-polygon')} id={it + '-' + iteration + '-polygon'} points="20,0 40,10 40,30 20,40 0,30 0,10" />
                                <path id="lineAB" d="M 0 10 l 20 -10" stroke="#75ff00" strokeWidth="2" fill="none" />
                                <path id="lineBC" d="M 20 0 l 10 5" stroke="#75ff00" strokeWidth="2" fill="none" />
                                <path id="lineAB" d="M 30 5 l 10 5" stroke="#00d7ff" strokeWidth="2" fill="none" />
                                <path id="lineBC" d="M 40 30 l 0 -20" stroke="#00d7ff" strokeWidth="2" fill="none" />
                            </svg>)
                        } else {
                            arr.push(<svg className="architecture m-bot-5" version="1.1" id={'svg-' + it + '-' + iteration} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40" xmlSpace="preserve">
                                <polygon className="hex-field clickable" onClick={this.move(it + '-' + iteration + '-polygon')} id={it + '-' + iteration + '-polygon'} points="20,0 40,10 40,30 20,40 0,30 0,10" />
                                <path id="lineAB" d="M 0 10 l 20 -10" stroke="#75ff00" strokeWidth="2" fill="none" />
                                <path id="lineBC" d="M 20 0 l 20 10" stroke="#75ff00" strokeWidth="2" fill="none" />
                            </svg>)

                        }
                    }
                } else {
                    if (iteration == 0) {
                        var chr = String.fromCharCode(65 + it)
                        if (it == size - 1) {
                            arr.push(<h2 className={"yellow-font text-center number-top max-content letter-left me-2" + " pl" + it + "it" + iteration}>{chr}</h2>)
                            arr.push(<svg className={"architecture " + "pl" + it} version="1.1" id={'svg-' + it + '-' + iteration} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40" xmlSpace="preserve">
                                <polygon className="hex-field clickable" onClick={this.move(it + '-' + iteration + '-polygon')} id={it + '-' + iteration + '-polygon'} points="20,0 40,10 40,30 20,40 0,30 0,10" />
                                <path id="lineAB" d="M 0 10 l 0 20" stroke="#00d7ff" strokeWidth="2" fill="none" />
                                <path id="lineBC" d="M 0 30 l 10 5" stroke="#00d7ff" strokeWidth="2" fill="none" />
                                <path id="lineAB" d="M 10 35 l 10 5" stroke="#75ff00" strokeWidth="2" fill="none" />
                                <path id="lineBC" d="M 20 40 l 20 -10" stroke="#75ff00" strokeWidth="2" fill="none" />
                            </svg>)
                        } else {
                            arr.push(<h2 className={"yellow-font text-center number-top max-content letter-left me-2" + " pl" + it + "it" + iteration}>{chr}</h2>)
                            arr.push(<svg className={"architecture " + "pl" + it} version="1.1" id={'svg-' + it + '-' + iteration} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40" xmlSpace="preserve">
                                <polygon className="hex-field clickable" onClick={this.move(it + '-' + iteration + '-polygon')} id={it + '-' + iteration + '-polygon'} points="20,0 40,10 40,30 20,40 0,30 0,10" />
                                <path id="lineAB" d="M 0 10 l 0 20" stroke="#00d7ff" strokeWidth="2" fill="none" />
                                <path id="lineBC" d="M 0 30 l 20 10" stroke="#00d7ff" strokeWidth="2" fill="none" />
                            </svg>)
                        }

                    } else {
                        if (it == size - 1) {
                            if (iteration == size - 1) {
                                arr.push(<svg className={"architecture " + "pl" + it} version="1.1" id={'svg-' + it + '-' + iteration} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40" xmlSpace="preserve">
                                    <polygon className="hex-field clickable" onClick={this.move(it + '-' + iteration + '-polygon')} id={it + '-' + iteration + '-polygon'} points="20,0 40,10 40,30 20,40 0,30 0,10" />
                                    <path id="lineAB" d="M 0 30 l 20 10" stroke="#75ff00" strokeWidth="2" fill="none" />
                                    <path id="lineBC" d="M 20 40 l 20 -10" stroke="#75ff00" strokeWidth="2" fill="none" />
                                    <path id="lineAB" d="M 20 0 l 20 10" stroke="#00d7ff" strokeWidth="2" fill="none" />
                                    <path id="lineBC" d="M 40 30 l 0 -20" stroke="#00d7ff" strokeWidth="2" fill="none" />
                                </svg>)
                            } else {
                                arr.push(<svg className={"architecture " + "pl" + it} version="1.1" id={'svg-' + it + '-' + iteration} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40" xmlSpace="preserve">
                                    <polygon className="hex-field clickable" onClick={this.move(it + '-' + iteration + '-polygon')} id={it + '-' + iteration + '-polygon'} points="20,0 40,10 40,30 20,40 0,30 0,10" />
                                    <path id="lineAB" d="M 0 30 l 20 10" stroke="#75ff00" strokeWidth="2" fill="none" />
                                    <path id="lineBC" d="M 20 40 l 20 -10" stroke="#75ff00" strokeWidth="2" fill="none" />
                                </svg>)
                            }
                        } else {
                            if (iteration == size - 1) {
                                arr.push(<svg className={"architecture " + "pl" + it} version="1.1" id={'svg-' + it + '-' + iteration} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40" xmlSpace="preserve">
                                    <polygon className="hex-field clickable" onClick={this.move(it + '-' + iteration + '-polygon')} id={it + '-' + iteration + '-polygon'} points="20,0 40,10 40,30 20,40 0,30 0,10" />
                                    <path id="lineAB" d="M 20 0 l 20 10" stroke="#00d7ff" strokeWidth="2" fill="none" />
                                    <path id="lineBC" d="M 40 30 l 0 -20" stroke="#00d7ff" strokeWidth="2" fill="none" />
                                </svg>)
                            } else {
                                arr.push(<svg className={"architecture " + "pl" + it} version="1.1" id={'svg-' + it + '-' + iteration} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40" xmlSpace="preserve">
                                    <polygon className="hex-field clickable" onClick={this.move(it + '-' + iteration + '-polygon')} id={it + '-' + iteration + '-polygon'} points="20,0 40,10 40,30 20,40 0,30 0,10" />
                                </svg>)
                            }
                        }

                    }
                }

                if (iteration == size - 1) {
                    arr.push(<br />)
                }
            }
        }
        return arr
    }

    componentDidMount() {
        var el = document.getElementById("bg")
        if (el) {
            el.classList.add("indoor-bg")
        }
        phase = 0
        document.getElementById('order').innerHTML = phases[phase].html
    }

    prev() {
        if (phase > 0) {
            var elems = document.querySelectorAll(".red-player");

            [].forEach.call(elems, function (el) {
                el.classList.remove("red-player")
            })

            elems = document.querySelectorAll(".blue-player");

            [].forEach.call(elems, function (el) {
                el.classList.remove("blue-player")
            })
            if (!shown) {
                shown = true
                var el = document.getElementsByClassName('names')
                el[0].classList.remove('hidden-vis')
                el[1].classList.remove('hidden-vis')
                el = document.getElementsByClassName('player1-arrow')
                el[0].classList.remove('hidden-vis')
                el = document.getElementsByClassName('np')
                el[0].classList.remove('hidden-vis')
                el[1].classList.remove('hidden-vis')
            }
            clearInterval(id)
            phase--
            if (phase <= 3 && phase >= 1) {
                if (phase == 1) {
                    var el = document.getElementsByClassName('names')
                    id = setInterval(() => {
                        if (shown) {
                            el[0].classList.add('hidden-vis')
                            el[1].classList.add('hidden-vis')
                            shown = false
                        } else {
                            el[0].classList.remove('hidden-vis')
                            el[1].classList.remove('hidden-vis')
                            shown = true
                        }
                    }, 500)
                } else if (phase == 2) {
                    var el = document.getElementsByClassName('player1-arrow')
                    id = setInterval(() => {
                        if (shown) {
                            el[0].classList.add('hidden-vis')
                            shown = false
                        } else {
                            el[0].classList.remove('hidden-vis')
                            shown = true
                        }
                    }, 500)
                } else if (phase == 3) {
                    var el = document.getElementsByClassName('np')
                    id = setInterval(() => {
                        if (shown) {
                            el[0].classList.add('hidden-vis')
                            el[1].classList.add('hidden-vis')
                            shown = false
                        } else {
                            el[0].classList.remove('hidden-vis')
                            el[1].classList.remove('hidden-vis')
                            shown = true
                        }
                    }, 500)
                }
            }
            if (phases[phase].move) {
                document.getElementsByClassName('player2-arrow')[0].classList.remove('hidden')
                document.getElementsByClassName('player1-arrow')[0].classList.add('hidden')
            } else {
                document.getElementsByClassName('player1-arrow')[0].classList.remove('hidden')
                document.getElementsByClassName('player2-arrow')[0].classList.add('hidden')
            }
            document.getElementById('order').innerHTML = phases[phase].html
            for (var i = 0; i < phases[phase].state.p1.length; i++) {
                document.getElementById(phases[phase].state.p1[i]).classList.add('red-player')
            }
            for (var i = 0; i < phases[phase].state.p2.length; i++) {
                document.getElementById(phases[phase].state.p2[i]).classList.add('blue-player')
            }
        }
        if (phases[phase]) {
            if (phases[phase].hideNext) {
                var elNext = document.getElementById("next")
                if (elNext) {
                    elNext.classList.add("hidden")
                }
            } else {
                var elNext = document.getElementById("next")
                if (elNext) {
                    elNext.classList.remove("hidden")
                }
            }
        }
    }

    next() {
        if (phase < 7) {
            var elems = document.querySelectorAll(".red-player");
            [].forEach.call(elems, function (el) {
                el.classList.remove("red-player")
            })

            elems = document.querySelectorAll(".blue-player");

            [].forEach.call(elems, function (el) {
                el.classList.remove("blue-player")
            })
            phase++
            if (!shown) {
                shown = true
                var el = document.getElementsByClassName('names')
                el[0].classList.remove('hidden-vis')
                el[1].classList.remove('hidden-vis')
                el = document.getElementsByClassName('player1-arrow')
                el[0].classList.remove('hidden-vis')
                el = document.getElementsByClassName('np')
                el[0].classList.remove('hidden-vis')
                el[1].classList.remove('hidden-vis')
            }
            clearInterval(id)
            if (phase <= 3 && phase >= 1) {
                if (phase == 1) {
                    var el = document.getElementsByClassName('names')
                    id = setInterval(() => {
                        if (shown) {
                            el[0].classList.add('hidden-vis')
                            el[1].classList.add('hidden-vis')
                            shown = false
                        } else {
                            el[0].classList.remove('hidden-vis')
                            el[1].classList.remove('hidden-vis')
                            shown = true
                        }
                    }, 500)
                } else if (phase == 2) {
                    var el = document.getElementsByClassName('player1-arrow')
                    id = setInterval(() => {
                        if (shown) {
                            el[0].classList.add('hidden-vis')
                            shown = false
                        } else {
                            el[0].classList.remove('hidden-vis')
                            shown = true
                        }
                    }, 500)
                } else if (phase == 3) {
                    var el = document.getElementsByClassName('np')
                    id = setInterval(() => {
                        if (shown) {
                            el[0].classList.add('hidden-vis')
                            el[1].classList.add('hidden-vis')
                            shown = false
                        } else {
                            el[0].classList.remove('hidden-vis')
                            el[1].classList.remove('hidden-vis')
                            shown = true
                        }
                    }, 500)
                }
            }
            if (phases[phase].move) {
                document.getElementsByClassName('player2-arrow')[0].classList.remove('hidden')
                document.getElementsByClassName('player1-arrow')[0].classList.add('hidden')
            } else {
                document.getElementsByClassName('player1-arrow')[0].classList.remove('hidden')
                document.getElementsByClassName('player2-arrow')[0].classList.add('hidden')
            }
            document.getElementById('order').innerHTML = phases[phase].html
            for (var i = 0; i < phases[phase].state.p1.length; i++) {
                document.getElementById(phases[phase].state.p1[i]).classList.add('red-player')
            }
            for (var i = 0; i < phases[phase].state.p2.length; i++) {
                document.getElementById(phases[phase].state.p2[i]).classList.add('blue-player')
            }
        }
        if (phases[phase]) {
            if (phases[phase].hideNext) {
                var elNext = document.getElementById("next")
                if (elNext) {
                    elNext.classList.add("hidden")
                }
            } else {
                var elNext = document.getElementById("next")
                if (elNext) {
                    elNext.classList.remove("hidden")
                }
            }
        }
    }

    move(id) {
        if (phases[phase]) {
            if (phases[phase].further) {
                if (id.target.id == phases[phase].further) {
                    clearInterval(id)
                    var elems = document.querySelectorAll(".red-player");

                    [].forEach.call(elems, function (el) {
                        el.classList.remove("red-player")
                    })

                    elems = document.querySelectorAll(".blue-player");

                    [].forEach.call(elems, function (el) {
                        el.classList.remove("blue-player")
                    })
                    if (!shown) {
                        shown = true
                        var el = document.getElementsByClassName('names')
                        el[0].classList.remove('hidden-vis')
                        el[1].classList.remove('hidden-vis')
                        el = document.getElementsByClassName('player1-arrow')
                        el[0].classList.remove('hidden-vis')
                        el = document.getElementsByClassName('np')
                        el[0].classList.remove('hidden-vis')
                        el[1].classList.remove('hidden-vis')
                    }
                    phase++
                    document.getElementsByClassName('player1-arrow')[0].classList.remove('hidden')
                    document.getElementsByClassName('player2-arrow')[0].classList.add('hidden')
                    document.getElementById('order').innerHTML = phases[phase].html
                    for (var i = 0; i < phases[phase].state.p1.length; i++) {
                        document.getElementById(phases[phase].state.p1[i]).classList.add('red-player')
                    }
                    for (var i = 0; i < phases[phase].state.p2.length; i++) {
                        document.getElementById(phases[phase].state.p2[i]).classList.add('blue-player')
                    }
                }
            }
        }
        if (phases[phase]) {
            if (phases[phase].hideNext) {
                var elNext = document.getElementById("next")
                if (elNext) {
                    elNext.classList.add("hidden")
                }
            } else {
                var elNext = document.getElementById("next")
                if (elNext) {
                    elNext.classList.remove("hidden")
                }
            }
        }

    }

    render() {
        return (
            <div className="position-relative">
                <h2 className="yellow-font my-0 py-4 text-center">Hex game tutorial</h2>
                <div id="order" className="yellow-font text-center mt-4 px-4"></div>
                <div className="d-flex mx-auto max-content">
                    <div className="my-4 players">
                        <ul className="my-4">
                            <div className="d-flex">
                                <img src="/img/right.png" style={{ position: 'absolute' }} className="player1-arrow smallest-img height-max activity" />
                                <ol className="p1 my-4 p-2 blue-font fixed-name-widht"><span class="names">PlayableWeb Hex bot - 1000:</span></ol>
                                <svg className="architecture mt-4" version="1.1" id="svg-0-4" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40">
                                    <polygon className="hex-field red-player rp np" id="0-4-polygon" points="20,0 40,10 40,30 20,40 0,30 0,10"></polygon>
                                </svg>
                            </div>
                            <div className="d-flex">
                                <img src="/img/right.png" style={{ position: 'absolute' }} className="player2-arrow smallest-img height-max activity hidden" />
                                <ol className="p2 my-4 p-2 blue-font fixed-name-widht"><span class="names">TutorialPlayer Hex - 1500:</span></ol>
                                <svg className="architecture mt-4" version="1.1" id="svg-0-4" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 40 40">
                                    <polygon className="hex-field blue-player bp np" id="0-4-polygon" points="20,0 40,10 40,30 20,40 0,30 0,10"></polygon>
                                </svg>
                            </div>
                        </ul>
                    </div>
                    <div id="gameBoard">
                        {this.generateBoard(11)}
                    </div>
                </div>
                <div className="controls mt-4 mx-auto max-width text-center d-flex">
                    <button id="prev" className="yellow-font ms-4 btn" onClick={this.prev}>Previous</button>
                    <button id="next" className="yellow-font ms-4 btn" onClick={this.next}>Next</button>
                </div>
            </div>
        )
    }
}

export default HexTutorial