import { Component } from 'react'
import { socket } from './App'
import Won from './Won'
import Lost from './Lost'
import Tie from './Tie'
import Players from './Players'
import BottomButtons from './BottomButtonsComponent'
import WithdrawConfirmation from './WithdrawConfirmation'
import ForfeitConfirmation from './ForfeitConfirmation'
import Chessboard from 'chessboardjsx'
import * as Chess from 'chess.js'
import GameWithdrawn from './GameWithdrawn'
import Waiting from './Waiting'

var that, times, intervalId, chess, finished, choosing, promotion, chess, player, from, to, pieces, justShown, starting

class ChessComponent extends Component {
    constructor() {
        super()
        that = this
        this.state = {
            fen: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
            status: <div></div>,
            dropSquareStyle: {},
            squareStyles: {},
            pieceSquare: "",
            square: "",
            history: [],
            fromSquare: "",
            toSquare: "",
            piece: "",
            promotion: false
        }
        justShown = false
        finished = false
        choosing = null
        promotion = false
        starting = true
        chess = new Chess(this.state.fen)
    }

    handleSocket() {
        if (socket) {
            socket.on('DataSended', (data) => {
                var arr1 = document.getElementsByClassName('player1-arrow')
                var arr2 = document.getElementsByClassName('player2-arrow')
                var pawn1 = document.getElementById("white-pawn")
                var pawn2 = document.getElementById("black-pawn")
                if (data.onMove) {
                    starting = false
                    that.hideWithdraw()
                    that.showForfeit()
                    if (data.onMove == 'active') {
                        if (arr1[0] != null && arr2[0] != null) {
                            arr1[0].style.display = "block"
                            arr2[0].style.display = "none"
                        }
                        if (pawn1 && pawn2) {
                            pawn1.src = '/img/pawn_white_turn.png'
                            pawn2.src = '/img/pawn_black.png'
                        }
                    } else {
                        if (arr1[0] != null && arr2[0] != null) {
                            arr1[0].style.display = "none"
                            arr2[0].style.display = "block"
                        }
                        if (pawn1 && pawn2) {
                            pawn1.src = '/img/pawn_white.png'
                            pawn2.src = '/img/pawn_black_turn.png'
                        }
                    }
                } else {
                    that.hideForfeit()
                    that.showWithdraw()
                    starting = true
                }
                if (data.fen) {
                    chess = new Chess(data.fen)
                    that.setState({ fen: data.fen })
                }
                if (data.lastMove.length > 0) {
                    var sourceSquare = document.querySelectorAll(`[data-squareid='${data.lastMove[0]}']`)
                    var targetSquare = document.querySelectorAll(`[data-squareid='${data.lastMove[1]}']`)
                    if (sourceSquare[0] && targetSquare[0]) {
                        sourceSquare[0].classList.add('lastMove')
                        targetSquare[0].classList.add('lastMove')
                    }
                }
                if (data.pot) {
                    var elementPot = document.getElementById("pot-size")
                    if (elementPot) {
                        elementPot.textContent = data.pot
                    }
                }
                if (data.time) {
                    times = data.time
                    that.displayTime(times)
                    if (intervalId) {
                        clearInterval(intervalId)
                    }
                    intervalId = setInterval(() => {
                        times--
                        that.displayTime(times)
                    }, 1000)
                }
                if (data.names.length == 2) {
                    var params = window.location.pathname
                    params = params.split("/")
                    socket.emit('checkUsersInRoom', ({ gameName: params[1], roomNumber: params[2], account: window.localStorage.getItem('account') }))
                }
                var el = document.getElementsByClassName('players')
                if (el) {
                    var el2 = document.getElementById("red-player-hex")
                    if (el2) {
                        el2.classList.add("hidden")
                    }
                    var el3 = document.getElementById("blue-player-hex")
                    if (el3) {
                        el3.classList.add("hidden")
                    }
                    el[0].classList.remove('hidden')
                }
                if (data.check) {
                    if (data.color == "white") {
                        var el = document.getElementsByClassName('chessboard')
                        if (el[0]) {
                            var children = el[0].children[0].children[0].children
                            for (var i = 0; i < children.length; i++) {
                                var childs = children[i].children
                                for (var j = 0; j < childs.length; j++) {
                                    if (childs[j].children[0]) {
                                        if (childs[j].children[0].children[0]) {
                                            if (childs[j].children[0].children[0].dataset) {
                                                if (childs[j].children[0].children[0].dataset.testid) {
                                                    var test = childs[j].children[0].children[0].dataset.testid
                                                    var testKing = test.split("-")[0]
                                                    if (testKing == 'wK') {
                                                        childs[j].children[0].children[0].classList.add("inCheck")
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        var el = document.getElementsByClassName('chessboard')
                        if (el[0]) {
                            var children = el[0].children[0].children[0].children
                            for (var i = 0; i < children.length; i++) {
                                var childs = children[i].children
                                for (var j = 0; j < childs.length; j++) {
                                    if (childs[j].children[0]) {
                                        if (childs[j].children[0].children[0]) {
                                            if (childs[j].children[0].children[0].dataset) {
                                                if (childs[j].children[0].children[0].dataset.testid) {
                                                    var test = childs[j].children[0].children[0].dataset.testid
                                                    var testKing = test.split("-")[0]
                                                    if (testKing == 'bK') {
                                                        childs[j].children[0].children[0].classList.add("inCheck")
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            })
        }
    }

    displayTime(time) {
        var seconds = time % 60
        if (seconds < 10) {
            seconds = `0${seconds}`
        }
        var minutes = time / 60
        minutes = Math.floor(minutes % 60)
        if (minutes < 10) {
            minutes = `0${minutes}`
        }
        var hours = Math.floor(time / (60 * 60))
        if (hours < 10) {
            hours = `0${hours}`
        }

        var el = document.getElementById('time-left')
        if (el) {
            el.innerHTML = hours + ":" + minutes + ":" + seconds
        }
    }

    async choosePiece(color) {
        if (color == "white") {
            return 'q'
        } else {
            return 'q'
        }
    }

    async move(object) {
        if (starting) {
            return
        }
        that.removeHighlightSquare()
        if (player == 1) {
            if (chess.turn() == 'b') {
                return
            }
        } else {
            if (chess.turn() == 'w') {
                return
            }
        }
        chess.move({ from: object.sourceSquare, to: object.targetSquare })
        that.setState({ fen: chess.fen() })
        var params = window.location.pathname
        params = params.split("/")
        var piece = null
        promotion = false
        if (object.piece == 'bP' && (object.targetSquare == 'a1' || object.targetSquare == 'b1' || object.targetSquare == 'c1' || object.targetSquare == 'd1' || object.targetSquare == 'e1' || object.targetSquare == 'f1' || object.targetSquare == 'g1' || object.targetSquare == 'h1')) {
            that.setState({
                fromSquare: object.sourceSquare,
                toSquare: object.targetSquare,
                piece: object.piece,
                promotion: true
            })
            that.showChoosingModal(1)
            return
        } else if (object.piece == 'wP' && (object.targetSquare == 'a8' || object.targetSquare == 'b8' || object.targetSquare == 'c8' || object.targetSquare == 'd8' || object.targetSquare == 'e8' || object.targetSquare == 'f8' || object.targetSquare == 'g8' || object.targetSquare == 'h8')) {
            that.setState({
                fromSquare: object.sourceSquare,
                toSquare: object.targetSquare,
                piece: object.piece,
                promotion: true
            })
            that.showChoosingModal(0)
            return
        }
        socket.emit("moveChess", ({ gameName: params[1], roomNumber: params[2], account: window.localStorage.getItem('account'), token: window.localStorage.getItem('token'), refreshToken: window.localStorage.getItem('refreshToken'), move: object, promotion: promotion, piece: piece }))
    }

    showChoosingModal(pl) {
        if (pl == 0) {
            var el = document.getElementById("promotion-white")
            if (el) {
                el.classList.remove("hidden")
            }
        } else {
            var el2 = document.getElementById("promotion-black")
            if (el2) {
                el2.classList.remove("hidden")
            }
        }
    }

    moveFromModal(piece) {
        var params = that.getParams()
        var object = {
            sourceSquare: that.state.fromSquare,
            targetSquare: that.state.toSquare,
            piece: that.state.piece
        }
        socket.emit("moveChess", ({ gameName: params[1], roomNumber: params[2], account: window.localStorage.getItem('account'), token: window.localStorage.getItem('token'), refreshToken: window.localStorage.getItem('refreshToken'), move: object, promotion: that.state.promotion, piece: piece.target.id }))
        that.hideChoosingModal()
    }

    hideChoosingModal() {
        var el = document.getElementById("promotion-white")
        if (el) {
            el.classList.add("hidden")
        }

        var el2 = document.getElementById("promotion-black")
        if (el2) {
            el2.classList.add("hidden")
        }

        that.clearMoveState()
    }

    clearMoveState() {
        that.setState({
            fromSquare: "",
            toSquare: "",
            piece: "",
            promotion: false
        })
    }

    getParams() {
        var params = window.location.pathname
        params = params.split("/")
        return params
    }

    clickOnBody(e) {
        if (e.target.id) {
            if (e.target.id == "promotion-white" || e.target.id == "promotion-black" || e.target.id == "r" || e.target.id == "n" || e.target.id == "b" || e.target.id == "q") {
                return
            }
        }
        if (!justShown) {
            var el = document.getElementById("promotion-white")
            var el2 = document.getElementById("promotion-black")
            if (el && el2) {
                if (!el.classList.contains("hidden") || !el2.classList.contains("hidden")) {
                    that.hideChoosingModal()
                }
            }
        } else {
            justShown = false
        }
    }

    componentDidMount() {
        this.handleSocket()
        document.body.addEventListener('click', this.clickOnBody);
        var params = window.location.pathname
        params = params.split("/")
        socket.on("disconnected", () => {
            socket.emit('checkUsersInRoom', ({ gameName: params[1], roomNumber: params[2], account: window.localStorage.getItem('account') }))
        })
        socket.on("users", (props) => {
            if (props.count == 1) {
                var el1 = document.getElementById('user1')
                if (el1) {
                    if (window.localStorage.getItem("name") == el1.textContent) {
                        player = 1
                        var el = document.getElementById("p1")
                        if (el) {
                            el.setAttribute('src', '/img/active.png')
                            el.setAttribute('state', 'active')
                        }
                        el = document.getElementById("p2")
                        if (el) {
                            if (props.telegram) {
                                el.setAttribute('src', '/img/half.png')
                                el.setAttribute('state', 'telegram')
                            } else {
                                el.setAttribute('src', '/img/waiting.png')
                                el.setAttribute('state', 'offline')
                            }
                        }
                    } else {
                        player = 2
                        var el = document.getElementById("p1")
                        if (el) {
                            if (props.telegram) {
                                el.setAttribute('src', '/img/half.png')
                                el.setAttribute('state', 'telegram')
                            } else {
                                el.setAttribute('src', '/img/waiting.png')
                                el.setAttribute('state', 'offline')
                            }
                        }
                        el = document.getElementById("p2")
                        if (el) {
                            el.setAttribute('src', '/img/active.png')
                            el.setAttribute('state', 'active')
                        }
                    }
                } else {

                }

            } else {
                var el1 = document.getElementById('user1')
                if (el1) {
                    if (window.localStorage.getItem("name") == el1.textContent) {
                        player = 1
                    } else {
                        player = 2
                    }
                }
                var el = document.getElementById("p1")
                if (el) {
                    el.setAttribute('src', '/img/active.png')
                }
                el = document.getElementById("p2")
                if (el) {
                    el.setAttribute('src', '/img/active.png')
                }
            }
        })

        socket.on("movedChess", (props) => {
            chess = new Chess(props.fen)
            var elements = document.getElementsByClassName('inCheck')
            for (var i = 0; i < elements.length; i++) {
                elements[i].classList.remove('inCheck')
            }
            var elementslm = document.getElementsByClassName('lastMove')
            if (elementslm[0] && elementslm[1]) {
                elementslm[0].classList.remove('lastMove')
                elementslm[0].classList.remove('lastMove')
            }

            times = 60 * 60 * 24
            if (props.token) {
                window.localStorage.setItem("token", props.token)
            }
            that.setState({ fen: props.fen })
            var sourceSquare = document.querySelectorAll(`[data-squareid='${props.lastMove.sourceSquare}']`)
            var targetSquare = document.querySelectorAll(`[data-squareid='${props.lastMove.targetSquare}']`)
            if (sourceSquare[0] && targetSquare[0]) {
                sourceSquare[0].classList.add('lastMove')
                targetSquare[0].classList.add('lastMove')
            }
            if (props.player != undefined) {
                var arr1 = document.getElementsByClassName('player1-arrow')
                var arr2 = document.getElementsByClassName('player2-arrow')
                var pawn1 = document.getElementById("white-pawn")
                var pawn2 = document.getElementById("black-pawn")
                if (props.player == 1) {
                    if (arr1[0] != null && arr2[0] != null) {
                        arr1[0].style.display = "none"
                        arr2[0].style.display = "block"
                    }
                    if (pawn1 && pawn2) {
                        pawn1.src = '/img/pawn_white.png'
                        pawn2.src = '/img/pawn_black_turn.png'
                    }
                } else {
                    if (arr1[0] != null && arr2[0] != null) {
                        arr1[0].style.display = "block"
                        arr2[0].style.display = "none"
                    }
                    if (pawn1 && pawn2) {
                        pawn1.src = '/img/pawn_white_turn.png'
                        pawn2.src = '/img/pawn_black.png'
                    }
                }

                if (props.check) {
                    if (props.color == "white") {
                        var el = document.getElementsByClassName('chessboard')
                        if (el[0]) {
                            var children = el[0].children[0].children[0].children
                            for (var i = 0; i < children.length; i++) {
                                var childs = children[i].children
                                for (var j = 0; j < childs.length; j++) {
                                    if (childs[j].children[0]) {
                                        if (childs[j].children[0].children[0]) {
                                            if (childs[j].children[0].children[0].dataset) {
                                                if (childs[j].children[0].children[0].dataset.testid) {
                                                    var test = childs[j].children[0].children[0].dataset.testid
                                                    var testKing = test.split("-")[0]
                                                    if (testKing == 'wK') {
                                                        childs[j].children[0].children[0].classList.add("inCheck")
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        var el = document.getElementsByClassName('chessboard')
                        if (el[0]) {
                            var children = el[0].children[0].children[0].children
                            for (var i = 0; i < children.length; i++) {
                                var childs = children[i].children
                                for (var j = 0; j < childs.length; j++) {
                                    if (childs[j].children[0]) {
                                        if (childs[j].children[0].children[0]) {
                                            if (childs[j].children[0].children[0].dataset) {
                                                if (childs[j].children[0].children[0].dataset.testid) {
                                                    var test = childs[j].children[0].children[0].dataset.testid
                                                    var testKing = test.split("-")[0]
                                                    if (testKing == 'bK') {
                                                        childs[j].children[0].children[0].classList.add("inCheck")
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }
        })

        socket.on("gameStopped", props => {
            if (props) {
                window.localStorage.setItem("token", props)
            }

            if (!finished) {
                finished = true
                that.hideWithdraw()
                that.setState({ status: <GameWithdrawn /> })
            }

        })

        socket.on("tie", () => {
            if (!finished) {
                finished = true
                that.hideForfeit()
                that.setState({ status: <Tie /> })
            }
        })

        socket.on("gameWon", () => {
            if (!finished) {
                finished = true
                that.hideForfeit()
                that.setState({ status: <Won /> })
            }
        })

        socket.on("gameLost", () => {
            if (!finished) {
                finished = true
                that.hideForfeit()
                that.setState({ status: <Lost /> })
            }
        })
    }

    forfeit() {
        var params = window.location.pathname
        params = params.split("/")
        socket.emit('chessForfeit', ({ gameName: params[1], roomNumber: params[2], account: window.localStorage.getItem('account'), token: window.localStorage.getItem('token'), refreshToken: window.localStorage.getItem('refreshToken') }))
    }

    componentWillUnmount() {
        clearInterval(intervalId)
        var params = window.location.pathname
        params = params.split("/")
        if (params[1] && params[2]) {
            socket.emit("disconnectFromChannel", ({ gameName: params[1], roomNumber: params[2] }))
        }
        socket.removeAllListeners()
    }

    showMoves(square) {
        that.removeHighlightSquare()
        that.highlightMoves(square)
        var piece = chess.get(square)
        if (piece != null) {
            if (piece.color == "w") {
                if (player == 1) {
                    pieces = "w" + piece.type.toUpperCase()
                    from = square
                } else {
                    if (from == null) {
                        return
                    }
                    to = square
                }
            } else {
                if (player == 1) {
                    if (from == null) {
                        return
                    }
                    to = square
                } else {
                    pieces = "b" + piece.type.toUpperCase()
                    from = square
                }
            }
        } else {
            if (from == null) {
                return
            }
            to = square
        }

        if (to && from) {
            var object = {
                sourceSquare: from,
                targetSquare: to,
                piece: pieces
            }

            to = null
            from = null
            pieces = null
            justShown = true

            if (object.piece == 'bP' && (object.targetSquare == 'a1' || object.targetSquare == 'b1' || object.targetSquare == 'c1' || object.targetSquare == 'd1' || object.targetSquare == 'e1' || object.targetSquare == 'f1' || object.targetSquare == 'g1' || object.targetSquare == 'h1')) {
                that.setState({
                    fromSquare: object.sourceSquare,
                    toSquare: object.targetSquare,
                    piece: object.piece,
                    promotion: true
                })
                that.showChoosingModal(1)
                return
            } else if (object.piece == 'wP' && (object.targetSquare == 'a8' || object.targetSquare == 'b8' || object.targetSquare == 'c8' || object.targetSquare == 'd8' || object.targetSquare == 'e8' || object.targetSquare == 'f8' || object.targetSquare == 'g8' || object.targetSquare == 'h8')) {
                that.setState({
                    fromSquare: object.sourceSquare,
                    toSquare: object.targetSquare,
                    piece: object.piece,
                    promotion: true
                })
                that.showChoosingModal(0)
                return
            }

            var params = that.getParams()
            socket.emit("moveChess", ({ gameName: params[1], roomNumber: params[2], account: window.localStorage.getItem('account'), token: window.localStorage.getItem('token'), refreshToken: window.localStorage.getItem('refreshToken'), move: object, promotion: false, piece: null }))
        }
    }

    highlightMoves(square) {
        if (player == 1) {
            if (chess.turn() == 'b') {
                return
            }
        } else {
            if (chess.turn() == 'w') {
                return
            }
        }
        let moves = chess.moves({
            square: square,
            verbose: true
        })

        if (moves.length === 0) {
            return
        }

        let squaresToHighlight = [];
        for (var i = 0; i < moves.length; i++) {
            squaresToHighlight.push(moves[i].to)
        }

        that.highlightMovesNext(squaresToHighlight)
    }

    highlightMovesNext(squaresToHighlight) {
        for (var i = 0; i < squaresToHighlight.length; i++) {
            var square = document.querySelectorAll(`[data-squareid='${squaresToHighlight[i]}']`)
            if (square[0]) {
                if (square[0].children[0].children[0]) {
                    if (square[0].children[0].children[0].hasAttribute("data-testid")) {
                        if (square[0].children[0].children[0].getAttribute("data-testid") == 'column-a' || square[0].children[0].children[0].getAttribute("data-testid") == 'column-b' || square[0].children[0].children[0].getAttribute("data-testid") == 'column-c' || square[0].children[0].children[0].getAttribute("data-testid") == 'column-d' || square[0].children[0].children[0].getAttribute("data-testid") == 'column-e' || square[0].children[0].children[0].getAttribute("data-testid") == 'column-f' || square[0].children[0].children[0].getAttribute("data-testid") == 'column-g' || square[0].children[0].children[0].getAttribute("data-testid") == 'column-h') {
                            square[0].classList.add('possible-move')
                        } else {
                            square[0].classList.add('take')
                        }
                    } else {
                        square[0].classList.add('possible-move')
                    }
                } else {
                    square[0].classList.add('possible-move')
                }
            }
        }
    }

    removeHighlightSquare() {
        var els = document.getElementsByClassName('possible-move')
        var take = document.getElementsByClassName('take')
        for (var i = 0; i < take.length; i) {
            take[i].classList.remove('take')
        }
        for (var i = 0; i < els.length; i) {
            els[i].classList.remove('possible-move')
        }
    }

    renderModals(toRender) {
        if (toRender == 0) {
            return (
                <div id="promotion-white" className="alligned-middle board-bg text-center px-5 py-5 radius small-padding-mobile hidden">
                    <h2 className="white-font text-center my-2">Choose a Piece!</h2>
                    <div className="d-flex">
                        <img id="r" src="/img/rook_white.png" alt="White rook" onClick={that.moveFromModal} className="small-image text-center mx-2 my-2 clickable" />
                        <img id="n" src="/img/knight_white.png" alt="White knight" onClick={that.moveFromModal} className="small-image text-center mx-2 my-2 clickable" />
                        <img id="b" src="/img/bishop_white.png" alt="White bishop" onClick={that.moveFromModal} className="small-image text-center mx-2 my-2 clickable" />
                        <img id="q" src="/img/queen_white.png" alt="White queen" onClick={that.moveFromModal} className="small-image text-center mx-2 my-2 clickable" />
                    </div>
                </div>
            )
        } else {
            return (
                <div id="promotion-black" className="alligned-middle board-bg text-center px-5 py-5 radius small-padding-mobile hidden">
                    <h2 className="white-font text-center my-2">Choose a Piece!</h2>
                    <div className="d-flex">
                        <img id="r" src="/img/rook_black.png" alt="Black rook" onClick={that.moveFromModal} className="small-image text-center mx-2 my-2 clickable" />
                        <img id="n" src="/img/knight_black.png" alt="Black knight" onClick={that.moveFromModal} className="small-image text-center mx-2 my-2 clickable" />
                        <img id="b" src="/img/bishop_black.png" alt="Black bishop" onClick={that.moveFromModal} className="small-image text-center mx-2 my-2 clickable" />
                        <img id="q" src="/img/queen_black.png" alt="Black queen" onClick={that.moveFromModal} className="small-image text-center mx-2 my-2 clickable" />
                    </div>
                </div>
            )
        }
    }

    showWithdraw() {
        var el = document.getElementById("withdraw")
        if (el) {
            el.classList.remove("hidden")
        }

        var el2 = document.getElementById("waiting")
        if (el2) {
            el2.classList.remove("hidden")
        }
    }

    showForfeit() {
        var el = document.getElementById("forfeit")
        if (el) {
            el.classList.remove("hidden")
        }
    }

    hideWithdraw() {
        var el = document.getElementById("withdraw")
        if (el) {
            el.classList.add("hidden")
        }

        var el2 = document.getElementById("waiting")
        if (el2) {
            el2.classList.add("hidden")
        }
    }

    hideForfeit() {
        var el = document.getElementById("forfeit")
        if (el) {
            el.classList.add("hidden")
        }
    }

    render() {
        var el1 = document.getElementById('user1')
        var el2 = document.getElementById('user2')
        if (el2) {
            if (el2.textContent == 'No player') {
                return (
                    <div className="position-relative">
                        <Waiting />
                        {this.renderModals(0)}
                        {this.renderModals(1)}
                        <div className="d-xl-flex mx-auto max-content">
                            <Players />
                            {this.state.status}
                            <div className="chessboard text-right">
                                <Chessboard
                                    position={this.state.fen}
                                    pieces={{
                                        wP: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/pawn_white.png"}
                                                alt={"wP"}
                                            />
                                        ),
                                        wR: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/rook_white.png"}
                                                alt={"wR"}
                                            />
                                        ),
                                        wN: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/knight_white.png"}
                                                alt={"wN"}
                                            />
                                        ),
                                        wB: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/bishop_white.png"}
                                                alt={"wB"}
                                            />
                                        ),
                                        wQ: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/queen_white.png"}
                                                alt={"wQ"}
                                            />
                                        ),
                                        wK: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/king_white.png"}
                                                alt={"wK"}
                                            />
                                        ),
                                        bP: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/pawn_black.png"}
                                                alt={"bP"}
                                            />
                                        ),
                                        bR: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/rook_black.png"}
                                                alt={"bwR"}
                                            />
                                        ),
                                        bN: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/knight_black.png"}
                                                alt={"bN"}
                                            />
                                        ),
                                        bB: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/bishop_black.png"}
                                                alt={"bB"}
                                            />
                                        ),
                                        bQ: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/queen_black.png"}
                                                alt={"bQ"}
                                            />
                                        ),
                                        bK: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/king_black.png"}
                                                alt={"bK"}
                                            />
                                        ),
                                    }}
                                    lightSquareStyle={{ backgroundImage: `url(/img/WHITE_FIELD.jpg)` }}
                                    darkSquareStyle={{ backgroundImage: `url(/img/BLACK_FIELD.jpg)` }}
                                />
                            </div>
                        </div>
                        <BottomButtons />
                        <WithdrawConfirmation />
                        <ForfeitConfirmation />
                    </div>
                )
            }
        }
        if (el1) {
            if (window.localStorage.getItem("name") == el1.textContent) {
                return (
                    <div className="position-relative">
                        <Waiting />
                        {this.renderModals(0)}
                        {this.renderModals(1)}
                        <div className="d-xl-flex mx-auto max-content">
                            <Players />
                            {this.state.status}
                            <div className="chessboard text-right">
                                <Chessboard
                                    position={this.state.fen}
                                    orientation='white'
                                    onDrop={this.move}
                                    onSquareClick={this.showMoves}
                                    pieces={{
                                        wP: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/pawn_white.png"}
                                                alt={"wP"}
                                            />
                                        ),
                                        wR: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/rook_white.png"}
                                                alt={"wR"}
                                            />
                                        ),
                                        wN: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/knight_white.png"}
                                                alt={"wN"}
                                            />
                                        ),
                                        wB: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/bishop_white.png"}
                                                alt={"wB"}
                                            />
                                        ),
                                        wQ: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/queen_white.png"}
                                                alt={"wQ"}
                                            />
                                        ),
                                        wK: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/king_white.png"}
                                                alt={"wK"}
                                            />
                                        ),
                                        bP: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/pawn_black.png"}
                                                alt={"bP"}
                                            />
                                        ),
                                        bR: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/rook_black.png"}
                                                alt={"bwR"}
                                            />
                                        ),
                                        bN: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/knight_black.png"}
                                                alt={"bN"}
                                            />
                                        ),
                                        bB: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/bishop_black.png"}
                                                alt={"bB"}
                                            />
                                        ),
                                        bQ: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/queen_black.png"}
                                                alt={"bQ"}
                                            />
                                        ),
                                        bK: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/king_black.png"}
                                                alt={"bK"}
                                            />
                                        ),
                                    }}
                                    lightSquareStyle={{ backgroundImage: `url(/img/WHITE_FIELD.jpg)` }}
                                    darkSquareStyle={{ backgroundImage: `url(/img/BLACK_FIELD.jpg)` }}
                                />
                            </div>
                        </div>
                        <BottomButtons />
                        <WithdrawConfirmation />
                        <ForfeitConfirmation />
                    </div>
                )
            } else {
                return (
                    <div className="position-relative">
                        <Waiting />
                        {this.renderModals(0)}
                        {this.renderModals(1)}
                        <div className="d-xl-flex mx-auto max-content">
                            <Players />
                            {this.state.status}
                            <div className="chessboard text-right">
                                <Chessboard
                                    position={this.state.fen}
                                    orientation='black'
                                    onDrop={this.move}
                                    onSquareClick={this.showMoves}
                                    pieces={{
                                        wP: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/pawn_white.png"}
                                                alt={"wP"}
                                            />
                                        ),
                                        wR: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/rook_white.png"}
                                                alt={"wR"}
                                            />
                                        ),
                                        wN: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/knight_white.png"}
                                                alt={"wN"}
                                            />
                                        ),
                                        wB: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/bishop_white.png"}
                                                alt={"wB"}
                                            />
                                        ),
                                        wQ: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/queen_white.png"}
                                                alt={"wQ"}
                                            />
                                        ),
                                        wK: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/king_white.png"}
                                                alt={"wK"}
                                            />
                                        ),
                                        bP: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/pawn_black.png"}
                                                alt={"bP"}
                                            />
                                        ),
                                        bR: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/rook_black.png"}
                                                alt={"bwR"}
                                            />
                                        ),
                                        bN: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/knight_black.png"}
                                                alt={"bN"}
                                            />
                                        ),
                                        bB: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/bishop_black.png"}
                                                alt={"bB"}
                                            />
                                        ),
                                        bQ: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/queen_black.png"}
                                                alt={"bQ"}
                                            />
                                        ),
                                        bK: () => (
                                            <img
                                                className="chess-piece clickable"
                                                src={"/img/king_black.png"}
                                                alt={"bK"}
                                            />
                                        ),
                                    }}
                                    lightSquareStyle={{ backgroundImage: `url(/img/WHITE_FIELD.jpg)` }}
                                    darkSquareStyle={{ backgroundImage: `url(/img/BLACK_FIELD.jpg)` }}
                                />
                            </div>
                        </div>
                        <BottomButtons />
                        <WithdrawConfirmation />
                        <ForfeitConfirmation />
                    </div>
                )
            }
        } else {
            return (
                <div className="position-relative">
                    <Waiting />
                    {this.renderModals(0)}
                    {this.renderModals(1)}
                    <div className="d-xl-flex mx-auto max-content">
                        <Players />
                        {this.state.status}
                        <div className="chessboard text-right">
                            <Chessboard
                                position={this.state.fen}
                                orientation='white'
                                onDrop={this.move}
                                onSquareClick={this.showMoves}
                                pieces={{
                                    wP: () => (
                                        <img
                                            className="chess-piece clickable"
                                            src={"/img/pawn_white.png"}
                                            alt={"wP"}
                                        />
                                    ),
                                    wR: () => (
                                        <img
                                            className="chess-piece clickable"
                                            src={"/img/rook_white.png"}
                                            alt={"wR"}
                                        />
                                    ),
                                    wN: () => (
                                        <img
                                            className="chess-piece clickable"
                                            src={"/img/knight_white.png"}
                                            alt={"wN"}
                                        />
                                    ),
                                    wB: () => (
                                        <img
                                            className="chess-piece clickable"
                                            src={"/img/bishop_white.png"}
                                            alt={"wB"}
                                        />
                                    ),
                                    wQ: () => (
                                        <img
                                            className="chess-piece clickable"
                                            src={"/img/queen_white.png"}
                                            alt={"wQ"}
                                        />
                                    ),
                                    wK: () => (
                                        <img
                                            className="chess-piece clickable"
                                            src={"/img/king_white.png"}
                                            alt={"wK"}
                                        />
                                    ),
                                    bP: () => (
                                        <img
                                            className="chess-piece clickable"
                                            src={"/img/pawn_black.png"}
                                            alt={"bP"}
                                        />
                                    ),
                                    bR: () => (
                                        <img
                                            className="chess-piece clickable"
                                            src={"/img/rook_black.png"}
                                            alt={"bwR"}
                                        />
                                    ),
                                    bN: () => (
                                        <img
                                            className="chess-piece clickable"
                                            src={"/img/knight_black.png"}
                                            alt={"bN"}
                                        />
                                    ),
                                    bB: () => (
                                        <img
                                            className="chess-piece clickable"
                                            src={"/img/bishop_black.png"}
                                            alt={"bB"}
                                        />
                                    ),
                                    bQ: () => (
                                        <img
                                            className="chess-piece clickable"
                                            src={"/img/queen_black.png"}
                                            alt={"bQ"}
                                        />
                                    ),
                                    bK: () => (
                                        <img
                                            className="chess-piece clickable"
                                            src={"/img/king_black.png"}
                                            alt={"bK"}
                                        />
                                    ),
                                }}
                                lightSquareStyle={{ backgroundImage: `url(/img/WHITE_FIELD.jpg)` }}
                                darkSquareStyle={{ backgroundImage: `url(/img/BLACK_FIELD.jpg)` }}
                            />
                        </div>
                    </div>
                    <BottomButtons />
                    <WithdrawConfirmation />
                    <ForfeitConfirmation />
                </div>
            )
        }
    }
}

export default ChessComponent