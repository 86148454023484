import { Component } from "react"

class BottomButtons extends Component {
    constructor() {
        super()
    }

    withdraw() {
        var el = document.getElementById('withdraw-confirm')
        if (el) {
            el.classList.remove("hidden")
        }
    }

    forfeit() {
        var el = document.getElementById('forfeit-confirm')
        if (el) {
            el.classList.remove("hidden")
        }
    }

    render() {
        return (
            <div className="end-buttons max-content mx-auto d-flex text-center position-relative">
                <button id="withdraw" className="hidden mx-auto btn own-btn yellow-font" onClick={this.withdraw}>Forfeit</button>
                <button id="forfeit" className="hidden mx-auto btn own-btn yellow-font" onClick={this.forfeit}>Forfeit</button>
            </div>
        )
    }
}

export default BottomButtons