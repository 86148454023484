import { Component } from 'react'

class ChooseGame extends Component {
    render() {
        return (
            <select name="games" className="yellow-font white-border">
                <option className="yellow-font" value="Chess">Chess</option>
                <option className="yellow-font" value="Hex">Hex (side game)</option>
            </select>
        )
    }
}

export default ChooseGame