import { combineReducers } from 'redux'
import UserReducer from './user-reducer'
import MetamaskReducer from './metamask-reducer'
import GameReducer from './game-reducer'
import AdminReducer from './admin-reducer'
import TokensReducer from './tokens-reducer'
import LogsReducer from './logs-reducer'

const rootReducer = combineReducers({
    user: UserReducer,
    metamask: MetamaskReducer,
    game: GameReducer,
    admin: AdminReducer,
    tokens: TokensReducer,
    logs: LogsReducer
})

export default rootReducer