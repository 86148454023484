import { Component } from 'react'

class Waiting extends Component {
    render() {
        return (
            <div id="waiting" className="w-700 mx-auto dark-bg text-center px-5 py-5 mb-4 radius small-padding-mobile new-prompts hidden">
                <p className="text-center white-font my-0">Waiting for player - we will message you via telegram when the game is matched.</p>
            </div>
        )
    }
}

export default Waiting